import React, { PropsWithChildren, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { Button } from "reactstrap";

interface IProps {
	text?: string;
	disabled?: boolean;
	onConfirmed: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const RemoveButton = (props: PropsWithChildren<IProps>) => {
	const [isConfirmMode, setConfirmMode] = useState(false);

	const ref = useOnclickOutside(() => {
		setConfirmMode(false);
	}, {});

	const onRemoveClicked = () => {
		setConfirmMode(true);
	};

	const onRemoveConfirmed = (
		e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		try {
			props.onConfirmed(e);
		} catch {}
		setConfirmMode(false);
	};

	if (!isConfirmMode) {
		return (
			<>
				<span ref={ref} className="remove-confirm-wrapper">
					<Button
						color="danger"
						outline={true}
						className="border-0"
						onClick={() => onRemoveClicked()}
					>
						{props.children ? (
							<>{props.children}</>
						) : (
							<>{props.text || "Remove"}</>
						)}
					</Button>
				</span>
			</>
		);
	}

	return (
		<span className="remove-confirm-wrapper">
			<Button
				color="danger"
				className="border-0"
				disabled={props.disabled}
				onClick={(e) => onRemoveConfirmed(e)}
			>
				Confirm
			</Button>
		</span>
	);
};
