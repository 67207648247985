import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";
import { Artist, ArtistLayouts } from ".";

interface IProps {
	artists?: ArtistDto[];
}

export const ArtistGridView = (props: IProps) => {
	return (
		<div className="release-grid-view">
			{props.artists?.map((artist, i) => {
				return (
					<div key={artist.guid} className="release-grid-item">
						<Artist
							key={i}
							alias={artist.alias}
							artist={artist}
							layout={ArtistLayouts.Summary}
						/>
					</div>
				);
			})}
		</div>
	);
};
