import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { InvoiceDto } from "src/api/models/v1/dto/payment/invoiceDto";
import { SubscriptionService } from "src/api/services/payments/subscriptionService";
import utils from "src/shared/utils";

interface IProps {
	reload: boolean;
}

export const Invoices = (props: IProps) => {
	const [invoices, setInvoices] = useState<InvoiceDto[]>([]);

	useEffect(() => {
		async function workerAsync() {
			const subscriptionService = new SubscriptionService();
			const invoiceList = await subscriptionService.getInvoicesAsync();
			setInvoices(invoiceList);
		}

		workerAsync();
	}, [props.reload]);

	if (invoices?.length) {
		return (
			<>
				<h1 className="mt-1">Payment History</h1>
				<Table className="mt-1 table table-striped table-compact">
					<thead>
						<tr>
							<th>Date</th>
							<th className="text-end">Amount</th>
							<th>Currency</th>
							<th className="text-end">Invoice/Receipt</th>
						</tr>
					</thead>
					<tbody>
						{invoices
							// .filter((x) => x.amountPaid > 0)
							.map((invoice) => {
								return (
									<tr key={invoice.id}>
										<td>
											{utils.dateTime.toLocalString(
												invoice.paymentDateTime
											)}
										</td>
										<td className="text-end">
											{(invoice.amountPaid / 100).toFixed(2)}
										</td>
										<td>{invoice.currency}</td>
										<td className="text-end">
											<a href={invoice.url} target="_blank">
												<i className="fa fa-file-invoice-dollar" />
											</a>
										</td>
									</tr>
								);
							})}
					</tbody>
				</Table>
			</>
		);
	} else {
		return (
			<>
				<h1 className="mt-1">Payment History</h1>
			</>
		);
	}
};
