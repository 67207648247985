import { SyntheticEvent } from "react";
import {
	addOrUpdate,
	getQuery,
	getUrlWithoutQuery,
	merge,
} from "./queryString";

export const onImgError = (
	e: SyntheticEvent<HTMLImageElement, Event>,
	altImageUrl?: string
) => {
	e.currentTarget.onerror = null; // prevents looping

	if (e.currentTarget.dataset.onerror) {
		return;
	}

	e.currentTarget.dataset.onerror = "true";

	if (!altImageUrl?.trim()?.length) {
		return;
	}

	if (e.currentTarget.src.toLowerCase() === altImageUrl.toLowerCase()) {
		return false;
	}

	let altImageNoQuery = getUrlWithoutQuery(altImageUrl);
	const newQuery = merge(getQuery(e.currentTarget.src), getQuery(altImageUrl));
	e.currentTarget.src = `${altImageNoQuery}?${newQuery}`;
};

export const renameBySize = (
	src?: string,
	square?: number,
	width?: number,
	height?: number
): string | undefined => {
	if (!src) {
		return src;
	}

	let query = getQuery(src);
	let fileUrl = getUrlWithoutQuery(src);

	const fileUrlExtension = fileUrl.substring(fileUrl.lastIndexOf("."));
	const fileUrlWithoutExtension = fileUrl.substring(
		0,
		fileUrl.lastIndexOf(".")
	);

	let sizeSuffix = "";
	if (square) {
		sizeSuffix = `_s-${square}`;
	} else if (width) {
		sizeSuffix = `_w-${width}`;
	} else if (height) {
		sizeSuffix = `_h-${height}`;
	}

	return `${fileUrlWithoutExtension}${sizeSuffix}${fileUrlExtension}${query}`;
};

export const appendSizeQuery = (
	src?: string,
	square?: number,
	width?: number,
	height?: number
): string | undefined => {
	if (!src) {
		return src;
	}

	let query = getQuery(src);
	let fileUrl = getUrlWithoutQuery(src);

	let sizeSuffix = "";

	if (square) {
		sizeSuffix = addOrUpdate(query, "s", square);
	} else if (width) {
		sizeSuffix = addOrUpdate(query, "w", width);
	} else if (height) {
		sizeSuffix = addOrUpdate(query, "h", height);
	}

	return `${fileUrl}?${sizeSuffix}`;
};

export const applyQueryString = (src?: string): string | undefined => {
	if (!src) {
		return src;
	}

	let newSrc = src;
	if (newSrc.indexOf("?") < 0) {
		return newSrc;
	}

	const fileUrl = newSrc.substring(0, newSrc.indexOf("?") + 1);
	const fileUrlExtension = fileUrl.substring(fileUrl.lastIndexOf("."));
	const fileUrlWithoutExtension = fileUrl.substring(
		0,
		fileUrl.lastIndexOf(".")
	);

	const query = newSrc.substring(newSrc.indexOf("?") + 1);
	const keyValues = getKeyValues(query);

	let sizeSuffix = "";
	let newQuery: string[] = [];

	for (const key in keyValues) {
		if (Object.prototype.hasOwnProperty.call(keyValues, key)) {
			const value = keyValues[key];

			switch (key.toLowerCase()) {
				case "s":
					sizeSuffix = `_s-${value}`;
					break;

				case "w":
					sizeSuffix = `_w-${value}`;
					break;

				case "h":
					sizeSuffix = `_h-${value}`;
					break;

				default:
					newQuery.push(`${key}=${encodeURIComponent(value)}`);
			}
		}
	}

	newSrc = `${fileUrlWithoutExtension}${sizeSuffix}${fileUrlExtension}?${newQuery.join(
		"&"
	)}`;
	return newSrc;
};

const getKeyValues = (query: string) => {
	let keyValues: { [key: string]: string } = {};

	if (query.trim() === "") {
		return keyValues;
	}

	query.split("&").forEach((keyValue) => {
		var [key, value] = keyValue.split("=");
		keyValues[key] = decodeURIComponent(value);
	});

	return keyValues;
};

export default {
	onImgError,
};
