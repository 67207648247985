import React from "react";
import { Col, Container, Row } from "reactstrap";

export class Footer extends React.Component {
	render() {
		return (
			<footer className="p-x-1 py-2">
				<Container className="h-100">
					<Row className="align-items-center">
						<Col xs="12" md="3" lg="3" className="mt-1">
							<a href="/">
								<img
									src="/images/logo/beats_union_hz_blue.png"
									style={{ height: "40px" }}
									alt="Beats Union"
								/>
							</a>
						</Col>
						<Col xs="12" md="6" lg="6" className="mt-1">
							<div>
								© {new Date().getFullYear()}, BeatsUnion Music Inc.
							</div>
							<div>
								View our{" "}
								<a
									href="https://beatsunion.com/TermsOfUse"
									target="_blank"
									rel="noopener noreferrer"
								>
									Terms of Use
								</a>{" "}
								or{" "}
								<a
									href="https://beatsunion.com/PrivacyPolicy"
									target="_blank"
									rel="noopener noreferrer"
								>
									Privacy Policy
								</a>
								.
							</div>
						</Col>
						<Col xs="12" md="3" className="mt-1 mb-1">
							<div className="social-container w-100">
								<a
									href="https://instagram.com/beatsunionmusic"
									target="_blank"
									rel="noopener noreferrer"
								>
									<span className="fab fa-instagram"></span>{" "}
								</a>
								<a
									href="https://facebook.com/beatsunionmusic"
									target="_blank"
									rel="noopener noreferrer"
								>
									<span className="fab fa-facebook"></span>{" "}
								</a>
								<a
									href="https://twitter.com/beatsunionmusic"
									target="_blank"
									rel="noopener noreferrer"
								>
									<span className="fab fa-twitter"></span>{" "}
								</a>
							</div>
						</Col>
					</Row>
				</Container>
			</footer>
		);
	}
}
