import { connect } from "react-redux";
import { Action, bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "src/store";
import { ArtistActionsCreator } from "src/store/artist/artist.actions";
import { IArtistState } from "src/store/artist/artist.state";
import { AuthActionsCreator } from "src/store/auth/auth.action";
import { IAuthState } from "src/store/auth/auth.state";
import {
	BeatsShopActions,
	IBeatsShopFunctions,
} from "src/store/beatsShop/beatsShop.actions";
import { IBeatsShopState } from "src/store/beatsShop/beatsShop.state";
import { CartActions } from "src/store/beatsShop/cart/cart.actions";
import { CheckoutActionsCreator } from "src/store/beatsShop/orders/checkout.actions";
import { IOrderState } from "src/store/beatsShop/orders/checkout.state";
import { MusicActionsCreator } from "src/store/music/music.actions";
import { IMusicState } from "src/store/music/music.state";
import { ReleaseActionCreator } from "src/store/music/release/release.actions";
import { SearchCriteriaActionCreator } from "src/store/music/searchCriteria/searchCriteria.action";
import { PlayerActionCreators } from "src/store/player/player.actions";
import { IPlayerState } from "src/store/player/player.state";
import { OrderHistoryActionCreator } from "./beatsShop/orders/history.actions";
import { SearchActionsCreator } from "./search/search.actions";
import { ISearchState } from "./search/search.state";

//------------- Interfaces -------------------------

interface IReduxState {
	BeatsShop: IBeatsShopState;
	Music: IMusicState;
	Player: IPlayerState;
	Checkout: IOrderState;
	Artist: IArtistState;
	Auth: IAuthState;
	Search: ISearchState;
}

interface IReduxActions {
	BeatsShop: IBeatsShopFunctions;
	Music: typeof MusicActionsCreator;
	Artist: typeof ArtistActionsCreator;
	Player: typeof PlayerActionCreators;
	Checkout: typeof CheckoutActionsCreator;
	Release: typeof ReleaseActionCreator;
	OrderHistory: typeof OrderHistoryActionCreator;
	SearchCriteria: typeof SearchCriteriaActionCreator;
	Auth: typeof AuthActionsCreator;
	Search: typeof SearchActionsCreator;
}

export interface IReduxProps {
	State: IReduxState;
	Actions: IReduxActions;
}

//------------- Connecting Store -------------------------

const bindState = (state: ApplicationState) => {
	return {
		State: {
			BeatsShop: state.beatsShop,
			Music: state.music,
			Player: state.player,
			Checkout: state.checkout,
			Artist: state.artist,
			Auth: state.auth,
			Search: state.search,
		} as IReduxState,
	};
};

const bindActions = (dispatch: Dispatch<Action<any>>) => {
	const cartActions = bindActionCreators(CartActions, dispatch);
	const authActions = bindActionCreators(AuthActionsCreator, dispatch);
	const orderHistoryActions = bindActionCreators(
		OrderHistoryActionCreator,
		dispatch
	);

	const searchActions = bindActionCreators(
		SearchCriteriaActionCreator,
		dispatch
	);

	const beatsShopActions = {
		Initialized: bindActionCreators(BeatsShopActions, dispatch).Initialized,
		Cart: {
			InitializeAsync: cartActions.InitializedAsync,
			AddToCart: cartActions.ItemAddedToCart,
			RemoveFromCart: cartActions.ItemRemovedFromCart,
		},
	};

	return {
		Actions: {
			Auth: authActions,
			BeatsShop: beatsShopActions,
			SearchCriteria: searchActions,
			OrderHistory: orderHistoryActions,
			Music: bindActionCreators(MusicActionsCreator, dispatch),
			Artist: bindActionCreators(ArtistActionsCreator, dispatch),
			Player: bindActionCreators(PlayerActionCreators, dispatch),
			Release: bindActionCreators(ReleaseActionCreator, dispatch),
			Checkout: bindActionCreators(CheckoutActionsCreator, dispatch),
			Search: bindActionCreators(SearchActionsCreator, dispatch),
		} as IReduxActions,
	};
};

export function connectStore<TProps>(component: any) {
	return connect<any, any, TProps, ApplicationState>(
		bindState,
		bindActions
	)(component as any);
}
