import { cloneDeep, isNumber } from "lodash";

export function cloneRemove<TItem>(
	array: Array<TItem>,
	item: TItem,
	comparisonKey?: (item: TItem) => string
): Array<TItem> {
	if (!array || !array.length) return array;

	var clone = [...array];
	var index = clone.indexOf(item);
	if (comparisonKey) {
		for (var i = 0; i < array.length; i++) {
			var sourceKey = comparisonKey(array[i]);
			var itemKey = comparisonKey(item);

			if (sourceKey === itemKey) {
				index = i;
				break;
			}
		}
	}
	if (index < 0) return clone;

	clone.splice(index, 1);
	return clone;
}

export function cloneReplace<TItem>(
	array: Array<TItem>,
	item: TItem,
	comparisonKey?: (item: TItem) => string
): Array<TItem> {
	if (!array || !array.length || !item) return array;

	var clone = [...array];
	var index = clone.indexOf(item);
	if (comparisonKey) {
		for (var i = 0; i < array.length; i++) {
			var sourceKey = comparisonKey(array[i]);
			var itemKey = comparisonKey(item);

			if (sourceKey === itemKey) {
				index = i;
				break;
			}
		}
	}
	if (index < 0) return clone;

	clone[index] = item;
	return clone;
}

export function toggle<TItem>(array: Array<TItem>, item: TItem): Array<TItem> {
	if (!array || !array.length) array = [];

	var clone = [...array];
	var index = clone.indexOf(item);
	if (index < 0) clone.push(item);
	else clone = cloneRemove(clone, item);

	return clone;
}

export function deepClone<TItem>(array: Array<TItem>): Array<TItem> {
	const newArray = array.map((x) =>
		Array.isArray(x) ? deepClone(x) : x
	) as Array<TItem>;
	return [...newArray];
}

export function moveElement<TItem>(
	array: Array<TItem>,
	oldIndex: number,
	newIndex: number
) {
	var element = array[oldIndex];
	array.splice(oldIndex, 1);
	array.splice(newIndex, 0, element);
}

export function sum<TItem>(
	array: Array<TItem>,
	predicate?: (item: TItem) => number
): number {
	if (!array || !array.length) return 0;

	let numberArray: Array<number> = [];
	if (isNumber(array[0])) numberArray = array.map((x) => x as any as number);
	else {
		if (!predicate)
			throw new Error(
				"Cannot sum an array that does not contain numbers. Specify a predicate"
			);
		numberArray = array.map((x) => predicate(x));
	}

	const result = numberArray.reduce((a, b) => a + b);
	return result;
}

export function merge<TItem>(
	array: Array<TItem>,
	newArray: Array<TItem>,
	comparisonKey: (item: TItem) => string,
	replaceIfExists: boolean = false
) {
	// if (!array?.length)
	// 	return array;

	// if (!newArray?.length)
	// 	return { ...array };

	let result = cloneDeep(array);

	newArray.forEach((v, i) => {
		const existingList = result.filter(
			(x) => comparisonKey(x) === comparisonKey(v)
		);
		if (!existingList || !existingList.length) {
			result.push(v);
		} else if (replaceIfExists) {
			for (const existing of existingList) {
				const index = result.indexOf(existing);
				result[index] = v;
			}
		}
	});

	return result;
}

export function flatten<TContainer, TElement>(
	container: TContainer[],
	projection: (container: TContainer) => TElement[]
): TElement[] {
	return Array.prototype.concat.apply(
		[],
		container.map((x) => projection(x))
	);
}

export default {
	cloneRemove,
	cloneReplace,
	toggle,
	deepClone,
	moveElement,
	sum,
	merge,
};
