import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import utils from "src/shared/utils";
import { AppThunkAction } from "../../store/index";
import { TrackActionsCreator } from "../music/track/track.actions";
import { storeDispatch } from "../storeRegistry";

export interface IPlayListFunctions {
	add(trackGuid: string): void;
	remove(trackGuid: string): void;

	reorder(oldIndex: number, newIndex: number): void;
}

export interface IPlayerFunctions {
	initialize(): void;
	play(trackGuid: string): void;

	stop(): void;
	pause(trackGuid: string): void;
	resume(trackGuid: string): void;

	setVolume(value: number): void;

	dismiss(): void;
	minimize(): void;
	maximize(): void;

	playlistFunctions: IPlayListFunctions;
}

export enum ActionTypes {
	Initialized = "[Player] Initialized",
	PlayPauseClicked = "[Player] Play/Pause Clicked",
	Dismissed = "[Player] Dismissed",

	PlaylistInitialized = "[Player] Playlist initialized",
	PlaylistAdded = "[Player] Track added to Playlist",
	PlaylistRemoved = "[Player] Track removed from Playlist",
	PlaylistReordered = "[Player] Playlist reordered",
}

export interface IInitialized {
	type: ActionTypes.Initialized;
}

export interface IPlayPauseClicked {
	type: ActionTypes.PlayPauseClicked;
	payload: TrackDto;
}

export interface IDismissed {
	type: ActionTypes.Dismissed;
}

export interface IPlaylistInitialized {
	type: ActionTypes.PlaylistInitialized;
	payload: string[];
}

export interface IPlaylistAdded {
	type: ActionTypes.PlaylistAdded;
	payload: string[];
}

export interface IPlaylistRemoved {
	type: ActionTypes.PlaylistRemoved;
	payload: string[];
}

export interface IPlaylistReordered {
	type: ActionTypes.PlaylistReordered;
	payload: string[];
}

export type KnownActions =
	| IInitialized
	| IPlayPauseClicked
	| IDismissed
	| IPlaylistAdded
	| IPlaylistRemoved
	| IPlaylistReordered
	| IPlaylistInitialized;

export const mapToPlayerFunctions = (
	actionCreators: typeof PlayerActionCreators
): IPlayerFunctions => {
	return {
		initialize: actionCreators.Initialized,
		play: actionCreators.PlayPauseClicked,
		pause: actionCreators.PlayPauseClicked,
		resume: actionCreators.PlayPauseClicked,
		dismiss: actionCreators.Dismissed,
		maximize: () => {},
		minimize: () => {},
		setVolume: (value) => {},
		stop: () => {},
		playlistFunctions: {
			add: actionCreators.PlaylistAdded,
			remove: actionCreators.PlaylistRemoved,
			reorder: actionCreators.PlaylistReordered,
		},
	};
};

export const playlistStorageKey = "tracks-in-playlist";

export const PlayerActionCreators = {
	Initialized: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
		storeDispatch(PlayerActionCreators.PlaylistInitialized());
	},

	PlayPauseClicked:
		(trackGuid: string): AppThunkAction<KnownActions> =>
		(dispatch, getState) => {
			document.body.classList.add("player-open-bottom");

			let track = getState().music.trackList.find(
				(x) =>
					x.guid.toLowerCase().trim() === trackGuid.toLowerCase().trim()
			);
			if (!track) return;

			storeDispatch(TrackActionsCreator.IsPlayingToggled(trackGuid));

			dispatch({
				type: ActionTypes.PlayPauseClicked,
				payload: track,
			});
		},

	Dismissed: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
		var track = getState().player.currentTrack || null;

		document.body.classList.remove("player-open-bottom");
		storeDispatch(TrackActionsCreator.PlayingStopped(track?.guid || ""));

		dispatch({
			type: ActionTypes.Dismissed,
		});
	},

	PlaylistInitialized:
		(): AppThunkAction<KnownActions> => async (dispatch, getState) => {
			let tracksInPlaylist =
				(await utils.storage.get(playlistStorageKey)) || [];
			dispatch({
				type: ActionTypes.PlaylistInitialized,
				payload: tracksInPlaylist,
			});
		},

	PlaylistAdded:
		(trackGuid: string): AppThunkAction<KnownActions> =>
		async (dispatch, getState) => {
			let tracksInPlaylist = [...getState().player.playlist];

			if (
				!tracksInPlaylist.find(
					(x) => x.toLowerCase().trim() === trackGuid.toLowerCase().trim()
				)
			) {
				tracksInPlaylist = [...tracksInPlaylist, trackGuid];
				await utils.storage.set(playlistStorageKey, tracksInPlaylist);
			}

			dispatch({
				type: ActionTypes.PlaylistAdded,
				payload: tracksInPlaylist,
			});

			storeDispatch(
				TrackActionsCreator.IsInPlaylistChanged(trackGuid, true)
			);
		},

	PlaylistRemoved:
		(trackGuid: string): AppThunkAction<KnownActions> =>
		async (dispatch, getState) => {
			let tracksInPlaylist = utils.array.cloneRemove(
				getState().player.playlist,
				trackGuid
			);
			await utils.storage.set(playlistStorageKey, tracksInPlaylist);

			dispatch({
				type: ActionTypes.PlaylistRemoved,
				payload: tracksInPlaylist,
			});

			storeDispatch(
				TrackActionsCreator.IsInPlaylistChanged(trackGuid, false)
			);
		},

	PlaylistReordered:
		(oldIndex: number, newIndex: number): AppThunkAction<KnownActions> =>
		async (dispatch, getState) => {
			let tracksInPlaylist = getState().player.playlist;
			utils.array.moveElement(tracksInPlaylist, oldIndex, newIndex);
			await utils.storage.set(playlistStorageKey, tracksInPlaylist);

			dispatch({
				type: ActionTypes.PlaylistReordered,
				payload: tracksInPlaylist,
			});
		},
};
