import { combineReducers } from "redux";
import { chartListReducer } from "./chart/chart.reducer";
import { releaseReducer } from "./release/release.reducer";
import { searchCriteriaReducer } from "./searchCriteria/searchCriteria.reducer";
import { trackListReducer } from "./track/track.reducer";


export const musicReducer = combineReducers({
	searchCriteria: searchCriteriaReducer,
	chartList: chartListReducer,
	trackList: trackListReducer,
	releaseList: releaseReducer
});

export default musicReducer;
