import { AppThunkAction } from "src/store";
import { storeDispatch } from "../storeRegistry";
import { SearchResponse } from "./../../api/models/v2/responses/search/searchResponse";
import { SearchService } from "./../../api/services/search/searchService";
import { ArtistActionsCreator } from "./../artist/artist.actions";
import { ReleaseActionCreator } from "./../music/release/release.actions";
import { TrackActionsCreator } from "./../music/track/track.actions";

export enum SearchActionTypes {
	SearchInitialized = "[Search] Search initialized",
	SearchRequested = "[Search] Search requested",
	SearchSuccess = "[Search] Search success",
	SearchError = "[Search] Search error",
}

interface ISearchInitialized {
	type: SearchActionTypes.SearchInitialized;
}

interface ISearchRequested {
	type: SearchActionTypes.SearchRequested;
	payload: string;
}

interface ISearchSuccess {
	type: SearchActionTypes.SearchSuccess;
	payload: SearchResponse;
}

interface ISearchError {
	type: SearchActionTypes.SearchError;
}

export type SearchKnownActions =
	| ISearchInitialized
	| ISearchRequested
	| ISearchSuccess
	| ISearchError;

export const SearchActionsCreator = {
	SearchAsync:
		(
			searchTerm: string,
			trackCount: number,
			releaseCount: number,
			artistCount: number
		): AppThunkAction<SearchKnownActions> =>
		async (dispatch, getState) => {
			dispatch({
				type: SearchActionTypes.SearchRequested,
				payload: searchTerm,
			});

			const response = await new SearchService().searchAsync(
				searchTerm,
				trackCount,
				releaseCount,
				artistCount
			);

			if (response && response.statusCode === 200 && response.result) {
				const { tracks, releases, artists } = response.result;
				const cartItemList = getState().beatsShop.Cart.itemList;

				storeDispatch(
					TrackActionsCreator.TrackListUpdated(tracks, cartItemList)
				);
				storeDispatch(ReleaseActionCreator.updateReleaseList(releases));
				storeDispatch(ArtistActionsCreator.UpdateArtists(artists));

				dispatch({
					type: SearchActionTypes.SearchSuccess,
					payload: response.result,
				});
			} else {
				dispatch({
					type: SearchActionTypes.SearchError,
				});
			}
		},
};
