import animation from "./animation";
import appConfig from "./appConfig";
import array from "./array";
import dateTime from "./dateTime";
import dom from "./dom";
import enums from "./enums";
import files from "./files";
import img from "./img";
import map from "./map";
import objects from "./objects";
import queryString from "./queryString";
import storage from "./storage";
import string from "./string";
import validation from "./validation";

export default {
	enums,
	string,
	animation,
	validation,
	appConfig,
	array,
	storage,
	dateTime,
	dom,
	map,
	objects,
	files,
	img,
	queryString,
};
