import { Action, Reducer } from "redux";
import { initialAjaxifyFlags } from "src/shared/ajaxify";
import { ActionTypes, AuthKnownActions } from "./auth.action";
import { IAuthState, InitialAuthState } from "./auth.state";

export const authReducer: Reducer<IAuthState> = (
	currentState: IAuthState = InitialAuthState,
	incomingAction: Action
): IAuthState => {
	const action = incomingAction as AuthKnownActions;
	var exhaustiveCheck: any = action;

	switch (action.type) {
		case ActionTypes.Initialized: {
			return {
				...currentState,
				authUser: action.payload.authUser ?? null,
				accessToken: action.payload.authUser?.access_token || "",
				ajaxFlags: initialAjaxifyFlags,
				isLogedIn: action.payload.currentUser !== null,
				user: action.payload.currentUser,
			};
		}

		case ActionTypes.SigninRequested: {
			return {
				...currentState,
				ajaxFlags: {
					...initialAjaxifyFlags,
					requestInProgress: true,
				},
			};
		}

		case ActionTypes.SigninSucceess: {
			return {
				...currentState,
				ajaxFlags: {
					...initialAjaxifyFlags,
					requestSuccess: true,
				},
				isLogedIn: true,
				accessToken: action.payload.authUser.access_token,
				user: action.payload.currentUser,
				authUser: action.payload.authUser,
			};
		}

		case ActionTypes.SigninError: {
			return {
				...currentState,
				ajaxFlags: {
					...initialAjaxifyFlags,
					requestError: true,
				},
				isLogedIn: false,
				accessToken: "",
				user: null,
			};
		}

		case ActionTypes.SignoutRequested: {
			return {
				...currentState,
				ajaxFlags: {
					...initialAjaxifyFlags,
					requestInProgress: true,
				},
			};
		}

		case ActionTypes.SignoutSucceess: {
			return {
				...currentState,
				ajaxFlags: {
					...initialAjaxifyFlags,
					requestSuccess: true,
				},
				isLogedIn: false,
				accessToken: "",
				user: null,
			};
		}

		default:
			exhaustiveCheck;
	}

	return currentState;
};
