import "@fortawesome/fontawesome-free/js/brands";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/solid";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ConnectedRouter } from "connected-react-router";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { reactPlugin } from "src/shared/utils/logging";
import App from "./App";
import "./App.scss";
import { unregister } from "./registerServiceWorker";
import utils from "./shared/utils";

///Created this JSX file to overcome typescript error
///with Children props of the Provider and ConenctedRouter
export function render(store, history) {
	console.log("X-BU-Env:", utils.appConfig.environments.name);
	console.log("X-BU-NS:", utils.appConfig.environments.namespace);

	ReactDOM.render(
		<>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<>
						<AppInsightsContext.Provider value={reactPlugin}>
							<App />
						</AppInsightsContext.Provider>
					</>
				</ConnectedRouter>
			</Provider>
		</>,
		document.getElementById("root")
	);

	unregister();
}
