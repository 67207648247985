import { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { SubscriptionTypes } from "src/api/models/v1/enums/payments/subscriptions/subscriptionTypes";

interface IProps {
	// items: SubscriptionItemDto[];

	currentSubscriptionType?: SubscriptionTypes;
	onSubscriptionSelected: (subscriptionType: SubscriptionTypes) => void;
}

export const SubscriptionItems = (props: IProps) => {
	let { currentSubscriptionType } = props;
	currentSubscriptionType =
		currentSubscriptionType ?? SubscriptionTypes.Producer_Starter;

	const [state, setState] = useState({
		inProgress: false,
		error: false,
		success: false,
	});

	const onSelectClicked = (subscriptionType: SubscriptionTypes) => {
		props.onSubscriptionSelected(subscriptionType);
	};

	return (
		<>
			<Row className="align-items-end">
				<Col xs={{ size: 12 }} md={{ size: 4 }} className="mt-1">
					<Card>
						<CardBody>
							<div className="border1 border-primary1 rounded">
								<div className="px-2 py-1">
									<div className="text-muted font-size-xxl">
										STARTER
									</div>
									<div className="font-size-lg font-weight-bold pt-h">
										$0 monthly
									</div>
									<div>No commitment. Cancel any time.</div>
								</div>
								<div className="bg-primary text-white px-2 py-1 rounded">
									BeatsUnion takes 15% of your music sales, up to a
									maximum of $10 monthly.
								</div>
								<div className="px-2 py-1 ">
									<div className="text-muted">
										You'll get access to
									</div>
									<div className="ms-1 mt-h">
										<i className="text-primary me-h far fa-check-circle" />
										Hosted BU artist page
									</div>
									<div className="ms-1">
										<i className="text-primary me-h far fa-check-circle" />
										Unlimited music release
									</div>
									<div className="ms-1">
										<i className="text-primary me-h far fa-check-circle" />
										BU communication tools
									</div>
								</div>
							</div>
							<div className="my-1 text-center">
								<button
									type="button"
									className="btn btn-lg w-100 btn-success"
									disabled={
										props.currentSubscriptionType ===
											SubscriptionTypes.Producer_Starter ||
										props.currentSubscriptionType ===
											SubscriptionTypes.Unknown
									}
									onClick={() =>
										onSelectClicked(
											SubscriptionTypes.Producer_Starter
										)
									}
								>
									{props.currentSubscriptionType ===
										SubscriptionTypes.Producer_Starter ||
									props.currentSubscriptionType ===
										SubscriptionTypes.Unknown
										? "Your Current Plan"
										: "Select"}
								</button>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col xs={{ size: 12 }} md={{ size: 4 }} className="mt-1">
					<Card>
						<CardBody>
							<div className="border1 border-primary1 rounded">
								<div className="px-2 py-1">
									<div className="text-muted font-size-xxl">PRO</div>
									<div className="font-size-lg font-weight-bold pt-h">
										$5 monthly
									</div>
									<div>No commitment. Cancel any time.</div>
								</div>
								<div className="bg-primary text-white px-2 py-1 rounded">
									0% commission to BeatsUnion. <br />
									Keep all your earning from music sales.
								</div>
								<div className="px-2 py-1 ">
									<div className="text-muted">
										You'll get access to
									</div>
									<div className="ms-h text-primary">
										Everything in the STARTER
									</div>
									<div className="ms-h mt-h text-primary font-size-lg">
										<i className="me-h fa fa-plus-circle" />
										PLUS
									</div>
									<div className="ms-1 mt-h">
										<i className="text-primary me-h far fa-check-circle" />
										<span className="font-wright-bold">
											Co-creation management tools
										</span>
									</div>
									<div className="ms-3 font-size-sm text-muted">
										Contract automation | Royalty split automation for
										music collaborations with other BeatsUnion
										artists.
									</div>
								</div>
							</div>
							<div className="my-1 text-center">
								<button
									type="button"
									className="btn btn-lg w-100 btn-success"
									disabled={
										props.currentSubscriptionType ===
										SubscriptionTypes.Producer_Pro
									}
									onClick={() =>
										onSelectClicked(SubscriptionTypes.Producer_Pro)
									}
								>
									{props.currentSubscriptionType ===
									SubscriptionTypes.Producer_Pro
										? "Your Current Plan"
										: "Select"}
								</button>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col xs={{ size: 12 }} md={{ size: 4 }} className="mt-1">
					<Card>
						<CardBody>
							<div className="border1 border-primary1 rounded text-white">
								<div className="px-2 py-1 bg-primary rounded">
									<div className="font-size-xxl">PRO REWARDS</div>
									<div className="font-size-lg font-weight-bold pt-h">
										$10 monthly
									</div>
									<div>No commitment. Cancel any time.</div>
								</div>
								<div className="bg-white text-black px-2 py-1">
									0% commission to BeatsUnion. <br />
									Keep all your earning from music sales and earn
									additional revenue thorough BU's Rewards Program.
								</div>
								<div className="px-2 py-1 bg-primary rounded">
									<div className="">You'll get access to</div>
									<div className="ms-h">Everything in the PRO</div>
									<div className="ms-h mt-h font-size-lg">
										<i className="me-h fa fa-plus-circle" />
										PLUS
									</div>
									{/* <div className="ms-1 mt-h">
										<i className="me-h far fa-check-circle" />
										<span className="font-wright-bold">
											Additional marketing support
										</span>
									</div>
									<div className="ms-3 font-size-sm">
										With each exclusive release
									</div> */}
									<div className="ms-1 mt-h">
										<i className="me-h far fa-check-circle" />
										<span className="font-wright-bold">
											BU rewards program activation
										</span>
									</div>
									<div className="ms-3 font-size-sm">
										Earn points for platform engagement {"&"} be
										eligible to receive monthly payment.
									</div>
								</div>
							</div>
							<div className="my-1 text-center">
								<button
									type="button"
									className="btn btn-lg btn-info disabled w-100"
									disabled
									onClick={() =>
										onSelectClicked(
											SubscriptionTypes.Producer_ProRewards
										)
									}
								>
									Coming Soon
								</button>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};
