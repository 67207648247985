import { Action, Reducer } from "redux";
import { ChartTypes } from "src/api/models/v1/enums/music/charts/chartTypes";
import utils from "src/shared/utils";
import { ActionTypes, ChartKnownActions } from "./chart.actions";
import { IChartState, initialChartState } from "./chart.state";

export const chartListReducer: Reducer<IChartState[]> = (
	currentState: IChartState[] = [],
	incomingAction: Action
): IChartState[] => {
	const action = incomingAction as ChartKnownActions;
	var exhaustiveCheck: any = action;

	switch (action.type) {
		case ActionTypes.ChartRequestStarted:
			let otherCharts =
				currentState.filter((x) => x.type !== action.payload) || [];
			let currentChart =
				currentState.find((x) => x.type === action.payload) ||
				initialChartState;

			var result1 = [
				...otherCharts,
				{
					...currentChart,
					type: action.payload,
					ajaxifyFlags: {
						...currentChart.ajaxifyFlags,
						requestInProgress: true,
						requestSuccess: false,
					},
				},
			];

			return result1;

		case ActionTypes.ChartRequestSuccess:
			let otherCharts1 =
				currentState.filter((x) => x.type !== action.payload.type) || [];
			let currentChart1 =
				currentState.find((x) => x.type === action.payload.type) ||
				initialChartState;

			if (action.payload.type === ChartTypes.Release) {
				otherCharts1 =
					currentState.filter(
						(x) =>
							x.type !== action.payload.type ||
							x.chartGuid !== action.payload.chartGuid
					) || [];
				currentChart1 =
					currentState.find(
						(x) =>
							x.type === action.payload.type &&
							x.chartGuid === action.payload.chartGuid
					) || initialChartState;
			}

			let newTrackList = action.payload.trackList;
			if ((action.payload.paging?.pageNumber || 1) > 1)
				newTrackList = [
					...currentChart1.trackList,
					...action.payload.trackList,
				];

			// action.payload.layout = currentChart1.layout;

			let result = [
				...otherCharts1,
				{
					...action.payload,
					ajaxifyFlags: {
						...currentChart1.ajaxifyFlags,
						requestSuccess: true,
						requestInProgress: false,
					},
					type: action.payload.type,
					height: action.payload.height,
					trackList: newTrackList,
					paging: action.payload.paging
						? { ...action.payload.paging }
						: undefined,
					filter: currentChart1.filter
						? { ...currentChart1.filter }
						: undefined,
				},
			];

			return result;

		case ActionTypes.ChartRequestError:
			return currentState;
		// let otherCharts3 = currentState.filter(x => x.type !== action.payload.postedData.chartType) || [];
		// let currentChart3 = currentState.find(x => x.type === action.payload.postedData.chartType) || initialChartState;

		// return [
		// 	...otherCharts3,
		// 	{
		// 		...currentChart3,
		// 		ajaxifyFlags: {
		// 			...currentChart3.ajaxifyFlags,
		// 			requestInProgress: false,
		// 			requestError: true,
		// 			errorMessage: action.payload.message
		// 		}
		// 	}
		// ];

		case ActionTypes.ChartLayoutChanged:
			let otherCharts2 =
				currentState.filter((x) => x.type !== action.payload.chartType) ||
				[];
			let currentChart2 =
				currentState.find((x) => x.type === action.payload.chartType) ||
				initialChartState;

			if (action.payload.chartType === ChartTypes.Release) {
				otherCharts2 =
					currentState.filter(
						(x) =>
							x.type !== action.payload.chartType ||
							x.chartGuid !== action.payload.chartGuid
					) || [];
				currentChart2 =
					currentState.find(
						(x) =>
							x.type === action.payload.chartType &&
							x.chartGuid === action.payload.chartGuid
					) || initialChartState;
			}

			return [
				...otherCharts2,
				{
					...currentChart2,
					layout: action.payload.layout,
				},
			];

		case ActionTypes.ChartTrackUpdated:
			let newState = utils.array.deepClone(currentState);
			for (var i = 0; i < newState.length; i++) {
				const chart = newState[i];
				for (var j = 0; j < chart.trackList.length; j++) {
					if (
						chart.trackList[j].guid.toLowerCase().trim() ===
						action.payload.guid.toLowerCase().trim()
					)
						chart.trackList[j] = action.payload;
				}
			}

			return newState;

		default:
			exhaustiveCheck = action;
	}

	if (!exhaustiveCheck) return currentState || [];

	return currentState || [];
};
