import { useLayoutEffect, useState } from "react";
import {
	Card,
	CardBody,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from "reactstrap";
import { WebReactPermissionTypes } from "src/api/models/enums/permissions/webReactPermissionTypes";
import { SubscriptionTypes } from "src/api/models/v1/enums/payments/subscriptions/subscriptionTypes";
import { SubscriptionService } from "src/api/services/payments/subscriptionService";
import { AuthProvider, IAuthStore } from "src/components/auth/authProvider";
import { CardPayment } from "src/components/payment/subscription/cardPayment";
import { Invoices } from "src/components/payment/subscription/invoices";
import { SubscriptionItems } from "src/components/payment/subscription/items";
import { IReduxProps } from "src/store/storeHelper";

interface IProps extends IReduxProps {
	redirectUrl: string;
}

interface IState {
	reload: boolean;
	subscriptionType: SubscriptionTypes;
	subscriptionEndDate: Date | null;
	selectedSubscriptionType: SubscriptionTypes;
	isPaymentModalOpen: boolean;
}

const LoggedinTemplate = (props: IProps) => {
	const [state, setState] = useState<IState>({
		reload: false,
		isPaymentModalOpen: false,
		subscriptionEndDate: null,
		subscriptionType: SubscriptionTypes.Unknown,
		selectedSubscriptionType: SubscriptionTypes.Unknown,
	});

	useLayoutEffect(() => {
		async function workerAsync() {
			const service = new SubscriptionService();
			const subscriptionResponse = await service.getListAsync();
			const currentItem = (subscriptionResponse?.subscriptions.map(
				(x: any) => x.prices
			) || [])[0];

			let endDate: Date | null = null;
			if (subscriptionResponse?.subscriptions?.length) {
				endDate =
					subscriptionResponse?.subscriptions[0]
						.currentPeriodEndDateTime || null;
			}
			const priceType: string =
				(currentItem && currentItem[0]?.priceType) ??
				SubscriptionTypes[SubscriptionTypes.Producer_Starter];

			let subscriptionType =
				SubscriptionTypes[priceType as keyof typeof SubscriptionTypes];

			if (
				subscriptionResponse?.subscriptions?.length &&
				subscriptionResponse?.subscriptions[0].cancelAtPeriodEnd === true
			) {
				subscriptionType = SubscriptionTypes.Producer_Starter;
			}

			setState({
				...state,
				subscriptionEndDate: endDate,
				subscriptionType: subscriptionType,
			});
		}

		workerAsync();
	}, [state.reload]);

	const closeModal = () => {
		setState({
			...state,
			isPaymentModalOpen: false,
		});
	};

	const handleSubscriptionSelected = async (
		subscriptionType: SubscriptionTypes
	) => {
		setState({
			...state,
			isPaymentModalOpen: true,
			selectedSubscriptionType: subscriptionType,
		});
	};

	const handleSubscriptionSuccessAsync = async (
		subscriptionType: SubscriptionTypes
	): Promise<any> => {
		setTimeout(async () => {
			setState({
				...state,
				reload: true,
				subscriptionType: subscriptionType,
				isPaymentModalOpen: false,
			});
		}, 1500);
	};

	return (
		<>
			<Container className="mt-1">
				{props.redirectUrl !== "" && (
					<Row className="mb-1">
						<Col>
							<a
								href={props.redirectUrl}
								className="btn btn-white btn-sm"
							>
								← Back to Manager
							</a>
						</Col>
					</Row>
				)}
				<SubscriptionItems
					currentSubscriptionType={state.subscriptionType}
					onSubscriptionSelected={handleSubscriptionSelected}
				/>
				<Card className="mt-2 mb-2">
					<CardBody>
						<Invoices reload={state.reload} />
					</CardBody>
				</Card>
			</Container>
			<Modal isOpen={state.isPaymentModalOpen} size="lg">
				<ModalHeader toggle={closeModal}>Payment Info</ModalHeader>
				<ModalBody>
					<Row>
						<Col className="pb-2">
							<CardPayment
								title="Your payment information"
								subscriptionType={state.selectedSubscriptionType}
								subscriptionEndDate={state.subscriptionEndDate}
								redirectUrl={props.redirectUrl}
								closeModal={closeModal}
								onSubscriptionSuccessAsync={
									handleSubscriptionSuccessAsync
								}
							/>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</>
	);
};

export const Subscription = (props: IProps) => {
	const authStore: IAuthStore = {
		authState: props.State.Auth,
		authFunctions: props.Actions.Auth,
	};

	return (
		<>
			<AuthProvider
				authStore={authStore}
				permissionsToCheck={[WebReactPermissionTypes.Subscription]}
				loggedOutTitle="My Subscriptions"
				loggedInTemplate={(auth) => <LoggedinTemplate {...props} />}
			/>
		</>
	);
};
