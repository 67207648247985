import { OrderDto } from "src/api/models/v1/dto/beatsShop/orderDto";
import { PagingDto } from "src/api/models/v1/dto/common/pagingDto";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { IAjaxifyFlags, initialAjaxifyFlags } from "src/shared/ajaxify";
import { InitialPaging } from "src/store/common/common.state";

export interface IOrderHistoryState {
	orders: Array<OrderDto>;
	paging: PagingDto;
	ajaxFlags: IAjaxifyFlags;
	orderDetails: { [orderGuid: string]: TrackDto[] };
}

export const IntialOrderHisotryState: IOrderHistoryState = {
	ajaxFlags: initialAjaxifyFlags,
	orders: [],
	paging: InitialPaging,
	orderDetails: {},
};
