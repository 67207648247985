import currencyFormatter from "currency-formatter";
import { format } from "date-fns";
import { useEffect } from "react";
import { Button, Table } from "reactstrap";
import { OrderDto } from "src/api/models/v1/dto/beatsShop/orderDto";
import { AuthProvider, IAuthStore } from "src/components/auth/authProvider";
import { ScrollPager } from "src/components/common/scrollPager";
import { OrderHistoryActionCreator } from "src/store/beatsShop/orders/history.actions";
import { IOrderHistoryState } from "src/store/beatsShop/orders/history.state";

interface IProps {
	orderHistory: IOrderHistoryState;
	orderHistoryFuncions: typeof OrderHistoryActionCreator;

	onDownloadClicked: (order: OrderDto) => void;
}

interface IPropsWithAuth extends IProps {
	authStore: IAuthStore;
}

const OrderHistoryWithUser = (props: IProps) => {
	const { orderHistoryFuncions } = props;
	let { orders, paging } = props.orderHistory;

	const hasMore = paging.pageNumber < paging.pageCount;
	const loadMore = async (pageNo: number) => {
		pageNo = pageNo || 1;
		await orderHistoryFuncions.getOrders(pageNo);
	};

	useEffect(() => {
		orderHistoryFuncions.getOrders(1);
	}, []);

	if (props.orderHistory.ajaxFlags.requestInProgress) {
		return <>Loading...</>;
	}

	return (
		<>
			<ScrollPager
				key="orderHistory"
				hasMore={hasMore}
				loadNextAsync={loadMore}
				maxHeight="calc(100vh - 200px - 4em)"
				scrollY={true}
			>
				<Table striped={true} className="sticky">
					<thead>
						<tr>
							<th className="d-none d-md-table-cell">Order No</th>
							<th>Date</th>
							<th className="text-end d-none d-md-table-cell">
								# of Tracks
							</th>
							<th className="text-end">Total Amount</th>
							<th className="text-end me-1">&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{orders.map((order, i) => {
							const date = Date.parse(
								(order.orderDate as any as string) + "Z"
							);
							return (
								<tr key={order.guid}>
									<td className="d-none d-md-table-cell">
										{order.referenceNo}
									</td>
									<td className="text-nowrap">
										{format(date, "MMM dd, yyyy")}
									</td>
									<td className="text-end d-none d-md-table-cell">
										{order.trackGuids.length}
									</td>
									<td className="text-end">
										{currencyFormatter.format(order.finalAmount, {
											code: order.currency,
										})}
									</td>
									<td className="text-end text-nowrap">
										{order.receiptUrl && order.receiptUrl !== "" ? (
											<a
												href={order.receiptUrl}
												target="_blank"
												className="btn btn-outline-info border-0"
											>
												Receipt
											</a>
										) : (
											<></>
										)}

										<Button
											outline
											color="primary"
											className="border-0"
											onClick={() => props.onDownloadClicked(order)}
										>
											Download
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</ScrollPager>
		</>
	);
};

export const OrderHistory = (props: IPropsWithAuth) => {
	return (
		<>
			<AuthProvider
				authStore={props.authStore}
				loggedInTemplate={(auth) => <OrderHistoryWithUser {...props} />}
			/>
		</>
	);
};
