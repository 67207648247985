import { Action, Reducer } from "redux";
import { ActionTypes, KnownActions } from "./beatsShop.actions";
import { IBeatsShopState, initialBeatsShopState } from "./beatsShop.state";
import { cartReducer } from "./cart/cart.reducer";
import { orderHistoryReducer } from "./orders/history.reducer";

export const beatsShopReducer: Reducer<IBeatsShopState> = (
	currentState: IBeatsShopState = initialBeatsShopState,
	incomingAction: Action
): IBeatsShopState => {
	const action = incomingAction as KnownActions;

	switch (action.type) {
		case ActionTypes.Initialized:
			return currentState;

		default:
			return {
				...currentState,
				Cart: cartReducer(currentState.Cart, incomingAction),
				OrderHisotry: orderHistoryReducer(
					currentState.OrderHisotry,
					incomingAction
				),
			};
	}
};
