import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import { ICartFunctions } from "src/store/beatsShop/cart/cart.actions";
import { ReleaseGridItem } from "./releaseGridItem";

interface IProps {
	releases?: ReleaseDto[];
	cartFunctions: ICartFunctions;
	itemWidth?: string;
}

export const ReleaseGridView = (props: IProps) => {
	let { releases } = props;

	return (
		<div className="release-grid-view">
			{releases?.map((release, i) => {
				return (
					<ReleaseGridItem
						key={release.guid}
						width={props.itemWidth ?? "300px"}
						release={release}
						cartFunctions={props.cartFunctions}
					/>
				);
			})}
		</div>
	);
};
