import utils from ".";

export const appendScriptTag = (src: string, async?:boolean): void => {
	
	async = async || false;

	document.querySelector(`[src^="${src}"]`)?.remove();

	const script = document.createElement("script");
	const sign = src.indexOf("?") >= 0 ? "&" : "?";

	script.src = `${src}${sign}__bu_k_s_h__=${utils.string.getHashCode(encodeURI(window.location.href))}`;
	script.async = async;
	document.body.appendChild(script);
}

export default{
	appendScriptTag
};

