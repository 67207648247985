import { useState } from "react";
import {
	Elements,
	injectStripe,
	ReactStripeElements,
	StripeProvider,
} from "react-stripe-elements";
import { Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { SubscriptionTypes } from "src/api/models/v1/enums/payments/subscriptions/subscriptionTypes";
import { SubscriptionService } from "src/api/services/payments/subscriptionService";
import { AjaxButton } from "src/components/common";
import { StripeSimple } from "src/components/payment/stripeSimple";
import utils from "src/shared/utils";

enum SubmitStatus {
	InProgress,
	Success,
	Error,
	None,
}

interface IInternalProps {
	title: string;
	redirectUrl: string;
	subscriptionType: SubscriptionTypes;
	subscriptionEndDate: Date | null;
	onSubscriptionSuccessAsync?: (
		subscriptionType: SubscriptionTypes
	) => Promise<any>;
	closeModal?: () => void;
}

type IProps = IInternalProps & ReactStripeElements.InjectedStripeProps;

const StripeCard = (props: IProps) => {
	const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(
		SubmitStatus.None
	);

	const handleSubmit = async () => {
		setSubmitStatus(SubmitStatus.InProgress);
		const subscriptionService = new SubscriptionService();
		const subscription = await subscriptionService.createTieredAsync(
			props.subscriptionType
		);

		if (!subscription) {
			setSubmitStatus(SubmitStatus.Error);
			return;
		}

		if (
			!subscription.mustSetupPayment ||
			!subscription.paymentIntentClientSecret
		) {
			setSubmitStatus(SubmitStatus.Success);
			props.onSubscriptionSuccessAsync &&
				(await props.onSubscriptionSuccessAsync(props.subscriptionType));

			return;
		}

		const response = await props.stripe?.handleCardPayment(
			subscription.paymentIntentClientSecret
		);

		if (response?.error) {
			setSubmitStatus(SubmitStatus.Error);
		} else {
			setSubmitStatus(SubmitStatus.Success);
			props.onSubscriptionSuccessAsync &&
				(await props.onSubscriptionSuccessAsync(props.subscriptionType));
		}
		return false;
	};

	const showError = submitStatus === SubmitStatus.Error;
	const showSuccess = submitStatus === SubmitStatus.Success;
	const isStarter =
		props.subscriptionType === SubscriptionTypes.Producer_Starter;
	const isPro = props.subscriptionType === SubscriptionTypes.Producer_Pro;

	const tierName = isStarter ? "STARTER" : isPro ? "PRO" : "PRO REWARDS";

	return (
		<>
			<Row className="mt-1">
				<Col xs="12">
					{isStarter ? (
						<>
							<div className="mb-2 text-center">
								<div>
									Are you sure you would like to downgrade your
									subscription to STARTER tier?
								</div>
								{props.subscriptionEndDate && (
									<div className="mt-1 text-muted">
										Your current subscription will be active till{" "}
										<strong>
											{utils.dateTime.format(
												props.subscriptionEndDate,
												"mmm dd, yyyy"
											)}
										</strong>
										.
									</div>
								)}
							</div>
						</>
					) : (
						<div className="pb-1">
							<h4>Payment Information</h4>
							<StripeSimple id="Stripe-Subscription" hasError={false} />
						</div>
					)}
				</Col>
			</Row>
			<Row className="mt-1">
				<Col xs="12" md={{ size: 4, offset: isStarter ? 2 : 4 }}>
					<AjaxButton
						type="button"
						className="btn btn-primary btn-lg w-100"
						disabled={showSuccess}
						loading={submitStatus === SubmitStatus.InProgress}
						onClick={handleSubmit}
					>
						{isStarter ? "Continue" : "Submit"}
					</AjaxButton>
				</Col>
				{isStarter && (
					<Col xs="12" md={{ size: 4 }}>
						<Button
							type="button"
							className="btn btn-white btn-lg w-100"
							onClick={() => props.closeModal && props.closeModal()}
						>
							Cancel
						</Button>
					</Col>
				)}
			</Row>
			<Row className="mt-1">
				<Col>
					<ListGroup
						className={`animated ${showError ? "fadeIn" : "hidden"}`}
					>
						<ListGroupItem color="danger">
							<i className="fa fa-exclamation-circle" />
							&nbsp;
							<strong>Payment failed.</strong>&nbsp; There was an error
							while processing your payment. Please try again later.
						</ListGroupItem>
					</ListGroup>
					<ListGroup
						className={`animated ${showSuccess ? "fadeIn" : "hidden"}`}
					>
						<ListGroupItem color="success">
							<i className="fa fa-check-circle" />
							&nbsp;
							<strong>
								Your {tierName} subscription is now active.
							</strong>
						</ListGroupItem>
					</ListGroup>
				</Col>
			</Row>
			{/* {showSuccess && props.redirectUrl.trim() !== "" && (
				<Row className="mt-1">
					<Col xs="12" md={{ size: 4, offset: 4 }}>
						<a href={props.redirectUrl} className="btn btn-primary w-100">
							Back to Manager
						</a>
					</Col>
				</Row>
			)} */}
		</>
	);
};

export const CardPayment = (props: IInternalProps) => {
	const StripeCardComponent = injectStripe(StripeCard);

	return (
		<>
			<StripeProvider apiKey={utils.appConfig.stripe.publishableKey}>
				<Elements
					fonts={[
						{
							cssSrc: "https://fonts.googleapis.com/css?family=Lato",
						},
					]}
				>
					<StripeCardComponent {...props} />
				</Elements>
			</StripeProvider>
		</>
	);
};
