import { PropsWithChildren, useState } from "react";
import { Waypoint } from "react-waypoint";
import { WaypointWrapper } from "./waypointWrapper";

interface IProps {
	maxHeight?: string;
	scrollY?: boolean;

	hasMore: boolean;
	loadNextAsync: (pageNo: number) => void;
}

export const ScrollPager = (props: PropsWithChildren<IProps>) => {
	const [pageNo, setPageNo] = useState(1);
	// const [hasMore, setHasMore] = useState(true);

	let { maxHeight, scrollY } = props;
	scrollY = scrollY ?? false;

	const handleOnEnter = async (args?: Waypoint.CallbackArgs) => {
		await props.loadNextAsync(pageNo + 1);
		setPageNo(pageNo + 1);

		// if (!paging || paging.pageNumber == paging.pageCount) {
		// 	setHasMore(false);
		// } else {
		// 	setHasMore(true);
		// 	setPageNo(paging.pageNumber + 1);
		// }
	};

	const handleOnLeave = (args?: Waypoint.CallbackArgs) => {};

	return (
		<>
			<div
				style={{
					position: "relative",
					maxHeight: maxHeight,
					overflowY: scrollY ? "scroll" : undefined,
				}}
			>
				{props.children}

				{props.hasMore && (
					<WaypointWrapper
						key={`${pageNo}`}
						onEnter={handleOnEnter}
						onLeave={handleOnLeave}
					/>
				)}
			</div>
		</>
	);
};
