import { User } from "oidc-client";
import { UserDto } from "src/api/models/dto/users/userDto";
import { IAjaxifyFlags, initialAjaxifyFlags } from "src/shared/ajaxify";

export interface IAuthState {
	user: UserDto | null;
	accessToken: string;
	isLogedIn: boolean;
	ajaxFlags: IAjaxifyFlags;
	authUser: User | null;
}

export const InitialAuthState: IAuthState = {
	user: null,
	authUser: null,
	accessToken: "",
	isLogedIn: false,
	ajaxFlags: initialAjaxifyFlags,
};
