import React from "react";

export const Verified = (isVerified: boolean) => {

	return (

		// style={{ display: "inline-block", position:"relative" }}

		isVerified &&

		<div className="d-inline-block position-relative" >
			<div
				className="fa-stack w-1-h user-verified-icon"
				title="Verified Producer"
			>
				<i className="fa fa-stack-1x fa-certificate text-primary"></i>
				<i className="fa fa-stack-1x fa-check fa-inverse font-sm"></i>
			</div>
		</div>
	)

}
