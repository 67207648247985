import qs from "qs";
import { useParams } from "react-router-dom";

export function useQueryString<TParams extends { [K in keyof TParams]?: string | undefined; }>(): TParams {
	const routeParams = useParams<TParams>();

	const queryString = qs.parse(
		window.location.search.substring(1).toLowerCase()
	) as any;

	if (routeParams && !queryString) {
		return routeParams;
	} else if (queryString && !routeParams) {
		return queryString as TParams;
	}

	return { ...routeParams, ...(queryString as TParams) };
}
