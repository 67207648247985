import { OrderDto } from "src/api/models/v1/dto/beatsShop/orderDto";
import { OrderHistoryResponse } from "src/api/models/v1/responses/beatsShop/orderHistoryResponse";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { HistoryService } from "src/api/services/beatsShop/orders/historyService";
import { trackService } from "src/api/services/music/trackService";
import { AppThunkAction } from "src/store";
import { TrackActionsCreator } from "src/store/music/track/track.actions";
import { storeDispatch } from "src/store/storeRegistry";

export enum ActionTypes {
	Initialized = "[Order History] Initialized",
	Requested = "[Order History] Requested",
	Success = "[Order History] Success",
	Error = "[Order History] Error",
	Details = "[Order History] Order Details Loaded",
}

interface IOrderDetails {
	orderGuid: string;
	tracks: TrackDto[];
}

interface IInitialized {
	type: ActionTypes.Initialized;
}

interface IRequested {
	type: ActionTypes.Requested;
}

interface ISuccess {
	type: ActionTypes.Success;
	payload: OrderHistoryResponse;
}

interface IError {
	type: ActionTypes.Error;
}

interface IDetails {
	type: ActionTypes.Details;
	payload: IOrderDetails;
}

export type OrderHistoryActions =
	| IInitialized
	| ISuccess
	| IError
	| IRequested
	| IDetails;

export interface IOrderHistoryFunctions {
	getOrders(pageNumber: number): void;
}

export const OrderHistoryActionCreator = {
	getOrders:
		(pageNumber: number): AppThunkAction<OrderHistoryActions> =>
		async (dispatch, getState) => {
			const response = await new HistoryService().getOrderHisotryAsync(
				pageNumber
			);

			if (!response) {
				dispatch({
					type: ActionTypes.Error,
				});
				return;
			}

			dispatch({
				type: ActionTypes.Success,
				payload: { ...response },
			});
		},

	getOrderTracks:
		(order: OrderDto): AppThunkAction<OrderHistoryActions> =>
		async (dispatch, getState) => {
			if (order.trackGuids?.length) {
				const res = await new trackService().getTracksByGuidsAsync(
					order.trackGuids
				);
				if (res.result) {
					storeDispatch(
						TrackActionsCreator.TrackListUpdated(res.result, [])
					);

					dispatch({
						type: ActionTypes.Details,
						payload: {
							tracks: res.result,
							orderGuid: order.guid,
						},
					});
				}
			}
		},
};
