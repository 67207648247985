import { Action, Reducer } from "redux";
import utils from "src/shared/utils";
import { ActionTypes, OrderHistoryActions } from "./history.actions";
import { IntialOrderHisotryState, IOrderHistoryState } from "./history.state";

export const orderHistoryReducer: Reducer<IOrderHistoryState> = (
	currentState: IOrderHistoryState = IntialOrderHisotryState,
	incomingAction: Action
): IOrderHistoryState => {
	const action = incomingAction as OrderHistoryActions;

	switch (action.type) {
		case ActionTypes.Requested:
			return {
				...currentState,
				ajaxFlags: {
					...currentState.ajaxFlags,
					requestInProgress: true,
					requestError: false,
					requestSuccess: false,
				},
			};

		case ActionTypes.Error:
			return {
				...currentState,
				ajaxFlags: {
					...currentState.ajaxFlags,
					requestInProgress: false,
					requestError: true,
				},
			};

		case ActionTypes.Success:
			const { orders, paging } = action.payload;
			let merged = utils.array.merge(currentState.orders, orders, (x) =>
				x.guid.toLowerCase()
			);

			if (merged?.length) {
				merged = merged.sort(
					(a, b) =>
						Date.parse((b?.orderDate || "0") as any) -
						Date.parse((a?.orderDate || "0") as any)
				);
			}

			return {
				...currentState,
				ajaxFlags: {
					...currentState.ajaxFlags,
					requestInProgress: false,
					requestSuccess: true,
				},
				orders: [...merged],
				paging: {
					...paging,
				},
			};

		case ActionTypes.Details:
			return {
				...currentState,
				orderDetails: {
					...currentState.orderDetails,
					[action.payload.orderGuid]: action.payload.tracks,
				},
			};
	}

	return currentState;
};
