import { isFunction } from "lodash";
import React, { Component, createRef } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { ChartFilterDto } from "src/api/models/v1/dto/music/chartFilterDto";
import { SearchCriteriaDto } from "src/api/models/v1/dto/music/searchCriteriaDto";
import { ChartTypes } from "src/api/models/v1/enums/music/charts/chartTypes";
import Chart, {
	ActionModes,
	IChartFunctions,
} from "src/components/music/chart";
import Filter from "src/components/music/filter";
import { IAjaxifyFlags } from "src/shared/ajaxify";
import { ICartFunctions } from "src/store/beatsShop/cart/cart.actions";
import { IChartState } from "src/store/music/chart/chart.state";

interface IProps {
	cartFunctions: ICartFunctions;
	chartFunctions: IChartFunctions;

	searchCriteria: SearchCriteriaDto;
	filterAjaxifyFlags: IAjaxifyFlags;
	hotChart: IChartState;
	defaultUI: IChartState;
}

export class Body extends Component<IProps> {
	DefaultUIChart: React.RefObject<Chart>;

	constructor(props: IProps) {
		super(props);
		this.DefaultUIChart = createRef();
	}

	handleTrackFilterOnChange(filter: ChartFilterDto) {
		if (
			this.DefaultUIChart.current &&
			isFunction(this.DefaultUIChart.current.onFilterChanged)
		)
			this.DefaultUIChart.current.onFilterChanged();

		if (this.props.chartFunctions.loadChart)
			this.props.chartFunctions.loadChart(
				ChartTypes.DefaultUI,
				filter,
				"",
				1
			);
	}

	render() {
		return (
			<React.Fragment>
				<Row>
					<Col lg="3">
						<Card>
							<CardBody>
								<Filter
									searchCriteria={this.props.searchCriteria}
									ajaxifyFlags={this.props.filterAjaxifyFlags}
									onChange={(filter: ChartFilterDto) =>
										this.handleTrackFilterOnChange(filter)
									}
								/>
							</CardBody>
						</Card>

						<Card className="mt-2">
							<CardBody className="px-h">
								<Chart
									title="Recommended"
									maxHeight="400px"
									chart={this.props.hotChart}
									showLayoutToggle={false}
									actionMode={ActionModes.Store}
									chartFunctions={this.props.chartFunctions}
									cartFunctions={this.props.cartFunctions}
								/>
							</CardBody>
						</Card>
					</Col>
					<div className="col-lg-9">
						<Row>
							<Col className="ms-1">
								<Chart
									ref={this.DefaultUIChart}
									title="Newest"
									maxHeight="calc( 100vh - 155px)"
									containerCssClass="pb-1"
									chart={this.props.defaultUI}
									showLayoutToggle={true}
									actionMode={ActionModes.Store}
									chartFunctions={this.props.chartFunctions}
									cartFunctions={this.props.cartFunctions}
								/>
							</Col>
						</Row>
					</div>
				</Row>
			</React.Fragment>
		);
	}
}
