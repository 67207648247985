import { AppThunkAction } from "../../store";
import { ICartFunctions } from "./cart/cart.actions";

export interface IBeatsShopFunctions {
	Initialized: () => void;
	Cart: ICartFunctions;
}

export enum ActionTypes {
	Initialized = "[BeatsShop] Initialized",
}

interface IInitialized {
	type: ActionTypes.Initialized;
}

export type KnownActions = IInitialized;

export const BeatsShopActions = {
	Initialized: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
		dispatch({ type: ActionTypes.Initialized });
	},
};
