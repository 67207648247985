import React from "react";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { ArtistLink } from "src/components/artist/artistLink";

interface IProps {
	track: TrackDto | null;
}

export const ArtistList = (props: IProps) => {
	const { track } = props;

	const hasRecordLabel = !!track?.collaborators.find((x) => x.isRecordLabel);
	const recordLabel = track?.collaborators.find((x) => x.isRecordLabel);

	let producers = track?.collaborators || [];
	if (hasRecordLabel) {
		producers = track?.collaborators.filter((x) => !x.isRecordLabel) || [];
	}

	return (
		<React.Fragment key={props.track?.guid}>
			{track && hasRecordLabel && recordLabel && (
				<div>
					<ArtistLink artist={recordLabel} />
				</div>
			)}
			<div>
				{track &&
					producers &&
					!!producers.length &&
					producers.map((artist, i) => {
						return (
							<React.Fragment key={artist.guid}>
								<ArtistLink artist={artist} />
								{i < producers.length - 1 && ", "}
							</React.Fragment>
						);
						// if (artist?.isActive === true) {
						// 	return (
						// 		<Link
						// 			key={artist.guid}
						// 			to={`/Artist/${encodeURIComponent(
						// 				artist.alias.trim()
						// 			)}`}
						// 			className="hover-primary"
						// 		>
						// 			<AliasAndIcon artist={artist} />

						// 			{/* {artist.alias} */}
						// 			{i < producers.length - 1 && ", "}
						// 		</Link>
						// 	);
						// } else {
						// 	return (
						// 		<span className="text-gray-700" key={artist.guid}>
						// 			<AliasAndIcon artist={artist} />
						// 			{i < producers.length - 1 && ", "}
						// 		</span>
						// 	);
						// }
					})}
			</div>
		</React.Fragment>
	);
};
