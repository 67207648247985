import { Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	Col,
	Container,
	Form,
	ListGroup,
	ListGroupItem,
	Row,
} from "reactstrap";
import { OrderService } from "src/api/services/beatsShop/orders/orderService";
import { AjaxButton } from "src/components/common";
import {
	FormikTextbox,
	InputTypes,
} from "src/components/common/formik/formikTextbox";
import { CenterScreen } from "src/components/layout/centerScreen";
import { IAjaxifyFlags } from "src/shared/ajaxify";
import { nameof } from "ts-simple-nameof";
import { initalStatus, intialSchema, ISchema, schema } from "./formSchema";

interface IProps {
	guestOrderGuid: string;
}

const Success = (props: IProps) => {
	return (
		<>
			<Container>
				<Row>
					<Col>
						<CenterScreen title="">
							<h3>
								<i className="far fa-check-circle text-success"></i>{" "}
								Your request has been submitted successfully.
							</h3>
							<div className="mt-1">
								If the provided information matches our records, you
								will recieve an email with a new download link.
							</div>
							<Row className="mt-2">
								<Col xs="8" sm="6" lg="4">
									<Link
										to={"/"}
										className="btn btn-lg btn-primary w-100"
									>
										Home
									</Link>
								</Col>
							</Row>
						</CenterScreen>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export const GuestOrderExpired = (props: IProps) => {
	const [status, setStatus] = useState<IAjaxifyFlags>(initalStatus);

	if (status.requestSuccess) {
		return <Success {...props} />;
	}

	const handleSubmitAsync = async (values: ISchema) => {
		setStatus({
			...status,
			requestInProgress: true,
			requestError: false,
			requestSuccess: false,
		});

		try {
			await new OrderService().sendNewDownloadAsync(
				props.guestOrderGuid,
				values.email
			);

			setStatus({
				...status,
				requestInProgress: false,
				requestError: false,
				requestSuccess: true,
			});
		} catch (error) {
			console.log(error);

			setStatus({
				...status,
				requestInProgress: false,
				requestError: true,
				requestSuccess: false,
			});
		}
	};

	return (
		<Container>
			<Row className="mt-2 mt-md-4">
				<Col>
					<CenterScreen title="">
						<Row>
							<Col>
								<ListGroup>
									<ListGroupItem color="warning">
										<i className="fa fa-exclamation-circle" />
										&nbsp; This download link has expired.
									</ListGroupItem>
								</ListGroup>
							</Col>
						</Row>
						<Row>
							<Col className="mt-2">
								Please enter the email address you used when placing
								this order to get a new link.
							</Col>
						</Row>
						<Row>
							<Col className="mt-1">
								<Formik<ISchema>
									initialValues={intialSchema}
									validationSchema={schema}
									onSubmit={handleSubmitAsync}
								>
									{(formikProps) => (
										<Form>
											<FormikTextbox
												type={InputTypes.email}
												label="Email"
												name={nameof<ISchema>((x) => x.email)}
											></FormikTextbox>
											<Row className="mt-2">
												<Col xs="8" md="6" lg="4">
													<AjaxButton
														type="button"
														className="btn btn-lg btn-primary w-100"
														loading={status.requestInProgress}
														loadingText="Processing..."
														onClick={() =>
															formikProps.submitForm()
														}
													>
														Submit
													</AjaxButton>
												</Col>
											</Row>
											{status.requestError && (
												<Row className="mt-1">
													<Col>
														<ListGroup>
															<ListGroupItem color="danger">
																<i className="fa fa-exclamation-circle" />{" "}
																There was an error when
																submitting your request. Please
																try again later.
															</ListGroupItem>
														</ListGroup>
													</Col>
												</Row>
											)}
										</Form>
									)}
								</Formik>
							</Col>
						</Row>
					</CenterScreen>
				</Col>
			</Row>
		</Container>
	);
};
