import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import { Img } from "src/components/common/img";
import utils from "src/shared/utils";
import appConfig from "src/shared/utils/appConfig";
import { ICartFunctions } from "src/store/beatsShop/cart/cart.actions";
import { CartItemTypes } from "src/store/beatsShop/cart/cart.state";
import { ReleasePriceButton } from "./releasePriceButton";

interface IProps {
	release: ReleaseDto;
	width?: string;
	showDescription?: boolean;

	cartFunctions: ICartFunctions;
}

export const ReleaseGridItem: React.FC<IProps> = (props) => {
	let { release, width, showDescription } = props;
	showDescription = showDescription || false;
	const location = useLocation();
	const link = `/Release/${encodeURIComponent(release.title.trim())}/${
		release.catalogNo
	}`;
	const renderLink = !location.pathname
		.trim()
		.toLowerCase()
		.endsWith(link.trim().toLowerCase());

	let title = <>{release.title}</>;
	if (release.isExclusive) {
		title = (
			<>
				{release.title}
				<img
					className="ms-q"
					src={`${appConfig.domains.legacyWeb}/generic/bu-exclusive-blue.png?h=25`}
				/>
			</>
		);
	}

	return (
		<Card
			key={release.guid}
			className="shadow-hover mb-2 ms-0 me-1 release-grid-item"
		>
			<CardBody>
				<Row>
					<Col>
						{renderLink ? (
							<Link to={link}>
								<Img
									alt={release.title}
									className="rounded w-100"
									style={{ maxWidth: width }}
									src={release.imageUrl}
									altSrc={release.altImageUrl}
									square={parseInt(width ?? "300")}
								/>
							</Link>
						) : (
							<Img
								alt={release.title}
								className="rounded w-100"
								style={{ maxWidth: width }}
								src={release.imageUrl}
								altSrc={release.altImageUrl}
								square={parseInt(width ?? "300")}
							/>
						)}
					</Col>
				</Row>
				<Row className="pt-2">
					<Col>
						<div>
							<div className="d-flex align-items-center">
								<div className="flex-grow-1">
									<h3>
										{renderLink ? (
											<Link to={link} className="hover-primary">
												{title}
											</Link>
										) : (
											<span>{title}</span>
										)}
									</h3>
								</div>
								<div className="flex-grow-0">
									{showDescription ? (
										<div className="text-end">
											<ReleasePriceButton
												release={release}
												onClick={() => {
													props.cartFunctions.AddToCart(
														release.guid,
														CartItemTypes.Release
													);
												}}
											/>
										</div>
									) : null}
								</div>
							</div>
							<hr />
							<div className="d-flex">
								<div className="flex-grow-0">
									<span className="font-weight-bold">
										{utils.dateTime.format(
											release.releaseDateIso,
											"mmmm dd, yyyy"
										)}
									</span>
								</div>
								<div className="flex-grow-1 text-end">
									<div className="badge badge-pill badge-secondary">
										{release.trackCount} track
										{release.trackCount > 1 ? "s" : ""}
									</div>
								</div>
							</div>
							<hr />
							<div>
								{release.genres.map((genre, i) => {
									return (
										<div
											key={genre}
											className="badge badge-pill badge-info me-h"
										>
											{genre}
										</div>
									);
								})}
							</div>
							{showDescription ? (
								<>{release.description}</>
							) : (
								<div className="text-end">
									<ReleasePriceButton
										release={release}
										onClick={() => {
											props.cartFunctions.AddToCart(
												release.guid,
												CartItemTypes.Release
											);
										}}
									/>
								</div>
							)}
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};
