import Axios from "axios";
import { UserDto } from "src/api/models/dto/users/userDto";
import appConfig from "src/shared/utils/appConfig";
import { IRestResponse } from "typed-rest-client";
import { AuthService } from "../auth/authService";
import { BaseService } from "../baseService";

export class UserService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async getCurrentUserAsync(
		accessToken: string
	): Promise<IRestResponse<UserDto> | null> {
		try {
			const path = "/api/v1/users/user/currentUser";
			const config = await super.getAxiosConfigAsync(true);
			const response = await Axios.get<UserDto>(path, config);
			return super.getRestResponse(response);
		} catch {
			await AuthService.removeUserFromStorage();
		}
		return null;
	}
}
