import axios from "axios";
import { OrderHistoryRequest } from "src/api/models/v1/requests/beatsShop/orderHistoryRequest";
import { OrderHistoryResponse } from "src/api/models/v1/responses/beatsShop/orderHistoryResponse";
import { BaseService } from "src/api/services/baseService";
import appConfig from "src/shared/utils/appConfig";

export class HistoryService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async getOrderHisotryAsync(
		pageNumber?: number,
		pageSize?: number
	): Promise<OrderHistoryResponse | null> {
		const request: OrderHistoryRequest = {
			pageNumber: pageNumber || 1,
			pageSize: pageSize || 10,
		};
		const config = await super.getAxiosConfigAsync(true);
		const url = "/api/v1/beatsshop/order/history";
		const requestUrl = super.appendQueryString(url, request);

		try {
			const response = await axios.get<OrderHistoryResponse>(
				requestUrl,
				// request,
				config
			);

			return response.data;
		} catch (error) {}
		return null;
	}
}
