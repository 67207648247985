import Axios from "axios";
import { GuestOrderResponse } from "src/api/models/v2/responses/beatsShop/guestOrderResponse";
import appConfig from "src/shared/utils/appConfig";
import { BaseService } from "../../baseService";

export class OrderService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async getGuestOrder(
		guestOrderGuid: string
	): Promise<GuestOrderResponse> {
		if (guestOrderGuid === "") {
			throw Error("Invalid request");
		}

		const config = await super.getAxiosConfigAsync(false);
		const url = `/api/v1/beatsShop/order/guestOrder/${guestOrderGuid}`;

		const response = await Axios.get(url, config);

		try {
			await Axios.patch(url, null, config);
		} catch (error) {}

		return response.data;
	}

	public async sendNewDownloadAsync(
		guestOrderGuid: string,
		email: string
	): Promise<boolean> {
		const config = await super.getAxiosConfigAsync(false);
		const url = `/api/v1/beatsShop/order/guestOrder/${guestOrderGuid}`;

		var data = {
			email: email,
		};

		await Axios.post(url, data, config);
		return true;
	}
}
