import { FieldMetaProps } from "formik";
import React, { Component } from "react";
import { CardElement } from "react-stripe-elements";

interface IProps {
	id?: string;
	hasError: boolean;
	formikMeta?: FieldMetaProps<any>;

	onChange?: (e: stripe.elements.ElementChangeResponse) => void;
}

interface IState {
	isComplete: boolean;
	hasError: boolean;
	showLabel: boolean;
}

export class StripeSimple extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isComplete: false,
			hasError: this.props.hasError,
			showLabel: false,
		};
	}

	render() {
		let className = "form-control form-control-stripe";
		if (this.state.hasError || this.props.hasError)
			className += " is-invalid";
		if (this.state.showLabel) className += " focused";

		return (
			<>
				<CardElement
					id={this.props.id}
					hidePostalCode={true}
					classes={{
						focus: "focused",
					}}
					className={className}
					style={{
						base: {
							fontFamily: "Lato",
						},
					}}
					onChange={(e) => {
						this.setState((state, props) => {
							return { ...state, isComplete: e.complete };
						});
						if (this.props.onChange) {
							this.props.onChange(e);
						}
					}}
					onBlur={(e) =>
						this.setState((state, props) => {
							return {
								...state,
								hasError: !state.isComplete,
								showLabel: e.value?.toString() === "" ? false : true,
							};
						})
					}
				/>
			</>
		);
	}
}
