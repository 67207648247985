import classNames from "classnames";
import { isFunction } from "lodash";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";

interface IProps {
	release: ReleaseDto;
	onClick: () => void;
}

export const ReleasePriceButton = (props: IProps) => {
	const button = useRef<any>(null);

	const icon = props.release?.isInCart ? "cart-arrow-down" : "cart-plus";
	const tooltip = props.release?.isInCart ? "Already in Cart" : "Add to Cart";
	const priceText =
		(props.release?.price ?? 0) === 0
			? "Free"
			: `$${props.release?.price.toFixed(2)}`;

	const link = `/Release/${encodeURIComponent(props.release.title.trim())}/${
		props.release.catalogNo
	}`;
	const onNavClicked = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		try {
			if (isFunction(props.onClick)) {
				props.onClick();
			}
		} catch (error) {}

		e.preventDefault();
		return false;
	};

	return (
		<>
			<Link
				ref={button}
				to={link}
				key={`${props.release?.guid}`}
				className={classNames({
					"btn no-wrap border-0 btn-sm ripple-surface": true,
					"btn-outline-danger": !props.release.isInCart,
					"btn-danger": props.release.isInCart,
				})}
				title={tooltip}
				// outline={custom.outline || false}
				onClick={onNavClicked}
			>
				{priceText} <i className={`fa fa-${icon}`} />
			</Link>
		</>
	);
};
