import { Action, Reducer } from "redux";
import utils from "../../../shared/utils";
import { ActionTypes, KnownActions } from "./cart.actions";
import { ICartState, initialCartState } from "./cart.state";

export const cartReducer: Reducer<ICartState> = (
	currentState: ICartState = initialCartState,
	incomingAction: Action
): ICartState => {
	const action = incomingAction as KnownActions;
	var exhaustiveCheck: any = action;

	switch (action.type) {
		case ActionTypes.Initialized:
			if (action.payload?.length)
				return {
					...currentState,
					itemList: action.payload,
				};
			return currentState;

		case ActionTypes.ItemAddedToCart:
			let current = currentState.itemList.filter(
				(x) =>
					x.guid.toLowerCase().trim() ===
					action.payload.guid.toLowerCase().trim()
			);
			if (current && current.length) return currentState;

			let newItemList1 = utils.array.deepClone(currentState.itemList);

			newItemList1.push({
				addDate: new Date(),
				guid: action.payload.guid,
				type: action.payload.type,
				isAddedByRelease: action.payload.isAddedByRelease,
			});

			return {
				...currentState,
				itemList: newItemList1,
			};

		case ActionTypes.ItemRemovedFromCart:
			let currentList = currentState.itemList.filter(
				(x) =>
					x.guid.toLowerCase().trim() ===
					action.payload.guid.toLowerCase().trim()
			);
			if (!currentList || !currentList.length) {
				return currentState;
			}

			let newItemList2 = utils.array.cloneRemove(
				currentState.itemList,
				action.payload,
				(item) => item.guid
			);
			return {
				...currentState,
				itemList: newItemList2,
			};

		case ActionTypes.PaymentCompleted:
			return {
				...initialCartState,
				itemList: [],
			};

		default:
			exhaustiveCheck = action;
	}

	if (!exhaustiveCheck) return currentState || initialCartState;

	return currentState || initialCartState;
};
