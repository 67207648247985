import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import { releaseService } from "src/api/services/music/releaseService";
import { flatten } from "src/shared/utils/array";
import { AppThunkAction } from "src/store";
import { ArtistActionsCreator } from "src/store/artist/artist.actions";
import { storeDispatch } from "src/store/storeRegistry";

export enum ReleaseActionTypes {
	ReleaseInitialized = "[Release] Release Initialized",

	ReleasesByAliasRequestedCatalogNo = "[Release] Get Releases by CatalogNo Requested",
	ReleasesByAliasRequestedArtistAlias = "[Release] Get Releases by Alias Requested",
	ReleasesByAliasSuccess = "[Release] Get Releases by alias  Success",
	ReleasesByAliasError = "[Release] Get Releases by alias  Error",

	ReleasesByGuidsSuccess = "[Release] Get Releases by guids Error",

	ReleaseMarkedInCart = "[Release] Added Release to Cart",
	ReleaseRemovedFromCart = "[Release] Removed Release to Cart",
}

interface IReleaseInitialized {
	type: ReleaseActionTypes.ReleaseInitialized;
}

interface IReleasesByAliasRequestedArtistAlias {
	type: ReleaseActionTypes.ReleasesByAliasRequestedArtistAlias;
	payload: string;
}

interface IReleasesByAliasRequestedCatalogNo {
	type: ReleaseActionTypes.ReleasesByAliasRequestedCatalogNo;
	payload: string;
}

interface IReleasesByAliasSuccess {
	type: ReleaseActionTypes.ReleasesByAliasSuccess;
	payload: ReleaseDto[];
}

interface IReleasesByAliasError {
	type: ReleaseActionTypes.ReleasesByAliasError;
}

interface IReleasesByGuidsSuccess {
	type: ReleaseActionTypes.ReleasesByGuidsSuccess;
	payload: ReleaseDto[];
}
interface IReleaseMarkedInCart {
	type: ReleaseActionTypes.ReleaseMarkedInCart;
	payload: string;
}
interface IReleaseRemovedFromCart {
	type: ReleaseActionTypes.ReleaseRemovedFromCart;
	payload: string;
}

export type ReleaseKnownActions =
	| IReleaseInitialized
	| IReleasesByAliasRequestedArtistAlias
	| IReleasesByAliasRequestedCatalogNo
	| IReleasesByAliasSuccess
	| IReleasesByAliasError
	| IReleasesByGuidsSuccess
	| IReleaseMarkedInCart
	| IReleaseRemovedFromCart;

export const ReleaseActionCreator = {
	getReleasesByArtistAlias:
		(alias: string): AppThunkAction<ReleaseKnownActions> =>
		async (dispatch, getState) => {
			dispatch({
				type: ReleaseActionTypes.ReleasesByAliasRequestedArtistAlias,
				payload: alias,
			});

			const response = await new releaseService().getByArtistAliasAsync(
				alias
			);

			if (response && response.statusCode === 200 && response.result) {
				dispatch({
					type: ReleaseActionTypes.ReleasesByAliasSuccess,
					payload: response.result,
				});

				const allUserGuids = flatten<ReleaseDto, string>(
					response.result,
					(x) => x.trackUserGuids
				);

				storeDispatch(ArtistActionsCreator.GetArtistsByGuids(allUserGuids));
			} else {
				dispatch({
					type: ReleaseActionTypes.ReleasesByAliasError,
				});
			}
		},

	getReleaseByCatalogNo:
		(CatalogNo: string): AppThunkAction<ReleaseKnownActions> =>
		async (dispatch, getState) => {
			dispatch({
				type: ReleaseActionTypes.ReleasesByAliasRequestedCatalogNo,
				payload: CatalogNo,
			});

			const response = await new releaseService().getByCatalogNoAsync(
				CatalogNo
			);

			if (response && response.statusCode === 200 && response.result) {
				dispatch({
					type: ReleaseActionTypes.ReleasesByAliasSuccess,
					payload: [response.result],
				});

				storeDispatch(
					ArtistActionsCreator.GetArtistsByGuids(
						response.result.trackUserGuids
					)
				);
			} else {
				dispatch({
					type: ReleaseActionTypes.ReleasesByAliasError,
				});
			}
		},

	updateReleaseList:
		(releases: ReleaseDto[]): AppThunkAction<ReleaseKnownActions> =>
		async (dispatch, getState) => {
			dispatch({
				type: ReleaseActionTypes.ReleasesByGuidsSuccess,
				payload: releases,
			});
		},

	MarkReleaseInCart:
		(releaseGuid: string): AppThunkAction<ReleaseKnownActions> =>
		async (dispatch, getState) => {
			dispatch({
				type: ReleaseActionTypes.ReleaseMarkedInCart,
				payload: releaseGuid,
			});
		},

	RemovedReleaseFromCart:
		(releaseGuid: string): AppThunkAction<ReleaseKnownActions> =>
		async (dispatch, getState) => {
			dispatch({
				type: ReleaseActionTypes.ReleaseRemovedFromCart,
				payload: releaseGuid,
			});
		},
};
