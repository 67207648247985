import { Button, Col, Row } from "reactstrap";
import { IAuthStore } from "src/components/auth/authProvider";
import { Img } from "../common/img";

interface IProps {
	auth: IAuthStore;
	showSignout?: boolean;
}

export const BasicUserInfo = (props: IProps) => {
	const { user } = props.auth.authState;
	const showSignout = props.showSignout || false;

	if (!user) {
		return <></>;
	}

	return (
		<>
			<div className="d-flex align-item-center">
				<div className="me-1 flex-grow-0">
					<Img
						className="rounded"
						src={user.imageUrl}
						altSrc={user.altImageUrl}
						square={75}
					/>
				</div>
				<div className="flex-grow-1">
					<div className="font-weight-bold">
						{user.firstName} {user.lastName}
					</div>
					<div>{user.email}</div>
					<div>{user.nickName}</div>
				</div>
			</div>
			<div className="mt-2">
				<Row>
					<Col xs="12" md="6" lg="4">
						<Button
							className="w-100"
							type="button"
							size="lg"
							color="white"
							onClick={props.auth.authFunctions.Signout}
						>
							Not you? Sign out
						</Button>
					</Col>
				</Row>
			</div>
		</>
	);
};
