import { isArray } from "lodash";
import { ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { WebReactPermissionTypes } from "src/api/models/enums/permissions/webReactPermissionTypes";
import { CenterScreen } from "src/components/layout/centerScreen";
import appConfig from "src/shared/utils/appConfig";
import { IAuthFunctions } from "src/store/auth/auth.action";
import { IAuthState } from "src/store/auth/auth.state";

export interface IAuthStore {
	authState: IAuthState;
	authFunctions: IAuthFunctions;
}

interface IProps {
	authStore: IAuthStore;
	loggedOutTitle?: string;
	permissionsToCheck?: WebReactPermissionTypes[];

	loggedInTemplate: (auth: IAuthStore) => ReactElement;
	loggedOutTemplate?: (auth: IAuthStore) => ReactElement;
	unAuthorizedTemplate?: (auth: IAuthStore) => ReactElement;
}

const DefaultLoggedOutTemplate = (props: IProps) => {
	return (
		<>
			<CenterScreen
				title={props.loggedOutTitle || ""}
				md={{ size: 10, offset: 1 }}
				lg={{ size: 6, offset: 3 }}
			>
				<div className="text-center">
					<Button
						className="mt-2"
						type="button"
						color="primary"
						size="lg"
						onClick={props.authStore.authFunctions.Signin}
					>
						Sign In to Continue
					</Button>
					<a
						href={`${
							appConfig.domains.identity
						}/register?returnUrl=${encodeURI(window.location.href)}`}
						className="btn btn-lg ripple-surface text-no-wrap ms-1"
					>
						Create New Account
					</a>
				</div>
			</CenterScreen>
		</>
	);
};

const DefaultUnAuthorizedTemplate = (props: IProps) => {
	return (
		<>
			<CenterScreen
				title={props.loggedOutTitle || ""}
				md={{ size: 10, offset: 1 }}
				lg={{ size: 6, offset: 3 }}
			>
				<div className="text-center mt-2 text-danger">
					You are not authorized to view this page.
				</div>
				<div className="text-center mt-2">
					<Link to="/" className="btn btn-primary btn-lg">
						Home
					</Link>
				</div>
			</CenterScreen>
		</>
	);
};

export const AuthProvider = (props: IProps) => {
	useEffect(() => {
		async function workerAsync() {
			props.authStore.authFunctions.Initialize();
		}

		workerAsync();
	}, []);

	const loggedOutTemplate = props.loggedOutTemplate
		? props.loggedOutTemplate(props.authStore)
		: DefaultLoggedOutTemplate(props);

	const unAuthorizedTemplate = props.unAuthorizedTemplate
		? props.unAuthorizedTemplate(props.authStore)
		: DefaultUnAuthorizedTemplate(props);

	let isAuthorized = true;

	if (props.permissionsToCheck?.length) {
		if (props?.authStore?.authState?.authUser?.profile) {
			let permissions: string[] = [];
			const permissionsValue: string | string[] =
				props.authStore.authState.authUser.profile["permissions"];
			if (permissionsValue && permissionsValue.length) {
				if (!isArray(permissionsValue)) {
					permissions = [permissionsValue];
				} else {
					permissions = permissionsValue;
				}

				isAuthorized =
					(props.permissionsToCheck.filter((x) =>
						permissions.find((y) => y === x.toString())
					)?.length ?? 0) > 0;
			}
		}
	}
	return (
		<>
			{props?.authStore?.authState?.isLogedIn && isAuthorized ? (
				<>{props.loggedInTemplate(props.authStore)}</>
			) : !isAuthorized ? (
				<>{unAuthorizedTemplate}</>
			) : (
				<>{loggedOutTemplate}</>
			)}
		</>
	);
};
