import { StripeDto } from "src/api/models/v1/dto/payment/stripeDto";
import { CheckoutResponse } from "src/api/models/v1/responses/beatsShop/checkoutResponse";
import { IAjaxifyFlags } from "src/shared/ajaxify";
import validation from "src/shared/utils/validation";
import * as yup from "yup";

const Messages = validation.Messages;

export const OrderSchema = yup.object({
	userGuid: yup.string().ensure(),
	cardHolderName: yup.string().ensure().required(Messages.Required),
	stripeToken: yup.object<stripe.TokenResponse>(),
	requestGuid: yup.string().ensure(),
	isGuestCheckout: yup.bool(),

	firstName: yup
		.string()
		.ensure()
		.trim()
		.when("userGuid", {
			is: (value) => (value || "") === "",
			then: yup.string().required(),
		}),
	lastName: yup
		.string()
		.ensure()
		.trim()
		.when("userGuid", {
			is: (value) => (value || "") === "",
			then: yup.string().required(),
		}),
	email: yup
		.string()
		.ensure()
		.trim()
		.when("userGuid", {
			is: (value) => (value || "") === "",
			then: yup.string().email().required(),
		}),
	terms: yup.boolean().when("userGuid", {
		is: (value) => {
			return (value || "") === "";
		},
		then: yup.boolean().oneOf([true]),
	}),
});

export interface ICheckoutForm extends yup.InferType<typeof OrderSchema> {
	amount: number;
}

export interface IOrderState {
	form: ICheckoutForm;
	stripe: StripeDto;
	response: CheckoutResponse;
	ajaxifyFlags: IAjaxifyFlags;
}

export const initialOrderState: IOrderState = {
	form: {
		userGuid: "",
		cardHolderName: "",
		stripeToken: {},
		requestGuid: "",
		amount: 0,
		firstName: "",
		lastName: "",
		email: "",
		terms: false,
		isGuestCheckout: false,
	},
	stripe: {
		amount: 0,
		currency: "CAD",
		stripeToken: {
			id: "",
		},
	},
	response: {
		order: {
			currency: "",
			finalAmount: 0,
			guid: "",
			referenceNo: "",
			receiptUrl: "",
			status: "",
			statusValue: 0,
			orderDate: new Date(),
			trackGuids: [],
			releaseGuids: [],
			personalInfo: {
				confirmEmail: "",
				email: "",
				firstName: "",
				lastName: "",
			},
			isGuestCheckout: false,
		},
	},
	ajaxifyFlags: {
		requestInProgress: false,
		requestSuccess: false,
		requestError: false,
		errorMessage: "",
	},
};
