import { Waypoint } from "react-waypoint";

export function WaypointWrapper(props) {
	return (
		// key={props.key}
		<Waypoint onEnter={props.onEnter} onLeave={props.onLeave}>
			<span></span>
		</Waypoint>
	);
}
