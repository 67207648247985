import React from "react";
import { Field, FieldProps } from "formik";

interface IProps {
   name: string;
   label: string | JSX.Element;
}

export const FormikCheckbox = (props: IProps) => {
   const { name, label } = props;
   const inputName = `bu_checkbox_${encodeURI(name)}`;

   return (
      <Field name={name}>
         {(renderArgs: FieldProps<any>) => {
            const { field, meta } = renderArgs;
            return (
               <div
                  className={
                     meta.touched && meta.error
                        ? "form-check is-invalid-wraper"
                        : "form-check"
                  }
               >
                  <input
                     key={inputName}
                     type="checkbox"
                     checked={field.value}
                     className={
                        meta.touched && meta.error
                           ? "form-check-input is-invalid"
                           : "form-check-input"
                     }
                     id={inputName}
                     {...field}
                  />
                  <label
                     className={
                        meta.touched && meta.error
                           ? "form-check-label is-invalid"
                           : "form-check-label"
                     }
                     htmlFor={inputName}
                  >
                     <>{label}</>
                  </label>
               </div>
            );
         }}
      </Field>
   );
};
