import factory, { IAppConfig } from "./_app.config";
const appConfig = factory();

export default {
	...appConfig,

	environments: {
		...appConfig.environments,
		isProduction: false,
		isK8sSandbox: true,
		name: "aks.sandbox",
	},

	domains: {
		api: "https://api.sandbox.beatsunion.com",
		legacyWeb: "https://beatsunion-web-sandbox.azurewebsites.net",
		identity: "https://identity-server.sandbox.beatsunion.com",
		wordpress: "https://beatsunion.com",
		storage: "https://storage.sandbox.beatsunion.com",
	},
	auth: {
		authority: "https://identity-server.sandbox.beatsunion.com",
		client_id: "BeatsUnion.Web.React",
		response_type: "id_token token",
		scope: "openid profile offline_access api1.read",
		automaticSilentRenew: true,
		redirect_uri:
			"https://web-react.sandbox.beatsunion.com/signin-oidc",
		silent_redirect_uri:
			"https://web-react.sandbox.beatsunion.com/silent-signin-oidc",
		extraQueryParams: {
			signoutUrl:
				"https://web-react.sandbox.beatsunion.com/signout-oidc",
		},
	},
	azure: {
		applicationInsights: {
			instrumentationKey: "c281a936-ad79-4761-8a0e-668e8c791441",
		},
	},
} as IAppConfig;
