import React, { Component } from "react";
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel,
} from "react-accessible-accordion";
import { ChartFilterDto } from "src/api/models/v1/dto/music/chartFilterDto";
import { SearchCriteriaDto } from "src/api/models/v1/dto/music/searchCriteriaDto";
import { IAjaxifyFlags } from "src/shared/ajaxify";
import utils from "src/shared/utils";
import { Checkbox, Slider } from "../common";

export interface IFilter {
	genreIdList: number[];
	keyIdList: number[];

	bpmLow: number;
	bpmHigh: number;

	releaseDateLow: Date | null;
	releaseDateHigh: Date | null;
}

interface IState {
	filter: ChartFilterDto;
	timestamp: string;
	isOpen: boolean;
}

interface SearchCriteriaDtoWithTimestamp extends SearchCriteriaDto {
	timestamp: string;
}

interface IProps {
	searchCriteria: SearchCriteriaDto;
	ajaxifyFlags: IAjaxifyFlags;
	onChange: (filter: ChartFilterDto) => any;
}

const getInitialChartFilter = (props: IProps): ChartFilterDto => {
	return {
		bpmLow: props.searchCriteria.bpmLow,
		bpmHigh: props.searchCriteria.bpmHigh,
		durationLow: props.searchCriteria.durationLow,
		durationHigh: props.searchCriteria.durationHigh,
		releaseId: 0,
		keyIdList: [],
		genreIdList: [],
	};
};

export class Filter extends Component<IProps, IState> {
	state: IState = {
		isOpen: false,
		timestamp: "",
		filter: getInitialChartFilter(this.props),
	};

	constructor(props: IProps) {
		super(props);
		this.formatDuration = this.formatDuration.bind(this);
	}

	handleOnGenreChanged(genreId: number) {
		this.setState(
			(state, props) => {
				let genreIdList = utils.array.toggle(
					this.state.filter.genreIdList,
					genreId
				);
				return {
					...state,
					filter: {
						...state.filter,
						genreIdList,
					},
				};
			},
			() => {
				// callback
				if (this.props.onChange) this.props.onChange(this.state.filter);
			}
		);
	}

	handleOnKeyChanged(keyId: number) {
		this.setState(
			(state, props) => {
				var keyIdList = utils.array.toggle(
					this.state.filter.keyIdList,
					keyId
				);
				return {
					...state,
					filter: {
						...state.filter,
						keyIdList,
					},
				};
			},
			() => {
				// callback
				if (this.props.onChange) this.props.onChange(this.state.filter);
			}
		);
	}

	handleOnBpmChanged(values: number[]): void {
		this.setState(
			(state, props) => {
				var range = {
					low: values[0],
					high: values[1],
				};

				return {
					...state,
					filter: {
						...state.filter,
						bpmLow: parseInt(range.low.toString()),
						bpmHigh: parseInt(range.high.toString()),
					},
				};
			},
			() => {
				// callback
				if (this.props.onChange) this.props.onChange(this.state.filter);
			}
		);
	}

	handleOnDurationChanged(values: number[]): void {
		this.setState(
			(state, props) => {
				var range = {
					low: values[0],
					high: values[1],
				};

				return {
					...state,
					filter: {
						...state.filter,
						durationLow: parseInt(range.low.toString()),
						durationHigh: parseInt(range.high.toString()),
					},
				};
			},
			() => {
				// callback
				if (this.props.onChange) this.props.onChange(this.state.filter);
			}
		);
	}

	formatDuration(value: number): string {
		return utils.dateTime.formatPlayerTime(value);
	}

	toggle(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		this.setState((state, props) => {
			return {
				...state,
				isOpen: !state.isOpen,
			};
		});
	}

	render() {
		return (
			<React.Fragment>
				<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								<div className="float-end">
									<i className="fa fa-angle-down" />
								</div>
								Genre
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							{this.props.searchCriteria.genres.map((genre) => {
								var isChecked =
									(this.state.filter.genreIdList.find(
										(x) => x === genre.id
									) || -1) >= 0;
								return (
									<Checkbox
										caption={genre.text}
										key={`genre_${genre.id}`}
										checked={isChecked}
										onChanged={this.handleOnGenreChanged.bind(
											this,
											genre.id
										)}
									/>
								);
							})}
						</AccordionItemPanel>
					</AccordionItem>

					{/* <AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								<div className="float-end">
									<i className="fa fa-angle-down" />
								</div>
								Key
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							{this.props.searchCriteria.keys.map((key) => {
								var isChecked =
									(this.state.filter.keyIdList.find(
										(x) => x === key.id
									) || -1) > 0;
								return (
									<Checkbox
										caption={key.text}
										key={`key_${key.id}`}
										checked={isChecked}
										onChanged={this.handleOnKeyChanged.bind(
											this,
											key.id
										)}
									/>
								);
							})}
						</AccordionItemPanel>
					</AccordionItem> */}

					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								<div className="float-end">
									<i className="fa fa-angle-down" />
								</div>
								BPM
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							<div className="mt-h">
								{/* <label>BPM</label> */}
								<div className="pl-1 mt-h">
									<Slider
										range={{
											min: this.props.searchCriteria.bpmLow,
											max: this.props.searchCriteria.bpmHigh,
										}}
										connect={[false, true, false]}
										start={[
											this.props.searchCriteria.bpmLow,
											this.props.searchCriteria.bpmHigh,
										]}
										onChange={(values, rawValues) =>
											this.handleOnBpmChanged(values)
										}
									/>
								</div>
							</div>
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								<div className="float-end">
									<i className="fa fa-angle-down" />
								</div>
								Duration
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							<div className="mt-h">
								{/* <label>Duration</label> */}
								<div className="pl-1 mt-h">
									<Slider
										connect={[false, true, false]}
										formatValue={this.formatDuration}
										range={{
											min: this.props.searchCriteria.durationLow,
											max: this.props.searchCriteria.durationHigh,
										}}
										start={[
											this.props.searchCriteria.durationLow,
											this.props.searchCriteria.durationHigh,
										]}
										onChange={(values, rawValues) =>
											this.handleOnDurationChanged(values)
										}
									/>
								</div>
							</div>
						</AccordionItemPanel>
					</AccordionItem>
				</Accordion>
			</React.Fragment>
		);
	}
}

export default Filter;
