import { useState } from "react";
import { Table } from "reactstrap";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { AjaxButton } from "src/components/common";
import { Img } from "src/components/common/img";
import { ActionModes } from ".";
import { ArtistList } from "../common/artistList";
import { ButtonTypes, TrackButton } from "../common/trackButton";

interface IBaseProps {
	tracks: TrackDto[];
	actionMode: ActionModes;
}

//------------------- Download -------------------
interface IPropsDownload extends IBaseProps {
	downloadAsync: (track: TrackDto, fileType: string) => Promise<any>;
}

export const ChartListViewCompact_Download = (props: IPropsDownload) => {
	const componentProps: IComponentProps = {
		tracks: props.tracks,
		actionMode: props.actionMode,
		buttonFunctions: {
			downloadAsync: props.downloadAsync,
		},
	};

	return <ChartListViewCompact {...componentProps} />;
};

//------------------- Store -------------------
interface IPropsStore extends IBaseProps {
	play: (track: TrackDto) => void;
	pause: (track: TrackDto) => void;
	addToCart: (track: TrackDto) => void;
}

export const ChartListViewCompact_Store = (props: IPropsStore) => {
	const componentProps: IComponentProps = {
		tracks: props.tracks,
		actionMode: props.actionMode,
		buttonFunctions: {
			play: props.play,
			pause: props.pause,
			addToCart: props.addToCart,
		},
	};

	return <ChartListViewCompact {...componentProps} />;
};

//------------------- Generic Component -------------------

interface IChartButtonFunctions {
	play?: (track: TrackDto) => void;
	pause?: (track: TrackDto) => void;
	addToCart?: (track: TrackDto) => void;
	downloadAsync?: (track: TrackDto, fileType: string) => void;
}

interface IComponentProps extends IBaseProps {
	buttonFunctions: IChartButtonFunctions;
}

const getDictionaryKey = (track: TrackDto, fileType: string) => {
	return `${track.guid}_${fileType}`;
};

const ChartListViewCompact = (props: IComponentProps) => {
	const initialLoadingDictionary: { [key: string]: boolean } = {};
	const [loadingDictionary, setLoadingDictionary] = useState(
		initialLoadingDictionary
	);

	const handleAddToCartClick = (track: TrackDto) => {
		const { addToCart } = props.buttonFunctions;
		addToCart && addToCart(track);
	};

	const setIsLoading = (track: TrackDto, fileType: string, value: boolean) => {
		const key = getDictionaryKey(track, fileType);
		const newState = {
			...loadingDictionary,
			[key]: value,
		};
		setLoadingDictionary(newState);
	};

	const handlePlayPauseClick = (track: TrackDto) => {
		const { play, pause } = props.buttonFunctions;

		if (track.isPlaying) {
			pause && pause(track);
		} else {
			play && play(track);
		}
	};

	const handleDownload = async (track: TrackDto, fileType: string) => {
		const { downloadAsync } = props.buttonFunctions;
		setIsLoading(track, fileType, true);

		downloadAsync && (await downloadAsync(track, fileType));

		setIsLoading(track, fileType, false);
	};

	const RenderButtons = (props: {
		track: TrackDto;
		actionMode: ActionModes;
	}) => {
		const { track, actionMode } = props;
		if (actionMode === ActionModes.Store) {
			return (
				<>
					<TrackButton
						size="sm"
						track={track}
						type={ButtonTypes.PlayPause}
						onClick={() => handlePlayPauseClick(track)}
					/>
					<div className="d-inline-block" style={{ width: 75 }}>
						<TrackButton
							size="sm"
							className="w-100"
							track={track}
							type={ButtonTypes.AddToCart}
							onClick={() => handleAddToCartClick(track)}
						/>
					</div>
				</>
			);
		}

		if (actionMode == ActionModes.Download) {
			const isMp3Loading = loadingDictionary[getDictionaryKey(track, "mp3")];
			const isWavLoading = loadingDictionary[getDictionaryKey(track, "wav")];

			return (
				<>
					<AjaxButton
						wrapperClass="me-q"
						type="button"
						loading={isMp3Loading}
						className="btn btn-sm btn-primary font-sm"
						onClick={() => handleDownload(track, "mp3")}
					>
						MP3
					</AjaxButton>
					<AjaxButton
						type="button"
						loading={isWavLoading}
						className="btn btn-sm btn-info font-sm"
						onClick={() => handleDownload(track, "wav")}
					>
						WAV
					</AjaxButton>
				</>
			);
		}

		return <></>;
	};

	return (
		<>
			<div className="chart-list-compact-view d-flex flex-wrap text-black">
				<Table borderless={true} size="sm">
					<tbody>
						{props.tracks?.map((track, index) => {
							return (
								<tr
									key={index}
									className={`shadow-hover border-separator ${
										track.isPlaying ? "shadow-normal" : ""
									}`}
								>
									<td
										style={{ maxWidth: "200px", overflowX: "hidden" }}
									>
										<div className="d-flex align-items-center">
											<div className="me-h">
												<Img
													className="rounded shadow-normal clickable"
													src={track.release.imageUrl}
													altSrc={track.release.altImageUrl}
													square={50}
													alt={track.title}
													onClick={() =>
														handlePlayPauseClick(track)
													}
												/>
											</div>
											<div className="">
												<div>
													<strong>{track.title}</strong>
												</div>
												<div className="text-muted font-size-sm">
													<ArtistList track={track} />
												</div>
											</div>
										</div>
									</td>
									<td className="text-end text-nowrap">
										<RenderButtons
											track={track}
											actionMode={props.actionMode}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		</>
	);
};
