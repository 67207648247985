import React, { Component, ReactNode } from "react";
import { Waypoint } from "react-waypoint";
import { Button, Col, Row } from "reactstrap";
import { ChartFilterDto } from "src/api/models/v1/dto/music/chartFilterDto";
import { ChartTypes } from "src/api/models/v1/enums/music/charts/chartTypes";
import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { WaypointWrapper } from "src/components/common/waypointWrapper";
import { ChartLayouts } from "src/shared/enums";
import { ICartFunctions } from "src/store/beatsShop/cart/cart.actions";
import { CartItemTypes } from "src/store/beatsShop/cart/cart.state";
import { ILayoutChangedPayload } from "src/store/music/chart/chart.actions";
import { IChartState } from "src/store/music/chart/chart.state";
import { IPlayerFunctions } from "src/store/player/player.actions";
import { ReleasePriceButton } from "../release/releasePriceButton";
import { ChartGridView } from "./chartGridView";
import { ChartListView } from "./chartListView";
import { ChartListViewCompact_Store } from "./chartListViewCompact";

export enum ActionModes {
	None,
	Store,
	Download,
}

export interface IChartFunctions {
	cartFunctions?: ICartFunctions;
	playerFunctions?: IPlayerFunctions;

	changeLayout?: (payload: ILayoutChangedPayload) => void;
	loadChart?: (
		chartType: ChartTypes,
		filter?: ChartFilterDto | null,
		chartGuid?: string,
		pageNumber?: number,
		alias?: string
	) => void;
	loadChartByCatalogNo?: (catalogNo: string) => any;
	loadChartByAlias?: (alias: string, pageNo: number) => any;
}

export interface IChartProps {
	title: string;
	hideTitle?: boolean;
	maxHeight?: string;
	containerCssClass?: string;
	chart: IChartState;
	actionMode: ActionModes;
	showLayoutToggle: boolean;
	showLayoutToggleOnRight?: boolean;
	alias?: string;

	release?: ReleaseDto;
	showAddToCartButton?: boolean;

	chartFunctions: IChartFunctions;
	cartFunctions: ICartFunctions;
}

interface ISate {
	pageNumber: number;
	hasMore: boolean;
	shouldLoadMore: boolean;
}

export interface IChartViewComponent {
	showLoading(): void;
	hideLoading(): void;
}

export class Chart extends Component<IChartProps, ISate> {
	constructor(props: IChartProps) {
		super(props);
		this.state = {
			pageNumber: 1,
			hasMore: true,
			shouldLoadMore: true,
		};

		this.loadNextPage = this.loadNextPage.bind(this);
	}

	onFilterChanged() {
		this.setState({
			pageNumber: 1,
			hasMore: true,
			shouldLoadMore: true,
		});
	}

	handleChangeLayoutClick = (layout: ChartLayouts) => {
		if (!this.props.chartFunctions.changeLayout) return;

		this.props.chartFunctions.changeLayout({
			layout,
			chartType: this.props.chart.type,
			chartGuid: this.props.chart.chartGuid,
		});
	};

	handleOnEnter(args?: Waypoint.CallbackArgs) {
		if ((args?.waypointTop ?? 0) === 0) {
			return;
		}

		this.setState({
			shouldLoadMore: true,
		});

		if (this.state.hasMore && this.state.shouldLoadMore) {
			this.loadNextPage();
		}
	}

	handleOnLeave(args?: Waypoint.CallbackArgs) {
		this.setState({
			shouldLoadMore: false,
		});
	}

	loadNextPage() {
		if (
			!this.props?.chartFunctions?.loadChart ||
			this.props.chart.type === ChartTypes.Unknown
		)
			return;

		if (
			this.props.chart.type !== ChartTypes.DefaultUI &&
			this.props.chart.type !== ChartTypes.Artist
		) {
			return;
		}

		if (!this.props.chart.paging) {
			return;
		}

		if (
			this.state.pageNumber >=
			(this.props.chart.paging.pageCount || this.state.pageNumber)
		)
			return;

		const { pageNumber, pageCount } = this.props.chart.paging; // || { pageNumber: 1, pageCount: 2 };
		this.setState({
			hasMore: pageNumber + 1 < (pageCount || pageNumber),
			pageNumber: (pageNumber || 0) + 1,
		});

		this.props.chartFunctions.loadChart(
			this.props.chart.type,
			this.props.chart.filter || null,
			this.props.chart.chartGuid,
			this.state.pageNumber,
			this.props.alias
		);
	}

	renderChartByLayout(): ReactNode {
		let view: JSX.Element | null = null;

		const { chart } = this.props;

		if (chart.layout === ChartLayouts.Grid) {
			view = <ChartGridView {...this.props} />;
		} else if (chart.layout === ChartLayouts.List) {
			view = <ChartListView {...this.props} />;
		} else if (chart.layout === ChartLayouts.ListCompact) {
			const props = {
				tracks: this.props.chart.trackList,
				actionMode: ActionModes.Store,
				play: (track: TrackDto) => {
					this.props.chartFunctions.playerFunctions?.play(track.guid);
				},
				pause: (track: TrackDto) => {
					this.props.chartFunctions.playerFunctions?.pause(track.guid);
				},
				addToCart: (track: TrackDto) => {
					this.props.cartFunctions?.AddToCart(
						track.guid,
						CartItemTypes.Track
					);
				},
			};
			view = <ChartListViewCompact_Store {...props} />;
		}

		return (
			<div className={this.props.containerCssClass}>
				{view}

				<WaypointWrapper
					key={
						chart.paging?.pageNumber +
						"_" +
						chart.paging?.pageSize +
						"_" +
						chart.paging?.pageCount
					}
					onEnter={(args: Waypoint.CallbackArgs) =>
						this.handleOnEnter(args)
					}
					onLeave={(args: Waypoint.CallbackArgs) =>
						this.handleOnLeave(args)
					}
				/>
			</div>
		);
	}

	render() {
		const isGrid = this.props.chart.layout === ChartLayouts.Grid;
		const { release } = this.props;
		const priceText =
			(release?.price ?? 0) === 0 ? "Free" : `$${release?.price.toFixed(2)}`;

		return (
			<React.Fragment>
				<Row>
					<Col className="d-flex align-items-center">
						<div className="flex-grow-0 me-3">
							{this.props.hideTitle ? null : <h2>{this.props.title}</h2>}
						</div>
						{this.props.showAddToCartButton && release ? (
							<div className="flex-grow-0 me-1">
								<ReleasePriceButton
									release={release}
									onClick={() => {
										this.props.cartFunctions.AddToCart(
											release.guid,
											CartItemTypes.Release
										);
									}}
								/>
							</div>
						) : null}
						<div
							className={`flex-grow-1 ${
								this.props.showLayoutToggle ? "" : "hidden"
							} ${
								this.props.showLayoutToggleOnRight
									? "text-end me-1"
									: "me-3"
							}`}
						>
							<Button
								color="primary"
								outline={!isGrid}
								className="btn-sm border-0 me-q"
								onClick={this.handleChangeLayoutClick.bind(
									this,
									ChartLayouts.Grid
								)}
							>
								<i className="fa fa-th" />
							</Button>
							<Button
								color="primary"
								outline={isGrid}
								className="btn-sm border-0"
								onClick={this.handleChangeLayoutClick.bind(
									this,
									ChartLayouts.List
								)}
							>
								<i className="fa fa-list-ul" />
							</Button>
						</div>
					</Col>
				</Row>

				<Row className="mt-1">
					<Col className="">
						<div className="chart-track-list-wrapper">
							<div className="chart-track-list">
								{this.renderChartByLayout()}
							</div>
						</div>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

export default Chart;
