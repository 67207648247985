export function castCaseInsensitive<TTarget>(source: object): TTarget {
	const target = {} as any as TTarget;

	const sourceKeys = Object.getOwnPropertyNames(source);
	const targetKeys = Object.getOwnPropertyNames(target);

	targetKeys.forEach((key) => {
		const sourceKey = sourceKeys.find(
			(x) => x.toLowerCase() === key.toLowerCase()
		);
		if (sourceKey) {
			const value = (source as any)[sourceKey];
			(target as any)[key] = (source as any)[sourceKey];
		}
	});

	return target;
}

export default {
	castCaseInsensitive,
};
