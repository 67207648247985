import Axios from "axios";
import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";
import appConfig from "src/shared/utils/appConfig";
import { IRestResponse } from "typed-rest-client";
import { BaseService } from "../baseService";

export class ArtistService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async getByAliasAsync(
		alias: string
	): Promise<IRestResponse<ArtistDto>> {
		const path = `api/v2/users/artist/alias/${encodeURIComponent(
			alias.trim()
		)}`;
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.get<ArtistDto>(path, config);
		return super.getRestResponse(response);
	}

	public async getByGuidsAsync(
		guids: string[]
	): Promise<IRestResponse<ArtistDto[]>> {
		// const guidsCsv = string.getUrlEncodedCsv(guids);
		// const path = `api/v1/users/artist/guid/${guidsCsv}`;
		const path = `api/v2/users/artist/guids`;
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.post<ArtistDto[]>(path, guids, config);
		return super.getRestResponse(response);
	}
}
