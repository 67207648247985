import classnames from "classnames";
import { useEffect, useState } from "react";
import {
	Col,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from "reactstrap";
import { ChartTypes } from "src/api/models/v1/enums/music/charts/chartTypes";
import { initialAjaxifyFlags } from "src/shared/ajaxify";
import { ChartLayouts } from "src/shared/enums";
import { IChartState } from "src/store/music/chart/chart.state";
import { mapToPlayerFunctions } from "src/store/player/player.actions";
import { IReduxProps } from "src/store/storeHelper";
import { ArtistGridView } from "../artist/artistGridView";
import Chart, { ActionModes, IChartFunctions } from "../music/chart";
import { ReleaseGridView } from "../music/release/releaseGridView";

interface IProps extends IReduxProps {
	searchTerm: string;
}

export const SearchResults = (props: IProps) => {
	const trackCount = 50;
	const releaseCount = 50;
	const artistCount = 50;

	const { searchTerm } = props;

	const [activeTab, setActiveTab] = useState<string>("1");

	useEffect(() => {
		async function fetchAsync() {
			await props.Actions.Search.SearchAsync(
				searchTerm,
				trackCount,
				releaseCount,
				artistCount
			);
		}

		fetchAsync();
	}, [searchTerm]);

	if (props.State.Search.response == null) {
		return <></>;
	}

	let { tracks, releases, artists } = props.State.Search.response;
	artists = artists.filter((x) => x.isActive);
	const artistUsers = artists.filter((x) => !x.isRecordLabel);
	const recordLabels = artists.filter((x) => x.isRecordLabel);

	const chartState: IChartState = {
		height: null,
		chartGuid: "",
		trackList: tracks || [],
		type: ChartTypes.Unknown,
		layout: ChartLayouts.Grid,
		ajaxifyFlags: initialAjaxifyFlags,
	};

	const chartFunctions: IChartFunctions = {
		playerFunctions: mapToPlayerFunctions(props.Actions.Player),
	};

	const cartFunctions = props.Actions.BeatsShop.Cart;

	const toggle = (tab: string) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	return (
		<>
			<Nav tabs className="border-bottom">
				<NavItem className="flex-grow-1 text-center">
					<NavLink
						className={classnames({
							active: activeTab === "1",
							clickable: activeTab !== "1",
							"text-capitalize": true,
						})}
						onClick={() => {
							toggle("1");
						}}
					>
						<h3>Tracks</h3>
					</NavLink>
				</NavItem>
				<NavItem className="flex-grow-1 text-center">
					<NavLink
						className={classnames({
							active: activeTab === "2",
							clickable: activeTab !== "2",
							"text-capitalize": true,
						})}
						onClick={() => {
							toggle("2");
						}}
					>
						<h3>Releases</h3>
					</NavLink>
				</NavItem>
				<NavItem className="flex-grow-1 text-center">
					<NavLink
						className={classnames({
							active: activeTab === "3",
							clickable: activeTab !== "3",
							"text-capitalize": true,
						})}
						onClick={() => {
							toggle("3");
						}}
					>
						<h3>Artists</h3>
					</NavLink>
				</NavItem>
				<NavItem className="flex-grow-1 text-center">
					<NavLink
						className={classnames({
							active: activeTab === "4",
							clickable: activeTab !== "4",
							"text-capitalize": true,
						})}
						onClick={() => {
							toggle("4");
						}}
					>
						<h3>Record Labels</h3>
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId="1">
					<Row className="mt-1">
						<Col>
							{tracks?.length ? (
								<Chart
									actionMode={ActionModes.Store}
									chart={chartState}
									cartFunctions={cartFunctions}
									chartFunctions={chartFunctions}
									showLayoutToggle={false}
									title=""
								/>
							) : (
								<h3 className="text-center">
									No Track found for your search
								</h3>
							)}
						</Col>
					</Row>
				</TabPane>
				<TabPane tabId="2">
					<Row className="mt-1">
						<Col>
							{releases?.length ? (
								<ReleaseGridView
									releases={releases}
									cartFunctions={cartFunctions}
								/>
							) : (
								<h3 className="text-center mt-2">
									No Release found for your search
								</h3>
							)}
						</Col>
					</Row>
				</TabPane>
				<TabPane tabId="3">
					<Row className="mt-1">
						<Col>
							{artistUsers?.length ? (
								<ArtistGridView artists={artistUsers} />
							) : (
								<h3 className="text-center mt-2">
									No Artist found for your search
								</h3>
							)}
						</Col>
					</Row>
				</TabPane>
				<TabPane tabId="4">
					<Row className="mt-1">
						<Col>
							{recordLabels?.length ? (
								<ArtistGridView artists={recordLabels} />
							) : (
								<h3 className="text-center mt-2">
									No Record Label found for your search
								</h3>
							)}
						</Col>
					</Row>
				</TabPane>
			</TabContent>
		</>
	);
};
