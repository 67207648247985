
export const whichAnimationEvent = (element: HTMLElement): string | null => {

    let animations = {
        "animation": "animationend",
        "OAnimation": "oAnimationEnd",
        "MozAnimation": "animationend",
        "WebkitAnimation": "webkitAnimationEnd"
    }

    for (let t in animations) {
        if ((element as any).style[t] !== undefined) {
            return (animations as any)[t];
        }
    }

    return null;
}

export const onAnimationDone = (element: HTMLElement | null, callback: (element: HTMLElement, e?: Event) => void) => {
    if (element) {
        var animationEvent = whichAnimationEvent(element)
        if (animationEvent)
            element.addEventListener(animationEvent, (e: Event) => callback(element, e));
    }
}



export default {
    whichAnimationEvent,
    onAnimationDone
};

