import { Fragment } from "react";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { IPriceCalc } from "src/components/beatsShop";
import { Img } from "src/components/common/img";
import { RemoveButton } from "src/components/common/removeButton";
import utils from "src/shared/utils";
import { createNewGuid } from "src/shared/utils/string";
import { ICartFunctions } from "src/store/beatsShop/cart/cart.actions";
import { CartItemTypes } from "src/store/beatsShop/cart/cart.state";
import { ArtistList } from "../common/artistList";

interface IProps extends IPriceCalc {
	cartFunctions: ICartFunctions;
}

const getTrackList = (
	trackGuidList: string[],
	trackList: TrackDto[]
): TrackDto[] => {
	const result: TrackDto[] = [];
	for (var i = 0; i < trackGuidList.length; i++) {
		const guid = trackGuidList[i];
		const track = trackList.find(
			(x) => x.guid.toLowerCase().trim() === guid.toLowerCase().trim()
		);
		if (track) result.push(track);
	}
	return result;
};

export const CartSummaryView = (props: IProps) => {
	const trackGuidList = props.cartItemList
		.filter((x) => x.type === CartItemTypes.Track && !x.isAddedByRelease)
		.map((item, index) => item.guid);
	const trackList = getTrackList(trackGuidList, props.trackList);

	return (
		<div className="cart-summary">
			{props.cartItemList.map((cartItem, index) => {
				const track = trackList.find(
					(x) =>
						x.guid.toLowerCase().trim() ===
						cartItem.guid.toLowerCase().trim()
				);
				if (!track) {
					return <Fragment key={createNewGuid()}></Fragment>;
				}
				const durationString = utils.dateTime.formatPlayerTime(
					track?.duration || 0
				);

				return (
					<div
						key={cartItem.guid}
						className="cart-summary-item d-flex px-h py-1 border-separator align-items-center shadow-hover"
					>
						<div className="flex-grow-0 ps-h">
							<Img
								className="rounded shadow-normal me-h"
								src={track.release.imageUrl}
								altSrc={track.release.altImageUrl}
								alt={track.title}
								square={75}
							/>
						</div>
						<div className="flex-grow-1 ps-h">
							<div>
								<strong>{track.title}</strong>
							</div>
							<div className="font-size-sm">
								<ArtistList track={track} />
							</div>
							<div className="font-size-sm text-muted">
								{[durationString, track?.genre].join(", ")}
								{/* , {track?.key} */}
							</div>
						</div>
						<div className="flex-grow-0 ps-h text-end">
							<div className="pb-h ps-h">
								<strong>{"$" + track?.price.toFixed(2)}</strong>
							</div>
							<div>
								<RemoveButton
									onConfirmed={() =>
										props.cartFunctions.RemoveFromCart(
											track.guid,
											CartItemTypes.Track
										)
									}
								/>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};
