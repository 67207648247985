import { formatDistanceToNow, parseISO } from "date-fns";
import dateFormat from "dateformat";

export const format = (
	date: string | number | Date,
	template: string
): string => {
	return dateFormat(date, template);
};

export const formatPlayerTime = (time: number): string => {
	var date = new Date(0);
	date.setSeconds(time);
	var result = format(date, "MM:ss");
	if (result.toLowerCase() === "invalid time") result = "--:--";

	return result;
};

export const toLocalString = (date: string | Date): string => {
	const dateFns = parseISO(date.toString());
	return dateFns.toLocaleDateString();
};

export const toAgo = (date: Date): string => {
	try {
		return formatDistanceToNow(date);
	} catch {}
	return "";
};

export default {
	toAgo,
	format,
	formatPlayerTime,
	toLocalString,
};
