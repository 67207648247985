import React, { Component, ReactElement, Children } from "react";
import { ColorCodes } from '../../shared/enums';
import util from '../../shared/utils';

interface IRadioGroupProp<TValue> {
	name: string;
	className?: string;
	selectedValue: TValue;
	children: ReactElement<IRadioProps<TValue>>[];
	onChange: (value: TValue, e?: React.ChangeEvent<HTMLInputElement>) => any;
}

interface IRadioGroupState<TValue> {
	value: TValue;
}


export class RadioGroup<TValue> extends Component<IRadioGroupProp<TValue>, IRadioGroupState<TValue>> {

	state = {
		value: this.props.selectedValue
	};

	handleOnRadioChange = (e?: React.ChangeEvent<HTMLInputElement>) => {

		if (e && e.target) {
			var newValue = (e.target.dataset["data"] as any) as TValue;
			this.setState((state, props) => {
				return {
					value: newValue
				}
			},
				() => {
					if (this.props.onChange)
						this.props.onChange(newValue, e);
				}
			);
		}
	};

	render() {


		return (
			<div className={this.props.className}>
				{
					Children.map(this.props.children, (child: ReactElement<IRadioProps<TValue>>, index: number) => {
						return (
							<div className="radio-wrapper">
								<Radio
									{...child.props}
									groupName={this.props.name}
									//eslint-disable-next-line
									checked={child.props.value == this.state.value}
									_onRadioClick={(e?: React.ChangeEvent<HTMLInputElement>) => this.handleOnRadioChange(e)}
								/>
							</div>
						)
					})
				}
			</div>
		);
	}

}


interface IRadioProps<TValue> {

	color?: ColorCodes;
	checked?: boolean;
	groupName?: string;

	text: string
	value: TValue;

	_onRadioClick?: (e?: React.ChangeEvent<HTMLInputElement>) => any;
}


export class Radio<TValue> extends Component<IRadioProps<TValue>> {

	guid: string = util.string.createNewGuid();

	handleOnClick(e?: React.MouseEvent<HTMLElement, MouseEvent>) {
		if (this.props._onRadioClick)
			this.props._onRadioClick(e as any)
	}

	render() {
		return (

			<label className={"radio abc-radio abc-radio-" + this.props.color} >
				<input
					className="form-radio-input"
					id={this.guid}
					type="radio"
					data-data={this.props.value}
					name={this.props.groupName}
					checked={this.props.checked}
					onChange={() => { }}
					onClick={this.handleOnClick.bind(this)} 
				/>

				<label className="form-radio-label" htmlFor={this.guid}>
					{this.props.text}
				</label>
			</label>
		)
	};
}
