import { Link } from "react-router-dom";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import appConfig from "src/shared/utils/appConfig";

interface IProps {
	track: TrackDto | null;
}

export const ReleaseName = (props: IProps) => {
	const { track } = props;

	let title = <>{track?.release.title.trim()}</>;
	if (track?.release.isExclusive) {
		title = (
			<>
				{track?.release.title}
				<img
					className="ms-q"
					src={`${appConfig.domains.legacyWeb}/generic/bu-exclusive-blue.png?h=20`}
				/>
			</>
		);
	}

	return (
		<>
			{track && track.release && (
				<Link
					className="hover-primary"
					to={`/Release/${encodeURIComponent(
						track.release.title.trim()
					)}/${track.release.catalogNo}`}
				>
					{title}
				</Link>
			)}
		</>
	);
};
