import appConfigFactory, { IAppConfig } from "./_app.config";
const appConfig = appConfigFactory();

export default {
	...appConfig,
	environments: {
		...appConfig.environments,
		isProduction: true,
		name: "Production",
	},

	domains: {
		api: "https://api.beatsunion.com",
		legacyWeb: "https://artistportal.beatsunion.com",
		wordpress: "https://beatsunion.com",
		identity: "https://identity.beatsunion.com",
		react: "https://store.beatsunion.com",
		storage: "https://storage.beatsunion.com",
	},

	stripe: {
		publishableKey: "pk_live_ESUQBOUVoqTRQCODJFi3R60Q00LbfFgFKN",
	},

	auth: {
		authority: "https://identity.beatsunion.com",
		client_id: "BeatsUnion.Web.React",
		response_type: "id_token token",
		scope: "openid profile offline_access api1.read",
		automaticSilentRenew: true,
		redirect_uri: "https://music.beatsunion.com/signin-oidc",
		silent_redirect_uri: "https://music.beatsunion.com/silent-signin-oidc",
		extraQueryParams: {
			signoutUrl: "https://music.beatsunion.com/signout-oidc",
		},
	},
} as IAppConfig;
