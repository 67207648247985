import React from "react";
import { Col, Container, Row } from "reactstrap";
import { CenterScreen } from "src/components/layout/centerScreen";

interface IProps {}

export const GuestOrderLoading = (props: IProps) => {
	return (
		<Container>
			<Row className="mt-2 mt-md-4">
				<Col>
					<CenterScreen title="">
						<Row>
							<Col>
								<h4 className="text-center">
									<i className="fa fa-spin fa-sync-alt" /> Loading...
								</h4>
							</Col>
						</Row>
					</CenterScreen>
				</Col>
			</Row>
		</Container>
	);
};
