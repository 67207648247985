import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";
import { AliasAndIcon } from "./aliasAndIcon";
import { Verified } from "./verified";

export const ArtistLink = (props: { artist: ArtistDto | null }) => {
	if (!(props?.artist?.alias ?? "").length) {
		return <span>{props.artist?.alias}</span>;
	}

	if (!props?.artist?.alias) {
		return null;
	}

	const link = `/${
		props.artist?.isRecordLabel ? "RecordLabel" : "Artist"
	}/${encodeURIComponent(props.artist.alias.trim())}/`;

	const location = useLocation();
	let renderLink = !location.pathname
		.trim()
		.toLowerCase()
		.endsWith(link.trim().toLowerCase());

	renderLink = renderLink && ((props.artist && props.artist.isActive) ?? true);

	return (
		<React.Fragment key={props.artist.guid}>
			{renderLink ? (
				<Link to={link} className="hover-primary">
					<AliasAndIcon artist={props.artist} />
				</Link>
			) : (
				<AliasAndIcon artist={props.artist} />
			)}
			{props.artist?.isRecordLabel
				? null
				: Verified(props.artist?.isVerified || false)}
		</React.Fragment>
	);
};
