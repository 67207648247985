import { PagingDto } from "src/api/models/v1/dto/common/pagingDto";
import { ChartFilterDto } from "src/api/models/v1/dto/music/chartFilterDto";
import { ChartTypes } from "src/api/models/v1/enums/music/charts/chartTypes";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { IAjaxifyFlags, initialAjaxifyFlags } from "src/shared/ajaxify";
import { ChartLayouts } from "src/shared/enums";

export interface IChartState {
	type: ChartTypes;
	height: string | null;
	filter?: ChartFilterDto;
	layout: ChartLayouts;
	chartGuid: string;

	paging?: PagingDto;
	trackList: Array<TrackDto>;
	ajaxifyFlags: IAjaxifyFlags;
}

export const initialChartState: IChartState = {
	height: null,
	chartGuid: "",
	trackList: [],
	type: ChartTypes.Unknown,
	layout: ChartLayouts.Grid,
	ajaxifyFlags: initialAjaxifyFlags,
};
