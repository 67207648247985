import Axios from "axios";
import { SearchCriteriaDto } from "src/api/models/v1/dto/music/searchCriteriaDto";
import appConfig from "src/shared/utils/appConfig";
import { IRestResponse } from "typed-rest-client";
import { BaseService } from "../baseService";

export class searchCriteriaService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async getAsync(): Promise<IRestResponse<SearchCriteriaDto>> {
		const path = `/api/v1/music/search/values`;
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.get<SearchCriteriaDto>(path, config);
		return super.getRestResponse(response);
	}
}
