import { FunctionComponent } from "react";
import { useLocation } from "react-router";
import { Card, CardBody } from "reactstrap";
import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";
import { Img } from "../common/img";
import { ArtistLink } from "./artistLink";

export interface IProps {
	alias: string;
	artist: ArtistDto | null;
}

export const ArtistDetails: FunctionComponent<IProps> = (props: IProps) => {
	const location = useLocation();
	const link = `/${
		props.artist?.isRecordLabel ? "RecordLabel" : "Artist"
	}/${encodeURIComponent(props.alias.trim())}/`;
	const renderLink = !location.pathname
		.trim()
		.toLowerCase()
		.endsWith(link.trim().toLowerCase());

	return (
		<Card className="mb-1 shadow-hover">
			<CardBody>
				<div className="d-sm-flex align-items-top">
					<div className="flex-grow-0 me-1">
						<Img
							square={205}
							className="rounded"
							alt={props.alias}
							src={props.artist?.imageUrl}
							altSrc={props.artist?.altImageUrl}
						/>
					</div>
					<div className="flex-grow-1">
						<div className="h1">
							<ArtistLink artist={props.artist} />
						</div>
						<div className="text-muted mb-1">
							{props.artist?.location}
						</div>
						<div
							style={{
								maxHeight: "230px",
								overflowY: "auto",
								textAlign: "justify",
								textJustify: "inter-word",
							}}
						>
							{props.artist?.biography}
						</div>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};
