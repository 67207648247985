import { Action, Reducer } from "redux";
import { ActionTypes, KnownActions } from "./player.actions";
import { initialPlayerState, IPlayerState } from "./player.state";



export const playerReducer: Reducer<IPlayerState> = (currentState: IPlayerState = initialPlayerState, incomingAction: Action): IPlayerState => {

	const action = incomingAction as KnownActions;
	let exhaustiveCheck: any = action;


	switch (action.type) {

		case ActionTypes.Initialized:
			return currentState || initialPlayerState;

		case ActionTypes.PlayPauseClicked:
			return {
				...currentState,
				isOpen: true,
				currentTrack: action.payload
			}

		case ActionTypes.Dismissed:
			return {
				...currentState,
				isOpen: false,
				currentTrack: null
			}





		case ActionTypes.PlaylistInitialized:

			return {
				...currentState,
				playlist: [...action.payload]
			}


        case ActionTypes.PlaylistAdded:

			return {
				...currentState,
				playlist: [...action.payload]
			}


		case ActionTypes.PlaylistRemoved:
			return {
				...currentState,
				playlist: [...action.payload]
			}


        case ActionTypes.PlaylistReordered:

			return {
				...currentState,
                playlist: [...action.payload]
			}



		default:
			exhaustiveCheck = action;
	}

	if (!exhaustiveCheck)
		return currentState || initialPlayerState;

	return currentState || initialPlayerState;
}
