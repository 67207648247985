import React from "react";
import { FileUpload } from "src/components/common/upload/fileUpload";
import utils from "src/shared/utils";
import appConfig from "src/shared/utils/appConfig";
import { FileTypes } from "src/shared/utils/files";
import { useQueryString } from "src/shared/utils/hooks";

interface IParams {
	userguid: string;
	completeurl: string;
	filetypes: string;
}

export const UploadView = () => {
	const qs = useQueryString<IParams>();

	if ((qs.userguid ?? "") === "") {
		if (appConfig.environments.isDevelopment) {
			return (
				<div className="list-group-item list-group-item-danger text-center">
					UserGuid must be passed in a `userguid` query string
				</div>
			);
		}
		return <></>;
	}

	if ((qs.completeurl ?? "") === "") {
		if (appConfig.environments.isDevelopment) {
			return (
				<div className="list-group-item list-group-item-danger text-center">
					Complete Url must be passed in a `completeurl` query string
				</div>
			);
		}
		return <></>;
	}

	React.useEffect(() => {
		async function workerAsync() {
			utils.dom.appendScriptTag(
				"/scripts/iframeResizer.contentWindow.min.js"
			);
		}

		workerAsync();
	});

	const fileTypes: FileTypes[] = qs.filetypes?.length
		? qs.filetypes
				.split(",")
				.map((x) => FileTypes[x as keyof typeof FileTypes])
		: [];

	return (
		<>
			<FileUpload
				userGuid={qs.userguid}
				onCompleteUrl={qs.completeurl}
				acceptableFileTypes={fileTypes}
			/>
		</>
	);
};
