import { isFunction } from "lodash";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";

export enum ButtonTypes {
	PlayPause,
	AddToPlaylist,
	AddToCart,
	Playlist,
	Download,
}

export type Size = "xs" | "sm" | "normal" | "lg";
type Color =
	| "primary"
	| "secondary"
	| "danger"
	| "info"
	| "success"
	| "warning";

interface IProps {
	onClick: () => void;

	size: Size;
	track: TrackDto | null;
	type: ButtonTypes;

	caption?: string;
	className?: string;
	alwaysOutlined?: boolean;
}

interface ICustomize {
	color: Color;
	icon: string;
	outline?: boolean;
	caption?: string;
	title?: string;
	className?: string;
}

export const TrackButton = (props: IProps) => {
	const customize = (
		type: ButtonTypes,
		track: TrackDto | null
	): ICustomize => {
		switch (type) {
			case ButtonTypes.PlayPause:
				return {
					color: "primary",
					outline: props.alwaysOutlined || !track?.isPlaying,
					icon: track?.isPlaying ? "pause" : "play",
					title: track?.isPlaying ? "pause" : "play",
				};

			case ButtonTypes.AddToPlaylist:
				return {
					color: "success",
					outline: !track?.isInPlaylist,
					icon: "plus-square",
					title: track?.isInPlaylist ? "In Playlist" : "Add to Playlist",
				};

			case ButtonTypes.Download:
				return {
					color: "primary",
					icon: "cloud-download-alt",
					title: "Download",
				};

			case ButtonTypes.AddToCart:
				const caption =
					(track?.price ?? 0) === 0
						? "Free"
						: "$" + track?.price.toFixed(2);
				if (track?.isInCart)
					return {
						color: "danger",
						outline: false,
						icon: "cart-arrow-down",
						caption: caption,
						title: "Already in Cart",
					};
				else
					return {
						color: "danger",
						outline: true,
						icon: "cart-plus",
						caption: caption,
						title: "Add to Cart",
					};

			default:
				return {
					color: "secondary",
					outline: true,
					icon: "",
				};
		}
	};

	const onNavClicked = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		try {
			if (isFunction(props.onClick)) {
				props.onClick();
			}
		} catch (error) {}

		e.preventDefault();
		return false;
	};

	const button = useRef<any>(null);
	const className = `no-wrap border-0 btn-${props.size} ${
		props.className ? props.className : ""
	}`;
	const custom = customize(props.type, props.track);

	if (props.type == ButtonTypes.AddToCart) {
		if (props.track?.isInCart) {
			custom.outline = false;
		}
		return (
			<Link
				ref={button}
				to={`/Track/${props.track?.release?.title}/${props.track?.release?.catalogNo}/${props.track?.guid}`}
				key={`${props.track?.guid}_${props.type}_${custom.icon}`}
				className={`btn btn-${custom.outline ? "outline-" : ""}${
					custom.color
				} ${className}`}
				title={custom.title}
				// outline={custom.outline || false}
				onClick={onNavClicked}
			>
				{custom.caption || ""} <i className={`fa fa-${custom.icon}`} />
			</Link>
		);
	} else if (custom.icon === "pause") {
		return (
			<Button
				ref={button}
				key={`${props.track?.guid}_${props.type}_${custom.icon}`}
				color={custom.color}
				className={className}
				title={custom.title}
				outline={custom.outline || false}
				onClick={props.onClick}
			>
				{custom.caption || ""} <i className={`fa fa-pause`} />
			</Button>
		);
	}
	return (
		<Button
			ref={button}
			key={`${props.track?.guid}_${props.type}_${custom.icon}`}
			color={custom.color}
			className={className}
			title={custom.title}
			outline={custom.outline || false}
			onClick={props.onClick}
		>
			{custom.caption || ""} <i className={`fa fa-${custom.icon}`} />
		</Button>
	);
};
