import React, { useState } from "react";
import { Table } from "reactstrap";
import { IFileType } from "src/shared/utils/files";
import { RemoveButton } from "../removeButton";

export interface FileAndKey {
	file: File;
	key: string;
	fileType: IFileType;
	isValid: boolean;
}

interface IProps {
	files: FileAndKey[];
	inProgress: boolean;
	progressDictionary: Map<string, number>;
	invalidTooltip?: string;
	onFileRemoved: (key: string, file: FileAndKey) => void;
}

export const FileListProgress = (props: IProps) => {
	const [checkedDictionary, setCheckedDictionary] = useState<
		Map<string, boolean>
	>(new Map<string, boolean>());

	const [allChecked, setAllChecked] = useState(false);

	const onCheckboxAllChange = (isChecked: boolean) => {
		const result = new Map<string, boolean>();

		for (const [key, value] of checkedDictionary.entries()) {
			result.set(key, isChecked);
		}

		setAllChecked(isChecked);
		setCheckedDictionary(result);
	};

	const onFileCheckboxChanged = (
		isChecked: boolean,
		key: string,
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		let allChecked = true;
		checkedDictionary.set(key, isChecked);

		for (const [key, value] of checkedDictionary.entries()) {
			allChecked = allChecked && value;
		}

		setAllChecked(allChecked);
	};

	const totalSize = props.files.reduce((sum, item) => sum + item.file.size, 0);
	let allProgress = 0;
	for (const [key, value] of props.progressDictionary.entries()) {
		allProgress += value;
	}
	const allProgressPercent = (
		totalSize === 0 ? 0 : (allProgress * 100) / totalSize
	).toFixed(0);

	let { invalidTooltip } = props;
	invalidTooltip =
		invalidTooltip ?? "This file doesn't seem to be a valid format!";
	return (
		<>
			<Table className="table-sm table-striped">
				<thead>
					<tr className="table-dark">
						{/* <th style={{ width: 20 }}></th> */}
						{/* <th style={{ width: 50 }}>
							<Checkbox
								key={"allCheckbox" + allChecked}
								onChanged={onCheckboxAllChange}
								checked={allChecked}
								disabled={props.inProgress}
							/>
						</th> */}
						{/* <th></th> */}
						<th>Name ({props.files.length} files)</th>
						{/* <th>Size ({utils.string.formatBytes(totalSize)})</th> */}
						<th>Progress ({allProgressPercent} %)</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{props.files.map((item, index) => {
						const { key, file, fileType, isValid } = item;
						const ext = fileType.ext ?? "---";

						if (!checkedDictionary.has(key)) {
							checkedDictionary.set(key, false);
						}
						const checked = checkedDictionary.get(key);
						const progress = props.progressDictionary.get(key) ?? 0;
						const progressPercentage = (progress * 100) / file.size;

						return (
							<tr key={key} title={isValid ? "" : invalidTooltip}>
								{/* <td style={{ width: 20 }}>{index + 1}</td> */}
								{/* <td style={{ width: 50 }}>
									<Checkbox
										key={key + checked}
										checked={checked}
										disabled={props.inProgress}
										onChanged={(isChecked, e) =>
											onFileCheckboxChanged(isChecked, key, e)
										}
									/>
								</td> */}
								<td>
									{isValid ? (
										<span className="text-success pe-h">
											<i className="fa fa-check" />
										</span>
									) : (
										<span className="text-danger pe-h">
											<i className="fa fa-times" />
										</span>
									)}
									{file.name}
								</td>
								{/* <td>{utils.string.formatBytes(file.size)}</td> */}
								<td>
									<div
										className="progress border border-ccc"
										style={{
											height: 20,
										}}
									>
										<div
											className="progress-bar"
											role="progressbar"
											style={{ width: progressPercentage + "%" }}
											aria-valuenow={progressPercentage}
											aria-valuemin={0}
											aria-valuemax={100}
										>
											{progressPercentage.toFixed(0)} %
										</div>
									</div>
								</td>
								<td className="text-end">
									<RemoveButton
										disabled={props.inProgress}
										onConfirmed={() => {
											props.onFileRemoved(key, item);
										}}
									></RemoveButton>
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</>
	);
};
