import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";

export const AliasAndIcon = (props: { artist: ArtistDto | null }) => {
	return (
		<>
			<span>{props.artist?.alias}</span>
			{props.artist?.isRecordLabel && (
				<i
					className="fa fa-record-vinyl ms-q text-muted"
					title="Record Label"
				/>
			)}
		</>
	);
};
