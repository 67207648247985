import Axios from "axios";
import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import appConfig from "src/shared/utils/appConfig";
import { IRestResponse } from "typed-rest-client";
import { BaseService } from "../baseService";

export class releaseService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async getByArtistAliasAsync(
		alias: string
	): Promise<IRestResponse<ReleaseDto[]>> {
		const path = `api/v2/music/release/artist/${encodeURIComponent(
			alias.trim()
		)}`;
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.get<ReleaseDto[]>(path, config);
		return super.getRestResponse(response);
	}

	public async getByCatalogNoAsync(
		catalogNo: string
	): Promise<IRestResponse<ReleaseDto>> {
		const path = `api/v2/music/release/catalog/${catalogNo}`;
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.get<ReleaseDto>(path, config);
		return super.getRestResponse(response);
	}

	public async getListByGuidsAsync(
		guids: string[]
	): Promise<IRestResponse<ReleaseDto[]>> {
		const path = `api/v2/music/release/guids`;
		guids = guids || [];
		guids = guids.filter((x) => x.trim() !== "");
		if (!guids.length) {
			return {
				statusCode: 200,
				result: [],
				headers: {},
			};
		}
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.post<ReleaseDto[]>(path, guids, config);
		return super.getRestResponse(response);
	}
}
