import React from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { ICheckoutProps } from "src/components/beatsShop/checkoutForm";
import { FormikCheckbox } from "src/components/common/formik/formikCheckbox";
import {
	FormikTextbox,
	InputTypes,
} from "src/components/common/formik/formikTextbox";
import appConfig from "src/shared/utils/appConfig";
import { ICheckoutForm } from "src/store/beatsShop/orders/checkout.state";
import { nameof } from "ts-simple-nameof";
import { Payment } from "./payment";

const agreeTerms = () => {
	return (
		<>
			I agree to and have read the{" "}
			<a
				href="https://beatsunion.com/TermsOfUse"
				target="_blank"
				rel="noreferrer"
			>
				Terms of Service
			</a>{" "}
			and{" "}
			<a
				href="https://beatsunion.com/PrivacyPolicy"
				target="_blank"
				rel="noreferrer"
			>
				Privacy Policy
			</a>
			.
		</>
	);
};

interface IProps extends ICheckoutProps {
	showPayment: boolean;

	onStripeChanged?: (e: stripe.elements.ElementChangeResponse) => void;
}

export const LoggedOutCheckout = (props: IProps) => {
	return (
		<>
			<Row className="mt-2">
				<Col lg="6" xs="12">
					<Card>
						<CardHeader className="bg-gray">User Checkout</CardHeader>
						<CardBody className="my-2">
							<Row>
								<Col sm="6" xs="12">
									<Button
										type="button"
										color="primary"
										size="lg"
										className="w-100"
										onClick={props.authStore.authFunctions.Signin}
									>
										Sign In to Continue
									</Button>
								</Col>
								<Col sm="6" xs="12">
									<a
										href={`${
											appConfig.domains.identity
										}/register?returnUrl=${encodeURI(
											window.location.href
										)}`}
										className="btn btn-lg ripple-surface text-no-wrap w-100 mt-1 mt-sm-0 w-100"
									>
										Create New Account
									</a>
								</Col>
							</Row>
							<div className="mt-2 text-muted">
								By continuing as a User, you can see all your past
								orders to easily download your purchased tracks right on
								BeatsUnion.
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col lg="6" xs="12" className="mt-lg-0 mt-1">
					<Card>
						<CardHeader className="bg-gray">Guest Checkout</CardHeader>
						<CardBody>
							<Row>
								<Col lg="6" sm="12">
									<FormikTextbox
										label="First Name"
										name={nameof<ICheckoutForm>((f) => f.firstName)}
										type={InputTypes.text}
									/>
								</Col>
								<Col lg="6" sm="12" className="mt-lg-0 mt-1">
									<FormikTextbox
										label="Last Name"
										name={nameof<ICheckoutForm>((f) => f.lastName)}
										type={InputTypes.text}
									/>
								</Col>
							</Row>
							<Row className="mt-1">
								<Col>
									<FormikTextbox
										label="Email"
										name={nameof<ICheckoutForm>((f) => f.email)}
										type={InputTypes.email}
									/>
								</Col>
							</Row>
							{props.showPayment && (
								<>
									<hr />
									<Row className="mt-1">
										<Col>
											<Payment onChanged={props.onStripeChanged} />
										</Col>
									</Row>
								</>
							)}
							<hr />
							<Row className="mt-1">
								<Col className="ms-h">
									<FormikCheckbox
										label={agreeTerms()}
										name={nameof<ICheckoutForm>((s) => s.terms)}
									/>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};
