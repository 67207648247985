import { useState } from "react";
import { Col, Row } from "reactstrap";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { DownloadService } from "src/api/services/music/downloadService";
import { ActionModes } from "src/components/music/chart";
import { ChartListViewCompact_Download } from "src/components/music/chart/chartListViewCompact";
import { downloadFile } from "src/shared/utils/files";

interface IProps {
	orderGuid: string;
	tracks: TrackDto[];
}

export const OrderDownload = (props: IProps) => {
	const [allMp3Loading, setAllMp3Loading] = useState(false);
	const [allWavLoading, setAllWavLoading] = useState(false);

	const downloadTrack = async (track: TrackDto, fileType: string) => {
		const response = await new DownloadService().downloadTrackAsync(
			fileType,
			track.guid,
			props.orderGuid,
			false
		);

		if (!response) {
			return;
		}

		const artistNames = track.collaborators
			.map((artist) => artist.alias)
			.join(", ");
		const downloadName = `${artistNames} - ${track.title}.${fileType}`;
		downloadFile(response.downloadUrl, downloadName);
	};

	const downloadAll = async (fileType: string) => {
		if (fileType == "wav") {
			setAllWavLoading(true);
		} else {
			setAllMp3Loading(true);
		}

		const response = await new DownloadService().downloadAllAsync(
			fileType,
			props.orderGuid
		);

		if (fileType == "wav") {
			setAllWavLoading(false);
		} else {
			setAllMp3Loading(false);
		}

		if (!response) {
			return;
		}

		downloadFile(response.downloadUrl);
	};

	return (
		<>
			{/* <Row className="mb-1">
				<Col>
					<AjaxButton
						loading={allMp3Loading}
						type="button"
						onClick={() => downloadAll("mp3")}
						className="btn btn-primary"
						wrapperClass="me-h"
					>
						All in MP3
					</AjaxButton>
					<AjaxButton
						loading={allWavLoading}
						type="button"
						className="btn btn-info"
						onClick={() => downloadAll("wav")}
					>
						All in WAV
					</AjaxButton>
				</Col>
			</Row> */}
			<Row>
				<Col>
					<ChartListViewCompact_Download
						tracks={props.tracks}
						actionMode={ActionModes.Download}
						downloadAsync={downloadTrack}
					/>
				</Col>
			</Row>
		</>
	);
};
