export default function appConfigFactory() {
	return {
		environments: {
			isProduction: true,
			isStaging: false,
			isSandbox: false,
			isDevelopment: false,
			isDockerDevelopment: false,
			isK8sDevelopment: false,
			isK8sStaging: false,
			isK8sSandbox: false,
			isK8sProduction: false,
			name: "Production", // Default to Production,
			namespace: "default",
		},

		domains: {
			legacyWeb: "",
			api: "",
			identity: "",
			wordpress: "",
			react: "",
			storage: "",
			fileServerUrl: "",
			webReact: "",
		},

		stripe: {
			publishableKey: "pk_test_AJJATTwNey7lXGE9hsHQ1f8000q0HrcEu6",
		},

		mdBootstrap: {
			jsSrc: "https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/2.2.1/mdb.min.js",
		},

		auth: {
			authority: "",
			client_id: "",
			response_type: "",
			scope: "",
			automaticSilentRenew: true,
			redirect_uri: "",
			silent_redirect_uri: "",
			extraQueryParams: {
				signoutUrl: "",
			},
		},

		azure: {
			applicationInsights: {
				instrumentationKey: "",
			},
		},

		featureFlags: {
			disableStoreIfSubscriptionIsInactive: false,
		},
	};
}

export type IAppConfig = ReturnType<typeof appConfigFactory>;
