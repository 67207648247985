import { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import {
	appendSizeQuery,
	onImgError,
	renameBySize,
} from "src/shared/utils/img";

interface IProps
	extends DetailedHTMLProps<
		ImgHTMLAttributes<HTMLImageElement>,
		HTMLImageElement
	> {
	altSrc?: string;
	square?: number;
	width?: number;
	height?: number;
}

export const Img = (props: IProps) => {
	const { altSrc, src, square, width, height, ...rest } = props;

	const newSrc = renameBySize(src, square, width, height);
	const newAltSrc = appendSizeQuery(altSrc, square, width, height);

	return (
		<img src={newSrc} onError={(e) => onImgError(e, newAltSrc)} {...rest} />
	);
};
