import { AxiosRequestConfig, AxiosResponse } from "axios";
import { AuthService } from "src/api/services/auth/authService";
import { IRestResponse } from "typed-rest-client";
import appConfig from "../../shared/utils/appConfig";
import { KeyValuePair } from "./keyValuePair";

export class BaseService {
	private baseUrl: string;

	constructor(baseUrl: string) {
		this.baseUrl = baseUrl;
	}

	protected async getAxiosConfigAsync(
		addAuthHeader?: boolean
	): Promise<AxiosRequestConfig> {
		addAuthHeader = addAuthHeader ?? false;

		var baseUrlWithNamespace = this.baseUrl;
		if (
			appConfig.environments.namespace !== "default" &&
			baseUrlWithNamespace.indexOf(".dev.") > 0
		) {
			baseUrlWithNamespace = this.baseUrl
				.replace("https://", `https://${appConfig.environments.namespace}-`)
				.replace("http://", `https://${appConfig.environments.namespace}-`);
		}

		const result: AxiosRequestConfig = {
			baseURL: baseUrlWithNamespace,
		};

		if (addAuthHeader) {
			const token = await AuthService.getAccessTokenAsync();
			if (token && token !== "") {
				result.headers = {
					Authorization: `Bearer ${token}`,
				};
			}
		}

		return result;
	}

	protected getRestResponse<TPayload>(
		response: AxiosResponse<TPayload>
	): Promise<IRestResponse<TPayload>> {
		return new Promise<IRestResponse<TPayload>>((resolve, reject) => {
			resolve({
				result: response.data,
				statusCode: response.status,
				headers: {},
			});
		});
	}

	private getQueryString(data: any, prefix?: string): KeyValuePair[] {
		let queryString: KeyValuePair[] = [];
		if (Array.isArray(data)) {
			let key = prefix || "array";
			for (let i = 0; i < data.length; i++) {
				queryString.push({
					Key: encodeURIComponent(key) + "[" + i + "]",
					Value: encodeURIComponent(data[i]),
				});
			}
		} else {
			for (let property in data) {
				if (data.hasOwnProperty(property)) {
					let value = (data as any)[property];
					let key = prefix ? prefix + "." + property : property;

					if (value !== null && typeof value === "object") {
						let parts = this.getQueryString(value, key);
						queryString = queryString.concat(parts);
					} else {
						queryString.push({
							Key: encodeURIComponent(key),
							Value: encodeURIComponent(value),
						});
					}
				}
			}
		}
		return queryString;
	}

	protected appendQueryString(url: string, data?: object | null): string {
		if (!data) {
			return url;
		}

		const queryString = this.getQueryString(data);
		const requestUrl = new URL(this.baseUrl + "/" + url);
		for (let i = 0; i < queryString.length; i++) {
			requestUrl.searchParams.set(queryString[i].Key, queryString[i].Value);
		}

		return requestUrl.href.replace(this.baseUrl + "/", "");
	}
}
