import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { IChartState } from "./chart/chart.state";
import { InitialReleaseState, IReleaseState } from "./release/release.state";
import {
	initialSearchCriteriaState,
	ISearchCriteriaState,
} from "./searchCriteria/searchCriteria.state";

export interface IMusicState {
	chartList: IChartState[];
	trackList: TrackDto[];
	releaseList: IReleaseState;
	searchCriteria: ISearchCriteriaState;
}

export const initialMusicState: IMusicState = {
	trackList: [],
	chartList: [],
	releaseList: InitialReleaseState,
	searchCriteria: initialSearchCriteriaState,
};
