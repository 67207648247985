export enum CartItemTypes {
	Track = 0,
	Release = 1,
}
export interface ICartItem {
	guid: string;
	type: CartItemTypes;
	addDate: Date;
	isAddedByRelease?: boolean;
}

export interface ICartState {
	itemList: Array<ICartItem>;
}

export const initialCartState: ICartState = {
	itemList: [],
};
