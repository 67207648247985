import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { nameof } from "ts-simple-nameof";
import { FormikTextbox, InputTypes } from "../common/formik/formikTextbox";

interface IProps {
	searchTerm?: string;
}

export interface ISearchBoxForm {
	searchTerm: string;
}

export const SearchBox = (props: IProps) => {
	const InitialSearchBoxForm: ISearchBoxForm = {
		searchTerm: props.searchTerm ?? "",
	};
	const history = useHistory();

	const handleSubmit = (values: ISearchBoxForm) => {
		if ((values.searchTerm?.trim()?.length || 0) > 2) {
			history.push(`/search/${values.searchTerm}`);
		}
	};

	return (
		<>
			<Formik<ISearchBoxForm>
				initialValues={InitialSearchBoxForm}
				onSubmit={(values) => handleSubmit(values)}
			>
				{(formikProps) => {
					return (
						<FormikTextbox
							label=""
							placeholder="Search..."
							cssClass="expandable search-box"
							submitForm={formikProps.submitForm}
							name={nameof<ISearchBoxForm>((f) => f.searchTerm)}
							type={InputTypes.text}
						/>
					);
				}}
			</Formik>
		</>
	);
};
