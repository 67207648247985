import factory, { IAppConfig } from "./_app.config";
const appConfig = factory();

export default {
	...appConfig,

	environments: {
		...appConfig.environments,
		isProduction: false,
		isDevelopment: true,
		name: "development.k8s",
	},

	domains: {
		legacyWeb: "https://localhost:44304",
		api: "https://bu-api.local.bu-dev.net",
		identity: "https://bu-id-server.local.bu-dev.net",
		react: "https://bu-web-react.local.bu-dev.net",
		wordpress: "https://beatsunion.com",
		storage: "https://bu-storage.local.bu-dev.net",
	},

	auth: {
		authority: "https://bu-id-server.local.bu-dev.net",
		client_id: "BeatsUnion.Web.React",
		response_type: "id_token token",
		scope: "openid profile offline_access api1.read",
		automaticSilentRenew: true,
		redirect_uri: "https://bu-web-react.local.bu-dev.net/signin-oidc",
		silent_redirect_uri: "https://bu-web-react.local.bu-dev.net/silent-signin-oidc",
		extraQueryParams: {
			signoutUrl: "https://bu-web-react.local.bu-dev.net/signout-oidc",
		},
	},

	azure: {
		applicationInsights: {
			instrumentationKey: "c281a936-ad79-4761-8a0e-668e8c791441",
		},
	},

	featureFlags: {
		disableStoreIfSubscriptionIsInactive: false,
	},
} as IAppConfig;
