import Axios from "axios";
import appConfig from "src/shared/utils/appConfig";
import { IRestResponse } from "typed-rest-client";
import { BaseService } from "../baseService";
import { SearchResponse } from "./../../models/v2/responses/search/searchResponse";

export class SearchService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async searchAsync(
		searchTerm: string,
		trackCount: number = 10,
		releaseCount: number = 10,
		artistCount: number = 10
	): Promise<IRestResponse<SearchResponse> | null> {
		if ((searchTerm ?? "").trim() === "") {
			null;
		}

		const path = `api/v2/search/search/${searchTerm}?tracks=${trackCount}&releases${releaseCount}&users=${artistCount}`;
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.get<SearchResponse>(path, config);
		return super.getRestResponse(response);
	}
}
