import Axios from "axios";
import { InvoiceDto } from "src/api/models/v1/dto/payment/invoiceDto";
import { SubscriptionDto } from "src/api/models/v1/dto/payment/subscriptionDto";
import { SubscriptionItemDto } from "src/api/models/v1/dto/payment/subscriptionItemDto";
import { SubscriptionTypes } from "src/api/models/v1/enums/payments/subscriptions/subscriptionTypes";
import { InvoicesResponse } from "src/api/models/v1/responses/payments/subscription/invoicesResponse";
import { SubscriptionItemsResponse } from "src/api/models/v1/responses/payments/subscription/subscriptionItemsResponse";
import { SubscriptionsResponse } from "src/api/models/v1/responses/payments/subscription/subscriptionsResponse";
import appConfig from "src/shared/utils/appConfig";
import { BaseService } from "../baseService";

export class SubscriptionService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async getListAsync(): Promise<SubscriptionsResponse | null> {
		const config = await super.getAxiosConfigAsync(true);
		const url = "/api/v1/payments/subscriptions";

		try {
			const response = await Axios.get<SubscriptionsResponse>(url, config);
			return response.data;
		} catch (error) {}

		return null;
	}

	// public async getByIdAsync(
	// 	subscriptionId: string
	// ): Promise<SubscriptionDto | null> {
	// 	try {
	// 		const list = await this.getListAsync();
	// 		return (
	// 			list?.subscriptions?.find((x) => x.id === subscriptionId) || null
	// 		);
	// 	} catch (error) {}

	// 	return null;
	// }

	// public async createAsync(): Promise<SubscriptionDto | null> {
	// 	const config = await super.getAxiosConfigAsync(true);
	// 	const url = "/api/v1/payments/subscriptions";

	// 	try {
	// 		const response = await Axios.post<SubscriptionDto>(url, {}, config);
	// 		return response.data;
	// 	} catch (error) {}

	// 	return null;
	// }

	public async createTieredAsync(
		subscriptionType: SubscriptionTypes
	): Promise<SubscriptionDto | null> {
		const config = await super.getAxiosConfigAsync(true);
		const url = "/api/v1/payments/subscriptions/tiered";

		try {
			var data = { subscriptionType };
			const response = await Axios.post<SubscriptionDto>(url, data, config);
			return response.data;
		} catch (error) {}

		return null;
	}

	// public async getOrCreateAsync(): Promise<SubscriptionDto | null> {
	// 	try {
	// 		const subscriptions = (await this.getListAsync())?.subscriptions;
	// 		if (subscriptions?.length) {
	// 			return subscriptions[0];
	// 		}

	// 		return await this.createAsync();
	// 	} catch (error) {}

	// 	return null;
	// }

	public async getInvoicesAsync(): Promise<InvoiceDto[]> {
		const config = await super.getAxiosConfigAsync(true);
		const url = "/api/v1/payments/subscriptions/invoices";

		try {
			const response = await Axios.get<InvoicesResponse>(url, config);
			return response.data.invoices;
		} catch (error) {}

		return [];
	}

	public async getItemsAsync(): Promise<SubscriptionItemDto[]> {
		const config = await super.getAxiosConfigAsync(true);
		const url = "/api/v1/payments/subscriptions/items";

		try {
			const response = await Axios.get<SubscriptionItemsResponse>(
				url,
				config
			);
			return response.data.items;
		} catch (error) {}

		return [];
	}

	// public async getPricesAsync(): Promise<PriceDto[]> {
	// 	const config = await super.getAxiosConfigAsync(true);
	// 	const url = "/api/v1/payments/subscriptions/prices";

	// 	try {
	// 		const response = await Axios.get<PriceResponse>(url, config);
	// 		return response.data.prices;
	// 	} catch (error) {}

	// 	return [];
	// }
}
