import "@fortawesome/fontawesome-free/js/brands";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/solid";
import { createBrowserHistory } from "history";
import "./App.scss";
import { render } from "./indexJS.jsx";
import configureStore from "./store/configureStore";
import { registerStore } from "./store/storeRegistry";

const history = createBrowserHistory();
export const store = configureStore(history);
registerStore(store);

render(store, history);
