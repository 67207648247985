declare global {
	interface Window {
		env: EnvType;
	}
}

// change with your own variables
type EnvType = {
	REACT_APP_ENV_NAME: string;
	REACT_APP_NAMESPACE: string;
};
export const env: EnvType = { ...process.env, ...window.env };

// export const env = { ...process.env, ...window["env"] };
