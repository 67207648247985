import { SearchCriteriaDto } from "src/api/models/v1/dto/music/searchCriteriaDto";
import { searchCriteriaService } from "src/api/services/music/searchCriteriaService";
import { ApplicationState, AppThunkAction } from "src/store";

export enum SearchCriteriaActionTypes {
	SearchCriteriaInitialized = "[SearchCriteria] Filter data initialized",
	SearchCriteriaRequested = "[SearchCriteria] Filter data requested",
	SearchCriteriaSuccess = "[SearchCriteria] Filter data success",
	SearchCriteriaError = "[SearchCriteria] Filter data error",
}

interface ISearchCriteriaInitialized {
	type: SearchCriteriaActionTypes.SearchCriteriaInitialized;
}

interface ISearchCriteriaRequested {
	type: SearchCriteriaActionTypes.SearchCriteriaRequested;
}

interface ISearchCriteriaSuccess {
	type: SearchCriteriaActionTypes.SearchCriteriaSuccess;
	payload: SearchCriteriaDto;
	applicationState: ApplicationState;
}

interface ISearchCriteriaError {
	type: SearchCriteriaActionTypes.SearchCriteriaError;
}

export type FilterKnownTypes =
	| ISearchCriteriaInitialized
	| ISearchCriteriaRequested
	| ISearchCriteriaSuccess
	| ISearchCriteriaError;

export const SearchCriteriaActionCreator = {
	Initialize: (): AppThunkAction<FilterKnownTypes> => (dispatch, getState) => {
		dispatch({
			type: SearchCriteriaActionTypes.SearchCriteriaInitialized,
		});
	},

	Load: (): AppThunkAction<FilterKnownTypes> => async (dispatch, getState) => {
		dispatch({
			type: SearchCriteriaActionTypes.SearchCriteriaRequested,
		});

		var res = await new searchCriteriaService().getAsync();
		if (res && res.statusCode === 200 && res.result) {
			dispatch({
				type: SearchCriteriaActionTypes.SearchCriteriaSuccess,
				payload: res.result,
				applicationState: getState(),
			});
		} else
			dispatch({
				type: SearchCriteriaActionTypes.SearchCriteriaError,
			});
	},
};
