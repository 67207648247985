import { SearchCriteriaDto } from "src/api/models/v1/dto/music/searchCriteriaDto";
import { IAjaxifyFlags, initialAjaxifyFlags } from "src/shared/ajaxify";

export interface ISearchCriteriaState {
	searchCriteria: SearchCriteriaDto;
	ajaxifyFlags: IAjaxifyFlags;
}

export const initialSearchCriteria: SearchCriteriaDto = {
	bpmLow: 0,
	bpmHigh: 500,
	durationLow: 0,
	durationHigh: 3600,
	keys: [],
	genres: [],
};

export const initialSearchCriteriaState: ISearchCriteriaState = {
	searchCriteria: initialSearchCriteria,
	ajaxifyFlags: initialAjaxifyFlags,
};
