import { push } from "connected-react-router";
import { CheckoutResponse } from "src/api/models/v1/responses/beatsShop/checkoutResponse";
import { checkoutService } from "src/api/services/beatsShop/orders/checkoutService";
import { AppThunkAction } from "src/store";
import { storeDispatch } from "src/store/storeRegistry";
import { CartActions } from "../cart/cart.actions";
import { ICheckoutForm } from "./checkout.state";
import { OrderHistoryActionCreator } from "./history.actions";

export interface IStripeFunctions {
	processCheckoutAsync(values: ICheckoutForm): Promise<any>;
}

export enum Actions {
	Initialized = "[Orders] Checkout Initialized",
	CheckoutRequested = "[Orders] Checkout Requested",
	CheckoutSuccess = "[Orders] Requested Checkout Succeeded",
	CheckoutError = "[Orders] Requested Checkout Failed",
	NavigatedFromConfirm = "[Orders] User navigated away from Confirm page",
}

interface IInitialized {
	type: Actions.Initialized;
}

interface ICheckoutRequested {
	type: Actions.CheckoutRequested;
	payload: ICheckoutForm;
}

interface ICheckoutSuccess {
	type: Actions.CheckoutSuccess;
	payload: CheckoutResponse;
}

interface ICheckoutError {
	type: Actions.CheckoutError;
	payload: CheckoutResponse | null;
}

interface INavigatedFromConfirm {
	type: Actions.NavigatedFromConfirm;
}

export type KnownActions =
	| IInitialized
	| ICheckoutError
	| ICheckoutRequested
	| ICheckoutSuccess
	| INavigatedFromConfirm;

export const CheckoutActionsCreator = {
	Initialized: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
		dispatch({
			type: Actions.Initialized,
		});
	},

	ProcessCheckoutAsync:
		(
			requestGuid: string,
			checkoutForm: ICheckoutForm,
			trackGuidList: Array<string>,
			releaseGuidList: Array<string>
		): AppThunkAction<KnownActions> =>
		async (dispatch, getState) => {
			if (!((trackGuidList?.length ?? 0) + (releaseGuidList?.length ?? 0))) {
				return;
			}

			dispatch({
				type: Actions.CheckoutRequested,
				payload: checkoutForm,
			});

			try {
				var res = await new checkoutService().processAsync(
					requestGuid,
					checkoutForm,
					trackGuidList,
					releaseGuidList
				);
				dispatch({
					type: Actions.CheckoutSuccess,
					payload: res,
				});

				storeDispatch(
					CartActions.PaymentSucceededForItems(
						trackGuidList,
						releaseGuidList
					)
				);
				storeDispatch(OrderHistoryActionCreator.getOrders(1));

				storeDispatch(push("/Confirm") as any);
			} catch (error) {
				dispatch({
					type: Actions.CheckoutError,
					payload: null,
				});
			}
		},

	NavigatedFromConfirmPage:
		(): AppThunkAction<KnownActions> => (dispatch, getState) => {
			dispatch({
				type: Actions.NavigatedFromConfirm,
			});
		},
};
