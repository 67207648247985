import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { CenterScreen } from "./centerScreen";

interface IProps {}

export const NotFound = (props: IProps) => {
	return (
		<>
			<Container className="text-center mt-3">
				<CenterScreen title="404 - Not Found">
					<div className="mt-1">
						The page you are looking for does not exist.
					</div>
					<div className="mt-3">
						<Link to="/" className="btn btn-primary btn-lg">
							Home
						</Link>
					</div>
				</CenterScreen>
			</Container>
		</>
	);
};
