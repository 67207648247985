import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";
import { Img } from "../common/img";
import { ArtistLink } from "./artistLink";

export interface IProps {
	alias: string;
	artist: ArtistDto | null;
	width?: string;
}

export const ArtistSummary: React.FC<IProps> = (props) => {
	const location = useLocation();
	const link = `/${
		props.artist?.isRecordLabel ? "RecordLabel" : "Artist"
	}/${encodeURIComponent(props.alias.trim())}/`;
	const renderLink = !location.pathname
		.trim()
		.toLowerCase()
		.endsWith(link.trim().toLowerCase());

	return (
		<Link to={renderLink ? link : "#"}>
			<Card
				className="mb-1 shadow-hover text-center1"
				style={{ width: props.width ?? "250px" }}
			>
				<CardBody className="d-flex1 align-items-center">
					<div className="mb-1">
						<Img
							alt={props.alias}
							className="rounded w-100"
							style={{ maxWidth: "400px" }}
							src={props.artist?.imageUrl}
							altSrc={props.artist?.altImageUrl}
							square={400}
						/>
					</div>
					<div className="">
						<div className="h2">
							<ArtistLink artist={props.artist} />
						</div>
						<div className="text-muted mb-1">
							{props.artist?.location}
						</div>
					</div>
				</CardBody>
			</Card>
		</Link>
	);
};
