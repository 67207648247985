import React from "react";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { IPlayerProps, IPlayerState } from ".";
import utils from "../../../shared/utils";
import Slider from "../../common/slider";
import { ArtistList } from "../common/artistList";
import { ReleaseName } from "../common/releaseName";
import { ButtonTypes, TrackButton } from "../common/trackButton";

interface IProps extends IPlayerProps {
	track: TrackDto | null;
	progress: number;
	duration: number;
	fullDuration?: number;
	playerState: IPlayerState;
	children: JSX.Element | JSX.Element[];

	handleOnDismiss: () => void;
	handlePlayPauseClick: () => void;
	handleVolumeChange: (volume: number) => void;

	handlePlayNext: () => void;
	handlePlayPrevious: () => void;

	handlePlaylistRemove: (trackGuid: string) => void;
	handlePlaylistReorder: (oldIndex: number, newIndex: number) => void;
}

export const FullPlayer: React.FunctionComponent<IProps> = (props: IProps) => {
	const { track } = props;
	const imageArt = track?.release.imageUrl
		? track?.release.altImageUrl + "?s=100"
		: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6zwAAAgcBApocMXEAAAAASUVORK5CYII=";

	const playlistCount =
		props.playerState?.state?.player?.playlist?.length || 0;

	return (
		<React.Fragment>
			<div className="row animated">
				<div className="col-sm-12 col-xl-12">
					<div className="card m-0 shadow-normal rounded-0">
						<div
							className="pos-absolute-top-right"
							style={{ zIndex: 5000 }}
						>
							{/* <button
								type="button"
								className="btn btn-sm btn-outline-secondary btn-sm border-0"
								data-id="buttonMinimize"
							>
								<i className="fa fa-compress-arrows-alt"></i>
							</button> */}
							<button
								type="button"
								className="btn btn-outline-secondary btn-sm border-0"
								onClick={props.handleOnDismiss}
							>
								<i className="fa fa-times"></i>
							</button>
						</div>

						<div className="card-body">
							<div className="row flex-row">
								<div className="col-sm-6 col-lg-3 col-xl-3">
									<div className="row">
										<div className="col d-flex align-items-center">
											<div className="float-left">
												<img
													className="rounded"
													src={imageArt}
													alt=""
												/>
											</div>
											<div className="float-left pl-1">
												<div>
													<div className="font-weight-bold">
														{track && track.title}
														{/* {props.fullDuration && (
															<span>
																&nbsp;(
																{utils.dateTime.formatPlayerTime(
																	props.fullDuration
																)}
																)
															</span>
														)} */}
													</div>
													<div className="text-muted">
														<ReleaseName track={track} />
													</div>
													<div className="text-muted font-sm">
														<ArtistList track={track} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xs-6 col-sm-6 col-lg-3 col-xl-2 mt-xs-1">
									<div className="row">
										{/* <div className="col">
											<Button
												color={
													playlistCount <= 1
														? "disabled"
														: "primary"
												}
												outline={true}
												size="sm"
												className="border-0"
												onClick={props.handlePlayPrevious}
												disabled={playlistCount <= 1}
											>
												<i className="fa fa-step-backward" />
											</Button>
										</div> */}
										{/* <div className="col text-center">
											<TrackButton
												type={ButtonTypes.PlayPause}
												track={track}
												size="sm"
												alwaysOutlined={true}
												onClick={props.handlePlayPauseClick}
											/>
										</div> */}

										{/* <div className="col text-end">
											<Button
												color={
													playlistCount <= 1
														? "disabled"
														: "primary"
												}
												outline={true}
												size="sm"
												className="border-0"
												onClick={props.handlePlayNext}
												disabled={playlistCount <= 1}
											>
												<i className="fa fa-step-forward" />
											</Button>
										</div> */}

										{/* <div className="col text-end">
											<Playlist
												trackList={
													props.playerState.state.trackList
												}
												playlistTrackGuidList={
													props.playerState.state.player.playlist
												}
												handlePlaylistRemove={
													props.handlePlaylistRemove
												}
												handlePlaylistReorder={
													props.handlePlaylistReorder
												}
											/>
										</div> */}
									</div>

									<div className="mt-xs-1">
										<div className="text-center">
											<TrackButton
												type={ButtonTypes.PlayPause}
												track={track}
												size="sm"
												alwaysOutlined={true}
												onClick={props.handlePlayPauseClick}
											/>
											<div className="d-inline-block ms-1">
												<span>
													{utils.dateTime.formatPlayerTime(
														props.progress
													)}
												</span>
												<span> - </span>
												<span>
													{utils.dateTime.formatPlayerTime(
														props.duration
													)}{" "}
												</span>
											</div>
										</div>
										<div className="text-muted font-size-sm row">
											<div className="col text-end">
												Full version:{" "}
												<strong>
													{utils.dateTime.formatPlayerTime(
														props.fullDuration ?? 0
													)}
												</strong>
											</div>
											<div className="col">
												Preview:{" "}
												<strong>
													{utils.dateTime.formatPlayerTime(
														props.duration
													)}
												</strong>
											</div>
										</div>
									</div>
									<div className="mt-1 d-flex align-items-center">
										<div className="flex-grow-0 text-primary text-muted me-h">
											<i className="fa fa-volume-down"></i>
										</div>
										<Slider
											start={[50]}
											connect={[true, false]}
											range={{ min: 0, max: 100 }}
											onSlide={(values) =>
												values &&
												values.length &&
												props.handleVolumeChange(values[0])
											}
										/>
										<div className="flex-grow-0 text-primary text-muted ms-h">
											<i className="fa fa-volume-up"></i>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-xl-7 d-none d-lg-block">
									{props.children}
								</div>
								{/* <div className="col-lg-6 col-xl-7 d-md-none">
									{props.children}
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
