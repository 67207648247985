
export enum Messages {

	Required = "This field is required",
	InvalidEmail = "Invalid email format",
	InvalidPhoneNo = "Invalid phone number",

	MaxLength50 = "Maximum 50 characters",

}

export default {
	Messages: Messages
}

