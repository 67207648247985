import Peaks from "peaks.js";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { isFunction } from "util";

export class PeaksJS {
	private static staticInstance: PeaksJS | null = null;
	public static factory(container: HTMLElement): PeaksJS {
		if (PeaksJS.staticInstance == null) {
			PeaksJS.staticInstance = new PeaksJS(container);
		}

		return PeaksJS.staticInstance;
	}

	public currentTrack: TrackDto | null = null;
	private container: HTMLElement;

	private peaksInstance: Peaks.PeaksInstance | null = null;
	private audioElement: HTMLAudioElement = document.createElement("audio");

	private isPlayResolved: boolean = false;

	private progressIntervalMS: number = 100;
	private progressIntervalHandler: number = 0;

	private constructor(container: HTMLElement) {
		this.container = container;
	}

	async loadAsync(
		track: TrackDto,
		mp3UrlSelector: (track: TrackDto) => string
	): Promise<any> {
		if (!this.container)
			throw new Error(
				"[Player] Can't initialize the PeaksJS object: 'container' is null"
			);

		if (!(this.currentTrack = track))
			throw new Error(
				"[Player] Can't initialize the PeaksJS object: 'currentTrack' is null"
			);

		if (!this.audioElement) {
			this.audioElement =
				this.container.parentNode?.querySelector("audio") ??
				document.createElement("audio");
		}
		if (!this.audioElement)
			throw new Error(
				"[Player] Can't initialize the PeaksJS object: 'audioElement' is null"
			);

		this.stop();

		this.audioElement.preload = "metadata";
		this.audioElement.setAttribute("type", "audio/mpeg");

		var mp3Url = mp3UrlSelector(track);
		if ((mp3Url ?? "") === "") return;
		this.audioElement.src = mp3Url + "?partial";

		const options = {
			containers: {
				overview: this.container,
				//zoomview: document.getElementById('zoomview-container')
			},
			mediaElement: this.audioElement,
			dataUri: this.currentTrack.peaksUrl,
			showPlayheadTime: true,
			overviewWaveformColor: "#AAAEA2",
			height: 100,
			//keyboard: true,
		} as Peaks.PeaksOptions;

		if (this.peaksInstance) {
			this.peaksInstance.destroy();
			this.peaksInstance = null;
		}

		return new Promise<void>((resolve, reject) => {
			if (!this.container.getClientRects().length) {
				this.container = document.createElement("div");
				this.container.style.width = "1px";
				this.container.style.height = "1px";
				document
					.getElementsByTagName("body")[0]
					.appendChild(this.container);
				(options as any).containers.overview = this.container;
			}

			this.peaksInstance = Peaks.init(options);

			this.peaksInstance.on("peaks.ready", () => {
				this.handleOnReady();
				resolve();
			});
			this.peaksInstance.on("player_seek", (time: number) => {
				this.handleOnProgressed(time);
			});
			this.peaksInstance.on("user_seek", (time: number) => {
				this.handleOnProgressed(time);
			});
			(this.peaksInstance as any).on("onSeek", (time: number) => {
				this.handleOnProgressed(time);
			});

			//this.peaksInstance.on("segments.mouseenter", (segment) => {
			//	this.handleSegmentMouseEnter(segment);
			//});

			//this.peaksInstance.on("segments.mouseleave", (segment) => {
			//});

			//this.peaksInstance.on("points.mouseenter", (point) => {
			//	this.handlePointMouseEnter(point);
			//});

			//this.peaksInstance.on("points.mouseleave", (point) => {
			//	//this.handlePointMouseEnter(point);
			//});

			//this.dom.divWaveContainer.dblclick((e) => this.handleWaveDblClick());
			//this.dom.divWaveContainer.mouseup((e) => this.handleWaveMouseUp());
			//this.dom.divWaveContainer.mousedown((e) => this.handleWaveMouseDown());
		});
	}

	private handleOnReady(): void {}

	private handleOnProgressed(time: number): any {
		if (time >= this.audioElement.duration) {
			this.onFinishedPlaying(this.currentTrack);
		}

		if (!this.peaksInstance) return;
		time = time || 0.001;

		if (this.progressIntervalMS === 0) this.progressIntervalMS = 100;

		time += this.progressIntervalMS / 1000;

		var id = "segment-progress";
		var segments = this.peaksInstance.segments.getSegments();
		if (!segments.filter((x) => x.id === id).length) {
			this.peaksInstance.segments.add({
				id,
				startTime: 0,
				endTime: time,
				color: "#4374FF",
			});
		} else {
			var progress = segments.filter((x) => x.id === id)[0];
			progress.update({ endTime: time });

			for (var i = 0; i < segments.length; i++) {
				let s = segments[i];
				if (s.id !== id)
					s.update({ startTime: s.startTime, endTime: s.endTime });
			}
		}

		if (isFunction(this.onProgress)) this.onProgress(time);
	}

	//--------- Player Functionality -------------

	public pause(): void {
		if (!this?.audioElement || !this.isPlayResolved) return;

		this.audioElement.pause();
		this.isPlayResolved = false;

		if (this.progressIntervalHandler > 0) {
			window.clearInterval(this.progressIntervalHandler);
			this.progressIntervalHandler = 0;
		}
	}

	public stop(): void {
		this.pause();
		this.audioElement.currentTime = 0.0;
	}

	public play(): any {
		var res = this.audioElement.play();
		if (res && isFunction(res.then)) {
			res.then(() => this.playPromise());
			return;
		}

		this.playPromise();
	}

	private playPromise() {
		this.isPlayResolved = true;
		if (this.progressIntervalHandler === 0)
			this.progressIntervalHandler = window.setInterval(
				() => this.handleOnProgressed(this.audioElement.currentTime),
				this.progressIntervalMS
			);
	}

	public setVolume(volume: number) {
		this.audioElement.volume = volume / 100;
	}

	//----------- Events -------------

	private onProgress(time: number) {}
	public setOnProgress(callback: (time: number) => void) {
		if (isFunction(callback)) this.onProgress = callback;
	}

	private onFinishedPlaying(trackGuid: TrackDto | null) {}
	public setOnFinishedPlaying(callback: (trackGuid: TrackDto | null) => void) {
		if (isFunction(callback)) this.onFinishedPlaying = callback;
	}
}

declare module "dateformat";
