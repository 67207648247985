import { newGuid } from "@microsoft/applicationinsights-core-js";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "reactstrap";
import {
	IUploadResponse,
	UploadService,
} from "src/api/services/files/uploadService";
import utils from "src/shared/utils";
import appConfig from "src/shared/utils/appConfig";
import { FileTypes, IFileType } from "src/shared/utils/files";
import { FileAndKey, FileListProgress } from "./fileListProgress";

interface IProps {
	userGuid: string;
	onCompleteUrl: string;
	acceptableFileTypes?: FileTypes[];
}

export const FileUpload = (props: IProps) => {
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: ".wav",
	});

	const [inProgress, setInProgress] = useState(false);
	// const [allValid, setAllValid] = useState(false);
	const [files, setFiles] = useState<FileAndKey[]>([]);
	const [progressDict, setProgressDict] = useState<Map<string, number>>(
		new Map<string, number>()
	);

	const getKey = (index: number, file: File) => {
		return `${index}_${file.name}`;
	};

	useEffect(() => {
		const fileAndKeys = [...files];
		async function worker() {
			const promises: Array<Promise<IFileType>> = [];

			acceptedFiles.forEach((item, index) => {
				const key = getKey(index, item);
				if (!fileAndKeys.find((x) => x.file.name == item.name)) {
					progressDict.set(key, 0);
					promises.push(utils.files.getFileTypeAsync(item));
					fileAndKeys.push({
						key: key,
						file: item,
						fileType: {},
						isValid: true,
					});
				}
			});

			const responses = await Promise.all(promises);
			for (let i = 0; i < responses.length; i++) {
				fileAndKeys[i].fileType = responses[i];
				fileAndKeys[i].isValid = validateFileType(
					fileAndKeys[i],
					responses[i]
				);
			}

			setFiles(fileAndKeys);
		}
		worker();
	}, [acceptedFiles]);

	const validateFileType = (
		fileAndKey: FileAndKey,
		fileType: IFileType
	): boolean => {
		if (!props.acceptableFileTypes?.length) {
			return true;
		}

		return (
			props.acceptableFileTypes.find((x) => {
				const y = FileTypes[x];
				return y === (fileType.ext || newGuid());
			}) !== undefined
		);
	};

	const onClearClicked = () => {
		setFiles([]);
		setInProgress(false);
		window.parent.postMessage(
			"bulkUploadCompleted",
			appConfig.domains.legacyWeb
		);
	};

	const onFileRemoved = (key: string, file: FileAndKey) => {
		const newFiles = utils.array.cloneRemove(files, file, (x) => x.key);
		setFiles(newFiles);
	};

	const onFileProgress = (fileAndKey: FileAndKey, progress: number) => {
		progressDict.set(fileAndKey.key, progress);
		setProgressDict(utils.map.cloneMap(progressDict));
	};

	const uploadAsync = async () => {
		setInProgress(true);
		const uploadService = new UploadService();

		let count = 3;
		let startIndex = 0;
		let lastIndex = 0;
		let allResponses: IUploadResponse[] = [];

		window.parent.postMessage(
			"bulkUploadStarted",
			appConfig.domains.legacyWeb
		);

		do {
			lastIndex = Math.min(startIndex + count, files.length);

			let batch: FileAndKey[] = [];
			for (let i = startIndex; i < lastIndex; i++) {
				batch.push(files[i]);
			}

			const responses = await Promise.all(
				batch.map((item) =>
					uploadService.uploadAsync(
						props.userGuid,
						item,
						(file, progress) => onFileProgress(item, progress)
					)
				)
			);
			allResponses.push(...responses);
			startIndex += count;
		} while (lastIndex < files.length);

		setInProgress(false);
		window.parent.postMessage(
			"bulkUploadCompleted",
			appConfig.domains.legacyWeb
		);
		setFiles([]);

		await uploadService.notifyUpload(props.onCompleteUrl, allResponses);
	};

	const allValid = files
		.map((x) => x.isValid)
		.reduce((previous, current) => previous && current, true);

	return (
		<>
			<div {...getRootProps({ className: "dropzone mb-1" })}>
				<input {...getInputProps()} />
				<p>Drag 'n' drop some files here, or click to select files</p>
			</div>
			{files.length > 0 && (
				<>
					<div className="mb-1">
						<Button
							size="lg"
							color="primary me-h"
							onClick={uploadAsync}
							disabled={inProgress || !allValid}
						>
							Upload All
						</Button>
						<Button
							color="warning me-h"
							size="lg"
							onClick={onClearClicked}
						>
							Clear List
						</Button>

						{/* <Button color="warning me-h">Remove Selected</Button> */}
					</div>
					<div>
						<FileListProgress
							inProgress={inProgress}
							files={files}
							progressDictionary={progressDict}
							onFileRemoved={onFileRemoved}
						/>
					</div>
				</>
			)}
		</>
	);
};
