import {
	useAppInsightsContext,
	useTrackEvent,
	useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import { flatten } from "src/shared/utils/array";
import { ICartFunctions } from "src/store/beatsShop/cart/cart.actions";
import { CartItemTypes } from "src/store/beatsShop/cart/cart.state";
import { calculateValues, IPriceCalc, taxPercentage } from ".";
import { CartSummaryView } from "../music/chart/cartSummaryView";
import { CartSummaryRelease } from "../music/release/cartSummaryRelease";
interface IProps extends IPriceCalc {
	cartFunctions: ICartFunctions;
	openCartOnLoad?: boolean;
}

const ModalBodyEmpty = (props: IProps) => {
	return (
		<React.Fragment>
			<div className="mt-3 text-center">
				<h2>Your cart is empty.</h2>
			</div>
		</React.Fragment>
	);
};

const CartGroupLabel = (props: { label: string }) => {
	return <div className="h3">{props.label}</div>;
};

const getReleaseList = (
	releaseGuidList: string[],
	releaseList: ReleaseDto[]
): ReleaseDto[] => {
	const result: ReleaseDto[] = [];
	for (var i = 0; i < releaseGuidList.length; i++) {
		const guid = releaseGuidList[i];
		const release = releaseList.find(
			(x) => x.guid.toLowerCase().trim() === guid.toLowerCase().trim()
		);
		if (release) result.push(release);
	}
	return result;
};

const ModalBodyList = (props: IProps) => {
	const values = calculateValues(props);

	const releaseGuidList = props.cartItemList
		.filter((x) => x.type === CartItemTypes.Release)
		.map((item, index) => item.guid);
	const releaseList = getReleaseList(releaseGuidList, props.releaseList);

	const userGuids = flatten(releaseList, (x) => x.trackUserGuids);

	return (
		<>
			{props.trackList?.length ? (
				<>
					<CartGroupLabel label="Tracks" />
					<div className="border-separator mb-2">
						<CartSummaryView {...props} />
					</div>
				</>
			) : null}

			{props.releaseList?.length ? (
				<>
					<CartGroupLabel label="Releases" />
					<div className="border-separator">
						<CartSummaryRelease
							{...props}
							userGuids={userGuids}
							releaseList={releaseList}
						/>
					</div>
				</>
			) : null}

			<div className="border-separator py-2  px-h">
				<div>
					<div className="float-end text-end ps-h">
						${values.subTotal.toFixed(2)}
					</div>
					<div className="">Subtotal</div>
				</div>
				<div>
					<div className="float-end text-end ps-h">
						${values.tax.toFixed(2)}
					</div>
					<div className="">Estimated Taxes ({taxPercentage * 100}%)</div>
				</div>
			</div>

			<div className="py-2 font-size-lg  px-h">
				<div>
					<strong>
						<div className="float-end text-end ps-h">
							${(values.tax + values.subTotal).toFixed(2)}
						</div>
						<div className="">Total</div>
					</strong>
				</div>
			</div>
		</>
	);
};

const CheckoutLink = (props: IProps) => {
	return props.cartItemList && props.cartItemList.length ? (
		<div className="pb-2">
			<Link to="/Checkout" className="btn btn-primary btn-lg w-100">
				Check Out
			</Link>
		</div>
	) : (
		<div></div>
	);
};

export const CartSummaryBody = (props: IProps) => {
	return props.cartItemList && props.cartItemList.length ? (
		<ModalBodyList {...props} />
	) : (
		<ModalBodyEmpty {...props} />
	);
};

export const CartSummary = (props: IProps) => {
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const body = document.getElementsByTagName("body")[0];
	const bodyClassName = "checkout-summary-modal-open";

	let countText = "";
	const items = props.cartItemList?.filter((x) => x.isAddedByRelease !== true);
	if (items && items.length) {
		if (items.length < 99) {
			countText = items.length.toString();
		} else countText = "99+";
	}

	const appInsights = useAppInsightsContext();
	useTrackMetric(appInsights, "Cart Summary")();
	useTrackEvent(appInsights, "Cart Summary Shown", items);

	const { openCartOnLoad } = props;
	useEffect(() => {
		if (openCartOnLoad === true && modal === false) {
			if (
				body.className.toLowerCase().indexOf(bodyClassName.toLowerCase()) <
				0
			) {
				body.classList.add(bodyClassName);
				setModal(true);
			}
		}
	}, [openCartOnLoad]);

	const onModalClosed = () => {
		body.classList.remove(bodyClassName);
	};

	return (
		<div className="cart-summary">
			<span className="nav-link clickable" onClick={toggle}>
				<i className="fa fa-shopping-cart text-primary"></i>&nbsp;
				<span className="badge rounded-pill badge-notification bg-danger">
					{countText}
				</span>
			</span>

			<Modal
				isOpen={modal}
				toggle={toggle}
				scrollable={true}
				className="right"
				onClosed={onModalClosed}
			>
				<ModalHeader toggle={toggle}>Music Cart</ModalHeader>
				<ModalBody>
					{/* <CheckoutLink {...props} /> */}
					<CartSummaryBody {...props} />
					<CheckoutLink {...props} />
				</ModalBody>
			</Modal>
		</div>
	);
};
