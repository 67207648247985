import { Component } from "react";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { Img } from "src/components/common/img";
import { CartItemTypes } from "src/store/beatsShop/cart/cart.state";
import { IChartProps } from ".";
import { ArtistList } from "../common/artistList";
import { ReleaseName } from "../common/releaseName";
import { ButtonTypes, TrackButton } from "../common/trackButton";

export class ChartGridView extends Component<IChartProps> {
	handleAddToCartClick(trackGuid: string) {
		return this.props.cartFunctions?.AddToCart(
			trackGuid,
			CartItemTypes.Track
		);
	}

	handlePlayPauseClick(track: TrackDto) {
		if (track.isPlaying)
			return this.props.chartFunctions.playerFunctions?.pause(track.guid);

		return this.props.chartFunctions.playerFunctions?.play(track.guid);
	}

	handleAddToPlaylist(track: TrackDto) {
		return this.props.chartFunctions.playerFunctions?.playlistFunctions.add(
			track.guid
		);
	}

	render() {
		return (
			<>
				<div className="chart-grid-view">
					{this.props.chart.trackList?.map((track, index) => {
						return (
							<div
								key={index}
								className={`chart-grid-item px-1 py-h mb-1 shadow-hover ${
									track.isPlaying ? "shadow-normal" : ""
								}`}
							>
								<div>
									<Img
										className="rounded shadow-normal clickable"
										src={track.release.imageUrl}
										altSrc={track.release.altImageUrl}
										square={180}
										alt={track.title}
										onClick={this.handlePlayPauseClick.bind(
											this,
											track
										)}
									/>
								</div>
								<div className="mb-h mt-1">
									<div className="track-title">{track.title}</div>
									<div className="release-title">
										<ReleaseName track={track} />
									</div>
									<div className="artist-name">
										<ArtistList track={track} />
									</div>
								</div>
								<div className="d-flex justify-content-center">
									<div className="flex-grow-0 me-q">
										<TrackButton
											size="sm"
											track={track}
											className="me-q"
											type={ButtonTypes.PlayPause}
											onClick={this.handlePlayPauseClick.bind(
												this,
												track
											)}
										/>
										{/* <TrackButton
											size="sm"
											className={
												track.isInPlaylist ? "disabled" : ""
											}
											track={track}
											type={ButtonTypes.AddToPlaylist}
											onClick={this.handleAddToPlaylist.bind(
												this,
												track
											)}
										/> */}
									</div>
									<div className="flex-grow-1 text-end">
										<TrackButton
											size="sm"
											track={track}
											type={ButtonTypes.AddToCart}
											onClick={this.handleAddToCartClick.bind(
												this,
												track.guid
											)}
										/>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</>
		);
	}
}
