import * as React from "react";
import { Col, Row } from "reactstrap";
import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";
import { Artist, ArtistLayouts } from "src/components/artist";
import Chart, {
	ActionModes,
	IChartFunctions,
} from "src/components/music/chart";
import { ReleaseGridItem } from "src/components/music/release/releaseGridItem";
import { ICartFunctions } from "src/store/beatsShop/cart/cart.actions";
import { IChartState } from "src/store/music/chart/chart.state";

interface IProps {
	artists?: ArtistDto[];
	release: ReleaseDto;
	chart: IChartState;

	chartFunctions: IChartFunctions;
	cartFunctions: ICartFunctions;
	getArtistsByGuids: (guids: string[]) => any;
}

export const ReleaseDetail: React.FC<IProps> = (props) => {
	const { artists, release, getArtistsByGuids } = props;

	const recordLabel = artists?.find((x) => x.isRecordLabel) ?? null;
	const artistList = artists?.filter((x) => !x.isRecordLabel) ?? [];

	React.useEffect(() => {
		function fetch() {
			getArtistsByGuids(release.trackUserGuids);
		}

		fetch();
	}, []);

	return (
		<>
			{artists && (
				<Row>
					<Col xs="12" lg="4" xl="3">
						<div>
							{props.release && (
								<>
									<div className="h1">Release</div>
									<ReleaseGridItem
										release={props.release}
										cartFunctions={props.cartFunctions}
										showDescription={true}
									/>
								</>
							)}
						</div>
						{recordLabel && (
							<div>
								<div className="h1 my-1">Record Label</div>
								<Artist
									key={recordLabel?.guid}
									alias={recordLabel?.alias ?? ""}
									artist={recordLabel}
									layout={ArtistLayouts.Summary}
								/>
							</div>
						)}
						{artistList?.length ? (
							<div>
								<div className="h1 my-1">
									Artist{artistList?.length > 1 ? "s" : ""}{" "}
								</div>
								{artistList.map((artist, i) => {
									return (
										<Artist
											key={i}
											alias={artist.alias}
											artist={artist}
											layout={ArtistLayouts.Summary}
										/>
									);
								})}
							</div>
						) : null}
					</Col>
					<Col xs="12" lg="8" xl="9">
						<Chart
							title={props.release.title}
							maxHeight="400px"
							showAddToCartButton={true}
							chart={props.chart}
							showLayoutToggle={true}
							actionMode={ActionModes.Store}
							chartFunctions={props.chartFunctions}
							cartFunctions={props.cartFunctions}
							release={release}
						/>
					</Col>
				</Row>
			)}
		</>
	);
};
