import React, { useEffect } from "react";
import { useHistory } from "react-router";
import storage from "src/shared/utils/storage";
import { IAuthFunctions } from "src/store/auth/auth.action";

interface IProps {
	authFunctions: IAuthFunctions;
}

export const SigninCallback = (props: IProps) => {
	const history = useHistory();

	useEffect(() => {
		async function workerAsync() {
			props.authFunctions.SigninCallback();

			const redirectUrl =
				(await storage.get<string>("signinRedirectUrl")) ?? "/";

			await storage.remove("signinRedirectUrl");
			history.push(redirectUrl);
		}

		workerAsync();
	}, [history]);

	return <></>;
};

export const SilentSigninCallback = (props: IProps) => {
	const history = useHistory();
	useEffect(() => {
		async function workerAsync() {
			props.authFunctions.SilentSigninCallback();
		}

		workerAsync();
	}, []);

	return <></>;
};

export const SignoutCallback = (props: IProps) => {
	const history = useHistory();
	useEffect(() => {
		props.authFunctions.SignoutCallback();
		history.push("/");
	}, [history]);

	return <></>;
};
