import { IAjaxifyFlags } from "src/shared/ajaxify";
import * as yup from "yup";

export const schema = yup.object({
	email: yup.string().email().required().ensure(),
});

export interface ISchema extends yup.InferType<typeof schema> {}

export const intialSchema: ISchema = {
	email: "",
};
export const initalStatus: IAjaxifyFlags = {
	requestInProgress: false,
	requestError: false,
	requestSuccess: false,
	errorMessage: "",
};
