import { IAjaxifyFlags, initialAjaxifyFlags } from "src/shared/ajaxify";
import { SearchResponse } from "./../../api/models/v2/responses/search/searchResponse";

export interface ISearchState {
	ajaxifyFlags: IAjaxifyFlags;
	response: SearchResponse | null;
}

export const InitialSearchState: ISearchState = {
	ajaxifyFlags: initialAjaxifyFlags,
	response: null,
};
