import Axios from "axios";
import { ChartFilterDto } from "src/api/models/v1/dto/music/chartFilterDto";
import { ChartTypes } from "src/api/models/v1/enums/music/charts/chartTypes";
import { ChartResponse } from "src/api/models/v2/responses/music/charts/chartResponse";
import appConfig from "src/shared/utils/appConfig";
import { IRestResponse } from "typed-rest-client";
import { BaseService } from "../baseService";

export class chartService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async getUserByGuidAsync(
		guid: string,
		pageNo: number = 1,
		pageSize?: number
	): Promise<IRestResponse<ChartResponse>> {
		const path = `api/v2/music/chart/user/${guid}`;
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.get<ChartResponse>(path, config);
		return super.getRestResponse(response);
	}

	public async getByTypeAsync(
		chartType: ChartTypes,
		pageNo: number = 1,
		pageSize?: number
	): Promise<IRestResponse<ChartResponse>> {
		const path = `api/v2/music/chart/type/${chartType}`;
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.get<ChartResponse>(path, config);
		return super.getRestResponse(response);
	}

	public async getDefaultUIAsync(
		filter: ChartFilterDto | null,
		pageNo: number = 1,
		pageSize?: number
	): Promise<IRestResponse<ChartResponse>> {
		let path = `/api/v2/music/chart/default`;
		const query: object = {
			filter,
			pageNo,
			pageSize: pageSize || 20,
		};

		const url = await super.appendQueryString(path, query);
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.get<ChartResponse>(url, config);
		return super.getRestResponse(response);
	}

	public async getReleaseByCatalogNo(
		catalogeNo: string
	): Promise<IRestResponse<ChartResponse>> {
		const path = `api/v2/music/chart/release/${catalogeNo}`;
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.get<ChartResponse>(path, config);
		return super.getRestResponse(response);
	}

	public async getReleaseByAlias(
		alias: string,
		pageNo: number
	): Promise<IRestResponse<ChartResponse>> {
		pageNo = pageNo ?? 1;
		const path = `api/v2/music/chart/artist/${alias}?pageNo=${pageNo}`;
		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.get<ChartResponse>(path, config);
		return super.getRestResponse(response);
	}
}
