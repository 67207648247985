import { Action, Reducer } from "redux";
import { FilterKnownTypes, SearchCriteriaActionTypes } from "./searchCriteria.action";
import { initialSearchCriteriaState, ISearchCriteriaState } from "./searchCriteria.state";


export const searchCriteriaReducer
	: Reducer<ISearchCriteriaState> = (currentState: ISearchCriteriaState = initialSearchCriteriaState, incomingAction: Action)
		: ISearchCriteriaState => {

		const action = incomingAction as FilterKnownTypes;

		switch (action.type) {

			case SearchCriteriaActionTypes.SearchCriteriaInitialized:
				return { ...currentState };

			case SearchCriteriaActionTypes.SearchCriteriaRequested:
				return {
					...currentState,
					ajaxifyFlags: {
						// ...currentState.ajaxifyFlags,
						errorMessage: "",
						requestError: false,
						requestSuccess: false,
						requestInProgress: true
					}
				};

			case SearchCriteriaActionTypes.SearchCriteriaError:
				return {
					...currentState,
					ajaxifyFlags: {
						...currentState.ajaxifyFlags,
						errorMessage: "",
						requestError: true,
						requestSuccess: false,
						requestInProgress: false
					}
				};

			case SearchCriteriaActionTypes.SearchCriteriaSuccess:
				let res = {
					...currentState,
					ajaxifyFlags: {
						...currentState.ajaxifyFlags,
						errorMessage: "",
						requestError: false,
						requestSuccess: true,
						requestInProgress: false
					},
					searchCriteria: {
						...action.payload
					}
				};
				return res;

		}

		return { ...currentState };

	}
