import { Fragment, useEffect, useState } from "react";
import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";
import { ArtistService } from "src/api/services/artists/artistService";
import { IPriceCalc } from "src/components/beatsShop";
import { Img } from "src/components/common/img";
import { RemoveButton } from "src/components/common/removeButton";
import { createNewGuid } from "src/shared/utils/string";
import { ICartFunctions } from "src/store/beatsShop/cart/cart.actions";
import { CartItemTypes } from "src/store/beatsShop/cart/cart.state";

interface IProps extends IPriceCalc {
	cartFunctions: ICartFunctions;
	userGuids: string[];
	releaseList: ReleaseDto[];
}

const getReleaseList = (
	releaseGuidList: string[],
	releaseList: ReleaseDto[]
): ReleaseDto[] => {
	const result: ReleaseDto[] = [];
	for (var i = 0; i < releaseGuidList.length; i++) {
		const guid = releaseGuidList[i];
		const release = releaseList.find(
			(x) => x.guid.toLowerCase().trim() === guid.toLowerCase().trim()
		);
		if (release) result.push(release);
	}
	return result;
};

export const CartSummaryRelease = (props: IProps) => {
	const [artists, setArtists] = useState<ArtistDto[]>([]);

	useEffect(() => {
		async function getUsersAsync() {
			const trackUsers = await new ArtistService().getByGuidsAsync(
				props.userGuids
			);
			setArtists(trackUsers?.result ?? []);
		}

		getUsersAsync();
	}, [props.userGuids]);

	return (
		<div className="cart-summary">
			{props.cartItemList.map((cartItem, index) => {
				const release = props.releaseList.find(
					(x) =>
						x.guid.toLowerCase().trim() ===
						cartItem.guid.toLowerCase().trim()
				);
				if (!release) {
					return <Fragment key={createNewGuid()}></Fragment>;
				}

				return (
					<div
						key={cartItem.guid}
						className="cart-summary-item d-flex px-h py-1 border-separator align-items-center shadow-hover"
					>
						<div className="flex-grow-0 ps-h">
							<Img
								className="rounded shadow-normal me-h"
								src={release.imageUrl}
								altSrc={release.altImageUrl}
								square={75}
								alt={release.title}
							/>
						</div>
						<div className="flex-grow-1 ps-h">
							<div>
								<strong>{release.title}</strong>
							</div>
							<div className="font-size-sm">
								{!artists?.length
									? null
									: artists.map((x) => x.alias).join(", ")}
							</div>
							<div className="font-size-sm">
								{`${release.trackCount} track${
									release.trackCount === 1 ? "" : "s"
								}`}
							</div>
						</div>
						<div className="flex-grow-0 ps-h text-end">
							<div className="pb-h ps-h">
								<strong>{"$" + release?.price.toFixed(2)}</strong>
							</div>
							<div>
								<RemoveButton
									onConfirmed={() =>
										props.cartFunctions.RemoveFromCart(
											release.guid,
											CartItemTypes.Release
										)
									}
								/>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};
