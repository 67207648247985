import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import utils from "src/shared/utils";
import { CartItemTypes, ICartItem } from "src/store/beatsShop/cart/cart.state";
import { Body } from "./body";

export const taxPercentage = 0.11;

export interface IPriceCalc {
	trackList: TrackDto[];
	releaseList: ReleaseDto[];
	cartItemList: ICartItem[];
}

export const calculateValues = (priceCalc: IPriceCalc) => {
	let result = {
		subTotal: 0,
		tax: 0,
		total: 0,
	};

	if (!priceCalc.cartItemList?.length) {
		return result;
	}

	const trackGuidList = priceCalc.cartItemList
		.filter(
			(x) => x.type === CartItemTypes.Track && x.isAddedByRelease !== true
		)
		.map((x) => x.guid);
	const trackList = priceCalc.trackList?.filter((x) =>
		trackGuidList.find((y) => y.toLowerCase() === x.guid.toLowerCase())
	);
	let subTotal = utils.array.sum<TrackDto>(trackList ?? [], (x) => x.price);

	const releaseGuids = priceCalc.cartItemList
		.filter((x) => x.type === CartItemTypes.Release)
		.map((x) => x.guid);
	const releases = priceCalc.releaseList?.filter((x) =>
		releaseGuids.find((y) => y.toLowerCase() === x.guid.toLowerCase())
	);
	subTotal += utils.array.sum<ReleaseDto>(releases ?? [], (x) => x.price);

	result = {
		subTotal: subTotal,
		tax: subTotal * taxPercentage,
		total: 0,
	};

	result.total = result.subTotal + result.tax;

	return result;
};

export default {
	Body,
};
