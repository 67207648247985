import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import { IAjaxifyFlags, initialAjaxifyFlags } from "src/shared/ajaxify";

export interface IReleaseState {
	releases: ReleaseDto[];
	ajaxifyFlags: IAjaxifyFlags;
}

export const InitialReleaseState: IReleaseState = {
	releases: [],
	ajaxifyFlags: initialAjaxifyFlags,
};
