import { useEffect, useState } from "react";
import { GuestOrderResponse } from "src/api/models/v2/responses/beatsShop/guestOrderResponse";
import { OrderService } from "src/api/services/beatsShop/orders/orderService";
import { IAjaxifyFlags } from "src/shared/ajaxify";
import appConfig from "src/shared/utils/appConfig";
import { GuestOrderError } from "./guestOrderError";
import { GuestOrderExpired } from "./guestOrderExpired";
import { GuestOrderLoading } from "./guestOrderLoading";
import { GuestOrderValid } from "./guestOrderValid";
interface IProps {
	guestOrderGuid: string;
}

export const GuestOrder = (props: IProps) => {
	const baseUrl = appConfig.domains.api;
	const initalStatus: IAjaxifyFlags = {
		requestInProgress: true,
		requestError: false,
		requestSuccess: false,
		errorMessage: "",
	};

	const [guestOrder, setGuestOrder] = useState<GuestOrderResponse | null>(
		null
	);
	const [status, setStatus] = useState<IAjaxifyFlags>(initalStatus);

	const getOrderAsync = async () => {
		try {
			const response = await new OrderService().getGuestOrder(
				props.guestOrderGuid
			);

			if (response) {
				setStatus({
					...status,
					requestInProgress: false,
					requestSuccess: true,
					requestError: false,
				});
				setGuestOrder(response);
			} else {
				setStatus({
					...status,
					requestInProgress: false,
					requestSuccess: false,
					requestError: true,
				});
			}
		} catch (error) {
			setGuestOrder(null);
			setStatus({
				...status,
				requestInProgress: false,
				requestSuccess: false,
				requestError: true,
			});
		}
	};

	useEffect(() => {
		getOrderAsync();
	}, []);

	if (status.requestInProgress) {
		return <GuestOrderLoading />;
	}

	if (guestOrder?.isExpired) {
		return <GuestOrderExpired guestOrderGuid={props.guestOrderGuid} />;
	}

	if (!guestOrder || status.requestError) {
		return <GuestOrderError />;
	}
	return <GuestOrderValid guestOrder={guestOrder} />;
};
