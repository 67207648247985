import { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { Link, RouteComponentProps } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { ChartTypes } from "src/api/models/v1/enums/music/charts/chartTypes";
import NavBar from "src/components/layout/navBar";
import Chart, { ActionModes } from "src/components/music/chart";
import array from "src/shared/utils/array";
import { initialChartState } from "src/store/music/chart/chart.state";
import { mapToPlayerFunctions } from "src/store/player/player.actions";
import { IReduxProps } from "src/store/storeHelper";

interface IProps extends IReduxProps {}
export class Confirm extends Component<IProps & RouteComponentProps> {
	UNSAFE_componentWillMount() {
		if ((this.props.State?.Checkout?.response?.order?.guid || "") === "")
			this.props.history.push("/");
	}

	UNSAFE_componentWillUnmount() {
		this.props.Actions.Checkout.NavigatedFromConfirmPage();
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.Actions.Music.ChartRequested(ChartTypes.Top);
	}

	render() {
		const childrenProps = {
			trackList: this.props.State.Music.trackList,
			cartItemList: this.props.State.BeatsShop.Cart.itemList,
			cartFunctions: this.props.Actions.BeatsShop.Cart,
		};

		const { Checkout } = this.props.State;

		const chartFunctions = {
			cartFunctions: this.props.Actions.BeatsShop.Cart,
			changeLayout: this.props.Actions.Music.LayoutChanged,
			playerFunctions: mapToPlayerFunctions(this.props.Actions.Player),
		};

		const topChart =
			this.props.State.Music.chartList.find(
				(x) => x.type === ChartTypes.Top
			) || initialChartState;

		const orderItemsTracks = Checkout.response.order?.trackGuids?.length
			? `${Checkout.response.order?.trackGuids.length} track${
					Checkout.response.order?.trackGuids.length > 1 ? "s" : ""
			  }`
			: "";
		const orderItemsReleases = Checkout.response.order?.releaseGuids?.length
			? `${Checkout.response.order?.releaseGuids.length} release${
					Checkout.response.order?.releaseGuids.length > 1 ? "s" : ""
			  }`
			: "";

		const items = array.cloneRemove(
			[orderItemsTracks, orderItemsReleases],
			"",
			(item) => item
		);

		return (
			<Fragment>
				<NavBar showCartButton={false} />

				<Container className="container-p-t">
					<h3 className="font-weight-bold mt-2">
						<i className="far fa-check-circle text-success"></i> Thank you
						for your order,{" "}
						{Checkout.response.order?.personalInfo?.firstName}!
					</h3>

					<Row className="mt-1">
						<Col xs="12" lg="4">
							An email with details on how to download your tracks has
							been submitted to the email address that you have provided.
						</Col>
					</Row>

					<table className="border-0 mt-1">
						<tbody>
							<tr>
								<td className="pt-1 text-muted">Order Total</td>
								<td className="pt-1 pl-2 font-weight-bold">
									$
									{(Checkout.response.order?.finalAmount || 0).toFixed(
										2
									)}
								</td>
							</tr>
							<tr>
								<td className="pt-1 text-muted">Order Number</td>
								<td className="pt-1 pl-2 font-weight-bold">
									{Checkout.response.order?.referenceNo}
								</td>
							</tr>
							<tr>
								<td className="pt-1 text-muted">Ordered Items</td>
								<td className="pt-1 pl-2 font-weight-bold">
									{items.join(", ")}
								</td>
							</tr>
						</tbody>
					</table>

					<Row className="mt-2">
						<Col xs="12" lg="4" className="hidden">
							<a
								target="_blank"
								rel="noopener noreferrer"
								className="btn btn-primary w-100"
								href={`/BeatsShop/Orders/${Checkout.response.order?.guid}`}
							>
								View Full Order Details
							</a>
						</Col>
					</Row>

					<Row className="mt-1">
						<Col xs="8" md="6" lg="4">
							{!Checkout.response.order.isGuestCheckout ? (
								<Link
									to="/MyOrders"
									className="btn btn-lg btn-primary w-100"
								>
									My Orders
								</Link>
							) : (
								<Link to="/" className="btn btn-lg btn-primary w-100">
									Home
								</Link>
							)}
						</Col>
					</Row>

					<hr className="my-3" />

					<div className="hidden">
						<Chart
							title="Tracks you may like ..."
							chart={topChart}
							showLayoutToggle={true}
							actionMode={ActionModes.Store}
							chartFunctions={chartFunctions}
							cartFunctions={this.props.Actions.BeatsShop.Cart}
						/>
					</div>
				</Container>
			</Fragment>
		);
	}
}

export default withRouter(Confirm);
