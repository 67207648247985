import { Action, Reducer } from "redux";
import { initialAjaxifyFlags } from "src/shared/ajaxify";
import array from "src/shared/utils/array";
import { ArtistActionTypes, ArtistKnownActions } from "./artist.actions";
import { IArtistState, InitialArtistState } from "./artist.state";

export const artistReducer: Reducer<IArtistState> = (currentState: IArtistState = InitialArtistState, incomingAction: Action): IArtistState => {

	const action = incomingAction as ArtistKnownActions;

	switch (action.type) {

		case ArtistActionTypes.ArtistInitialized:
			return { ...(currentState || InitialArtistState) };

		case ArtistActionTypes.ArtistRequestedAliases:
		case ArtistActionTypes.ArtistRequestedGuids:
			return {
				...currentState,
				ajaxifyFlags: {
					...initialAjaxifyFlags,
					requestInProgress: true,
				}
			};

		case ArtistActionTypes.ArtistError:
			return {
				...currentState,
				ajaxifyFlags: {
					...initialAjaxifyFlags,
					requestError: true,
				}
			};

		case ArtistActionTypes.ArtistSuccess:

			const artists = array.merge(currentState.artistList, action.payload, (x) => x.guid.toLowerCase().trim());

			return {
				...currentState,
				artistList: artists,
				ajaxifyFlags: {
					...initialAjaxifyFlags,
					requestSuccess: true
				}
			};

	}

	return currentState || InitialArtistState;
}
