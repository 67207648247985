import React from "react";
import { Col, Row } from "reactstrap";
import { IAuthStore } from "src/components/auth/authProvider";
import { ICheckoutProps } from "src/components/beatsShop/checkoutForm";
import {
	FormikTextbox,
	InputTypes,
} from "src/components/common/formik/formikTextbox";
import { BasicUserInfo } from "src/components/users/basicUserInfo";
import { ICheckoutForm } from "src/store/beatsShop/orders/checkout.state";
import { nameof } from "ts-simple-nameof";
import { Payment } from "./payment";

interface IProps extends ICheckoutProps {
	auth: IAuthStore;
	showPayment: boolean;

	onStripeChanged?: (e: stripe.elements.ElementChangeResponse) => void;
}

export const LoggedInCheckout = (props: IProps) => {
	return (
		<>
			<div className="mt-1">
				<BasicUserInfo auth={props.auth} />
			</div>
			{props.showPayment && (
				<>
					<hr className="mt-2" />
					<h3>Payment Information</h3>
					<Row className="mt-1 mb-2">
						<Col xs="12" lg="6">
							<Payment onChanged={props.onStripeChanged} />
						</Col>
					</Row>
				</>
			)}
			<div className="hidden">
				<FormikTextbox
					label="UserGuid"
					name={nameof<ICheckoutForm>((f) => f.userGuid)}
					type={InputTypes.text}
				/>
			</div>
		</>
	);
};
