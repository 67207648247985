import {
	StateStore,
	User,
	UserManager,
	UserManagerSettings,
} from "oidc-client";
import appConfig from "src/shared/utils/appConfig";
import storage from "src/shared/utils/storage";

class AuthServiceClass {
	private userManager: UserManager;
	private customStorage: StateStore;
	private userManagerSettings: UserManagerSettings;

	constructor() {
		this.customStorage = customStorage;

		this.userManagerSettings = {
			...appConfig.auth,
			userStore: this.customStorage,
		};

		this.userManager = new UserManager(this.userManagerSettings);

		this.userManager.events.addUserLoaded((user) => {
			this.userManager.storeUser(user);
		});

		this.userManager.events.addSilentRenewError((x) => {
			console.log(x);
		});
	}

	public async getAccessTokenAsync(
		isRecursive: boolean = false
	): Promise<string | null> {
		const user = await this.loadUserFromStorage();
		if (user == null) {
			return Promise.resolve(null);
		}
		return Promise.resolve(user.access_token);
	}

	public async loadUserFromStorage(): Promise<User | null> {
		try {
			return await this.userManager.getUser();
		} catch {}
		return null;
	}
	public async removeUserFromStorage(): Promise<void> {
		try {
			await this.userManager.removeUser();
		} catch {}
	}

	public async signinRedirect(redirectUrl?: string) {
		redirectUrl =
			redirectUrl || window.location.pathname + window.location.search;
		await storage.set("signinRedirectUrl", redirectUrl);

		return this.userManager.signinRedirect();
	}

	public signinRedirectCallback() {
		return this.userManager.signinRedirectCallback();
	}

	public async silentSigninRedirectCallback() {
		return await this.userManager.signinSilentCallback();
	}

	public signoutRedirect() {
		this.userManager.clearStaleState();
		this.userManager.removeUser();
		return this.userManager.signoutRedirect();
	}

	public signoutRedirectCallback() {
		this.userManager.clearStaleState();
		this.userManager.removeUser();
		return this.userManager.signoutRedirectCallback();
	}
}

const customStorage = {
	get: (key: string) => {
		const result = window.localStorage.getItem(key);
		return Promise.resolve(result);
	},
	set: (key: string, value: any) => {
		window.localStorage.setItem(key, value);
		return Promise.resolve();
	},

	getAllKeys: () => {
		return storage.getAllKeys();
	},
	remove: async (key: string) => {
		var value = await customStorage.get(key);
		await storage.remove(key);
		return Promise.resolve(value);
	},
};

export const AuthService = {
	get getAccessTokenAsync() {
		return () => new AuthServiceClass().getAccessTokenAsync();
	},
	get loadUserFromStorage() {
		return () => new AuthServiceClass().loadUserFromStorage();
	},
	get removeUserFromStorage() {
		return () => new AuthServiceClass().removeUserFromStorage();
	},
	get signinRedirect() {
		return () => new AuthServiceClass().signinRedirect();
	},
	get signinRedirectCallback() {
		return () => new AuthServiceClass().signinRedirectCallback();
	},
	get silentSigninRedirectCallback() {
		return () => new AuthServiceClass().silentSigninRedirectCallback();
	},
	get signoutRedirect() {
		return () => new AuthServiceClass().signoutRedirect();
	},
	get signoutRedirectCallback() {
		return () => new AuthServiceClass().signoutRedirectCallback();
	},
};
