import React from "react";
import NavBar from "src/components/layout/navBar";
import { Subscription } from "src/components/payment/subscription/subscription";
import { useQueryString } from "src/shared/utils/hooks";
import { IReduxProps } from "src/store/storeHelper";

interface IProps extends IReduxProps {}

interface IRouteParams {
	subscriptionId: string;
	redirecturl: string;
}

export const SubscriptionView = (props: IProps) => {
	const params: IRouteParams = useQueryString<IRouteParams>();

	return (
		<>
			<NavBar showCartButton={false} />

			<Subscription {...props} redirectUrl={params.redirecturl} />
		</>
	);
};
