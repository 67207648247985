import * as Cookies from "js-cookie";

interface IDataItem {
	data: any;
	expiryDateMS: number | null;
}

export function set<TData = any>(
	key: string,
	value: TData,
	expiryInMinutes: number = 0
): Promise<void> {
	let never = new Date(3000, 1, 1);
	let date = new Date(new Date().getTime() + expiryInMinutes * 60 * 1000);

	let valueJson = JSON.stringify({
		data: JSON.stringify(value),
		expiryDateMS: expiryInMinutes > 0 ? date.getTime() * 10000 : null,
	} as IDataItem);

	if (isLocalStorageSupported()) {
		localStorage.setItem(key, valueJson);
	} else
		Cookies.set(key, valueJson, {
			expires: expiryInMinutes > 0 ? date : never,
		});

	return Promise.resolve();
}

export function get<TData = any>(key: string): Promise<TData | null> {
	let valueJson = "";
	if (isLocalStorageSupported()) {
		valueJson = localStorage.getItem(key) ?? "";
	} else {
		valueJson = Cookies.get(key) ?? "";
	}

	let result: TData | null = null;
	if (valueJson === "") {
		result = null;
	} else {
		var dataItem = JSON.parse(valueJson) as IDataItem;
		if (
			dataItem &&
			(!dataItem.expiryDateMS ||
				dataItem.expiryDateMS > new Date().getTime() * 10000)
		) {
			result = JSON.parse(dataItem.data) as TData;
		}
	}
	return Promise.resolve(result);
}

export function remove(key: string): Promise<void> {
	if (isLocalStorageSupported()) {
		localStorage.removeItem(key);
	} else {
		Cookies.remove(key);
	}

	return Promise.resolve();
}

export function getAllKeys(): Promise<string[]> {
	let keys: string[] = [];
	if (isLocalStorageSupported()) {
		keys = Object.keys(localStorage);
	} else {
		keys = Object.keys(Cookies);
	}

	return Promise.resolve(keys);
}

function isLocalStorageSupported(): boolean {
	try {
		var itemBackup = localStorage.getItem("");
		localStorage.removeItem("");
		//localStorage.setItem("", itemBackup ?? "");
		if (itemBackup == null) localStorage.removeItem("");
		else localStorage.setItem("", itemBackup);
		return true;
	} catch (e) {
		return false;
	}
}

export default {
	get,
	set,
	remove,
	getAllKeys,
};
