import React, { useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { Link, useParams } from "react-router-dom";
import {
	Collapse,
	Container,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	NavItem,
	NavLink,
} from "reactstrap";
import appConfig from "src/shared/utils/appConfig";
import { connectStore, IReduxProps } from "src/store/storeHelper";
import { AuthProvider, IAuthStore } from "../auth/authProvider";
import { CartSummary } from "../beatsShop/cartSummary";
import { SearchBox } from "./searchBox";

type IPropsAndRedux = IProps & IReduxProps;

interface IProps {
	showCartButton: boolean;
	searchTerm?: string;
}

interface IRouteParams {
	trackGuid: string;
}

interface IAuthTemplateProps {
	authStore: IAuthStore;
	isOpen: boolean;
	toggle: () => void;
	close: () => void;
}

const LoggedInTemplate = (props: IAuthTemplateProps) => {
	const ref = useOnclickOutside(() => {
		props.close();
	});

	return (
		<>
			<NavItem className="text-primary clickable nav-dropdown">
				<NavLink className="text-primary clickable" onClick={props.toggle}>
					{props.authStore.authState.user?.firstName}
					&nbsp;
					<i className="fa fa-caret-down"></i>
				</NavLink>
				<div
					ref={ref}
					className={`dropdown-menu dropdown-menu-navbar animated ${
						props.isOpen ? "fadeIn show" : "fadeOut"
					}`}
				>
					<a
						className="dropdown-item"
						href={`${appConfig.domains.identity}/profile`}
					>
						Profile
					</a>
					<Link className="dropdown-item" to="/MyOrders">
						My Orders
					</Link>
					<span
						className="dropdown-item"
						color="primary"
						onClick={props.authStore.authFunctions.Signout}
					>
						Sign out
					</span>
				</div>
			</NavItem>
		</>
	);
};

const LoggedOutTemplate = (authStore: IAuthStore) => {
	return (
		<NavItem>
			<span
				className="nav-link clickable text-primary"
				color="primary"
				onClick={authStore.authFunctions.Signin}
			>
				My BU
			</span>
		</NavItem>
	);
};

const closeOnClickAway = () => {
	const className = "navbar-click-away";

	if (document.body.className.indexOf(className) >= 0) {
		return;
	}

	document.body.className += " " + className;
	document.addEventListener("click", (event: MouseEvent) => {
		const nav = document.querySelector(".navbar-collapse.show");
		const target = event.target as any;

		if (
			target.closest(".search-box") ||
			target.querySelector(".search-box")
		) {
			event.preventDefault();
			event.stopImmediatePropagation();
			return;
		}

		if (
			nav &&
			!target.closest(".nav-toggler") &&
			!target.closest(".nav-dropdown")
		) {
			(document.querySelector(".nav-toggler") as any)?.click();
			nav.className = nav.className.replace("show", "");
		}
	});
};

const NavBar = (props: IPropsAndRedux) => {
	const [isNavbarOpen, setIsNavbarOpen] = useState(false);
	const toggleNavbar = () => {
		setIsNavbarOpen(!isNavbarOpen);
	};

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};
	const closeDropdown = () => {
		setIsDropdownOpen(false);
	};

	const { trackGuid } = useParams<IRouteParams>();
	const openCartOnLoad = (trackGuid ?? "") !== "";

	const cartSummaryProps = {
		trackList: props.State.Music.trackList,
		releaseList: props.State.Music.releaseList.releases,
		cartItemList: props.State.BeatsShop.Cart.itemList,
		cartFunctions: props.Actions.BeatsShop.Cart,
		showCartButton: props.showCartButton,
		openCartOnLoad: openCartOnLoad,
	};

	const authStore: IAuthStore = {
		authState: props.State.Auth,
		authFunctions: props.Actions.Auth,
	};

	React.useEffect(() => {
		async function effect() {
			await props.Actions.BeatsShop.Cart.InitializeAsync(trackGuid);
		}

		closeOnClickAway();
		effect();
	}, []);

	return (
		<header>
			<Navbar color="light" light expand="md" className="fixed-top">
				<Container className="align-items-center d-flex align-item-center">
					<div className="flex-grow-1">
						<NavbarBrand
							href={appConfig.domains.wordpress}
							className="d-inline"
						>
							<img
								className="navbar-logo"
								alt="BeatsUnion"
								src="/images/logo/beats_union_hz_blue.svg"
							/>
						</NavbarBrand>
					</div>
					<NavbarToggler className=" flex-grow-0">
						<div className={`${props.showCartButton ? "" : "hidden"}`}>
							<CartSummary {...cartSummaryProps} />
						</div>
					</NavbarToggler>
					<NavbarToggler
						onClick={toggleNavbar}
						className="nav-toggler flex-grow-0"
					>
						<i className="fa fa-bars" />
					</NavbarToggler>
					<Collapse
						className="d-md-inline-flex flex-md-row-reverse"
						isOpen={isNavbarOpen}
						navbar
					>
						<ul className="navbar-nav flex-grow font-size-1_2">
							<NavItem>
								<SearchBox searchTerm={props.searchTerm} />
							</NavItem>
							<NavItem>
								<NavLink tag={Link} to={`/`} className="text-primary">
									Explore Music
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className="text-primary"
									href={`${appConfig.domains.legacyWeb}/account/login?returnUrl=%2FProducer%2FRelease%2FReleaseListInProgress`}
								>
									Artist Portal
								</NavLink>
							</NavItem>
							<AuthProvider
								loggedInTemplate={(auth) => (
									<LoggedInTemplate
										authStore={auth}
										isOpen={isDropdownOpen}
										toggle={toggleDropdown}
										close={closeDropdown}
									/>
								)}
								loggedOutTemplate={LoggedOutTemplate}
								authStore={authStore}
							/>
							<NavItem
								className={`d-none d-md-block  ${
									props.showCartButton ? "" : "hidden"
								}`}
							>
								<CartSummary {...cartSummaryProps} />
							</NavItem>
						</ul>
					</Collapse>
				</Container>
			</Navbar>
		</header>
	);
};

const connectedComponent = connectStore<IProps>(NavBar);
export default connectedComponent;
