import { ChartFilterDto } from "src/api/models/v1/dto/music/chartFilterDto";
import { ChartTypes } from "src/api/models/v1/enums/music/charts/chartTypes";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { ChartResponse } from "src/api/models/v2/responses/music/charts/chartResponse";
import { chartService } from "src/api/services/music/chartService";
import { ChartLayouts } from "src/shared/enums";
import utils from "src/shared/utils";
import { createNewGuid } from "src/shared/utils/string";
import { AppThunkAction } from "src/store";
import { IRestResponse } from "typed-rest-client";
import { IAjaxifyResponse, initialAjaxifyFlags } from "../../../shared/ajaxify";
import {
	Actions as TrackActions,
	KnownActions as TrackKnownActions,
} from "../track/track.actions";
import { IChartState } from "./chart.state";

export interface ILayoutChangedPayload {
	layout: ChartLayouts;
	chartType: ChartTypes;
	chartGuid: string;
}

export enum ActionTypes {
	ChartRequestStarted = "[Chart] Request Started",
	ChartRequestSuccess = "[Chart] Request Success",
	ChartRequestError = "[Chart] Request Error",
	ChartLayoutChanged = "[Chart] Layout Changed",
	ChartTrackUpdated = "[Chart] Track Updated",
}

interface IChartRequestStarted {
	type: ActionTypes.ChartRequestStarted;
	payload: ChartTypes;
}

interface IChartRequestSuccess {
	type: ActionTypes.ChartRequestSuccess;
	payload: IChartState;
}

interface IChartRequestError {
	type: ActionTypes.ChartRequestError;
	payload: IAjaxifyResponse<ChartResponse> | null;
}

interface ILayoutChanged {
	type: ActionTypes.ChartLayoutChanged;
	payload: ILayoutChangedPayload;
}

interface IChartTrackUpdated {
	type: ActionTypes.ChartTrackUpdated;
	payload: TrackDto;
}

export type ChartKnownActions =
	| TrackKnownActions
	| IChartRequestStarted
	| IChartRequestSuccess
	| IChartRequestError
	| ILayoutChanged
	| IChartTrackUpdated;

const getChartLayoutStorageKey = (chartType: ChartTypes): string => {
	const key = `chart_layout_${chartType}`;
	return key;
};

const RequestChart =
	(
		chartType: ChartTypes,
		filter?: ChartFilterDto | null,
		chartGuid?: string,
		pageNumber?: number,
		targetId?: string
	): AppThunkAction<ChartKnownActions> =>
	async (dispatch, getState) => {
		chartGuid = chartGuid ? chartGuid : "";
		filter = filter === undefined ? null : filter;
		pageNumber = pageNumber === undefined ? 1 : pageNumber;

		const state = getState();
		dispatch({
			type: ActionTypes.ChartRequestStarted,
			payload: chartType,
		});

		let response: IRestResponse<ChartResponse> | null = null;
		switch (chartType) {
			case ChartTypes.Unknown:
				throw Error("Chart type is Unknown");

			case ChartTypes.DefaultUI:
				response = await new chartService().getDefaultUIAsync(
					filter,
					pageNumber
				);
				break;

			case ChartTypes.User:
				response = await new chartService().getUserByGuidAsync(
					chartGuid,
					pageNumber
				);
				break;

			case ChartTypes.Release:
				targetId = targetId || createNewGuid();
				response = await new chartService().getReleaseByCatalogNo(targetId);
				chartGuid = targetId;
				break;

			case ChartTypes.Artist:
				targetId = targetId || createNewGuid();
				response = await new chartService().getReleaseByAlias(
					targetId,
					pageNumber
				);
				chartGuid = targetId;
				break;

			default:
				response = await new chartService().getByTypeAsync(
					chartType,
					pageNumber
				);
				break;
		}

		if (response && response.result && response.statusCode === 200) {
			// dispatch(TrackActions.TrackListUpdated(response.result.trackList, state?.beatsShop?.cart?.itemList ?? [], getState));

			const key = getChartLayoutStorageKey(chartType);
			const layout =
				(await utils.storage.get<ChartLayouts>(key)) || ChartLayouts.Grid;

			let track: TrackDto | undefined = undefined;

			dispatch(
				TrackActions.TrackListUpdated(
					response.result.trackList,
					state?.beatsShop?.Cart?.itemList ?? [],
					getState
				)
			);

			dispatch({
				type: ActionTypes.ChartRequestSuccess,
				payload: {
					type: chartType,
					height: "500px",
					filter: filter,
					layout: layout,
					chartGuid: chartGuid || "",
					paging: response.result.paging,
					trackList: response.result.trackList,
					ajaxifyFlags: initialAjaxifyFlags,
				},
			} as IChartRequestSuccess);

			dispatch(
				TrackActions.TrackListUpdated(
					response.result.trackList,
					state?.beatsShop?.Cart?.itemList ?? [],
					getState
				)
			);

			// window.setTimeout(() => {
			// 	if (!response?.result) {
			// 		return;
			// 	}

			// 	dispatch(TrackActions.TrackListUpdated(response.result.trackList, state?.beatsShop?.cart?.itemList ?? [], getState));

			// }, 0);
			return;
		} else {
			dispatch({
				type: ActionTypes.ChartRequestError,
				payload: null,
			} as IChartRequestError);
		}
	};

export const ChartActionsCreator = {
	ChartRequestedByCatalogNo: (
		catalogNo: string
	): AppThunkAction<ChartKnownActions> /*=> async (dispatch, getState)*/ => {
		return RequestChart(
			ChartTypes.Release,
			undefined,
			undefined,
			undefined,
			catalogNo
		);
	},

	ChartRequestedByAlias: (
		alias: string,
		pageNumber: number
	): AppThunkAction<ChartKnownActions> /*=> async (dispatch, getState)*/ => {
		return RequestChart(
			ChartTypes.Artist,
			undefined,
			undefined,
			pageNumber,
			alias
		);
	},

	ChartRequested: (
		chartType: ChartTypes,
		filter?: ChartFilterDto | null,
		chartGuid?: string,
		pageNumber?: number,
		catalogNo?: string
	): AppThunkAction<ChartKnownActions> => /*async (dispatch, getState) =>*/ {
		return RequestChart(chartType, filter, chartGuid, pageNumber, catalogNo);
	},

	LayoutChanged:
		(payload: ILayoutChangedPayload): AppThunkAction<ChartKnownActions> =>
		async (dispatch, getState) => {
			const key = getChartLayoutStorageKey(payload.chartType);
			await utils.storage.set(key, payload.layout);

			dispatch({
				type: ActionTypes.ChartLayoutChanged,
				payload,
			});
		},

	TrackUpdated:
		(trackGuid: string): AppThunkAction<ChartKnownActions> =>
		async (dispatch, getState) => {
			const trackList = getState().music.trackList;
			var track = trackList.find(
				(x) =>
					x.guid.toLowerCase().trim() === trackGuid.toLowerCase().trim()
			);
			if (!track) return;

			dispatch({
				type: ActionTypes.ChartTrackUpdated,
				payload: track,
			});
		},
};
