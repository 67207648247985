import Axios from "axios";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import appConfig from "src/shared/utils/appConfig";
import { IRestResponse } from "typed-rest-client";
import { BaseService } from "../baseService";

export class trackService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async getTracksByGuidsAsync(
		guids: string[]
	): Promise<IRestResponse<TrackDto[]>> {
		// const guidCsv = guids.join(",");
		let path = `api/v2/music/track`;
		guids = guids || [];
		guids = guids.filter((x) => x.trim() !== "");
		if (!guids.length) {
			return {
				statusCode: 200,
				result: [],
				headers: {},
			};
		}

		const config = await super.getAxiosConfigAsync(false);
		const response = await Axios.post<TrackDto[]>(path, guids, config);
		return super.getRestResponse(response);
	}
}
