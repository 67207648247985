

export enum ColorCodes {

	Primary = "primary",
	Secondary = "secondary",
	Info = "info",
	Danger = "danger",
	Success = "success",
	Warning = "warning",
	Light = "light",
	Dark = "dark"
}


export enum ContainerLayoutTypes {
	Default = "Default",
	HomePage = "HomePage",
    BeatsShop = "BeatsShop",
}


export enum ChartLayouts {
	Grid,
	List,
	ListCompact,
}
