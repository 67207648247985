import { TransferProgressEvent } from "@azure/core-http";
import { BlobServiceClient } from "@azure/storage-blob";
import Axios from "axios";
import { FileAndKey } from "src/components/common/upload/fileListProgress";
import appConfig from "src/shared/utils/appConfig";
import { BaseService } from "../baseService";

interface SasTokenResponse {
	url: string;
	path: string;
}

export interface IUploadResponse {
	url: string;
	path: string;
	fileAndKey: FileAndKey;
}

export class UploadService extends BaseService {
	constructor() {
		super(appConfig.domains.storage);
	}

	public async uploadAsync(
		userGuid: string,
		fileAndKey: FileAndKey,
		onProgress?: (file: File, progress: number) => void
	): Promise<IUploadResponse> {
		const url = `/api/v1/upload/${userGuid}?filename=${encodeURIComponent(
			fileAndKey.file.name
		)}`;
		const config = await super.getAxiosConfigAsync();

		const response = await Axios.get<SasTokenResponse>(url, config);
		if (response.data) {
			return await this.uploadFileAsync(
				fileAndKey,
				response.data,
				onProgress
			);
		}

		throw new Error("");
	}

	public async notifyUpload(
		postUrl: string,
		uploads: IUploadResponse[]
	): Promise<any> {
		const data = uploads.map((upload) => {
			const { url, path } = upload;
			return { url, path };
		});

		return await Axios.post(postUrl, data);
	}

	private async uploadFileAsync(
		fileAndKey: FileAndKey,
		sasTokenResponse: SasTokenResponse,
		onProgress?: (file: File, progress: number) => void
	): Promise<IUploadResponse> {
		const blobServiceClient = new BlobServiceClient(sasTokenResponse.url);

		const containerClient = await blobServiceClient.getContainerClient("");

		const blobClient = containerClient.getBlockBlobClient(
			sasTokenResponse.path
		);

		const options = {
			blobHTTPHeaders: {
				blobContentType: fileAndKey.file.type,
			},
			onProgress: (progress: TransferProgressEvent) =>
				this.onProgress(fileAndKey.file, progress, onProgress),
		};
		await blobClient.uploadData(fileAndKey.file, options);

		let basePath = `${sasTokenResponse.url.substring(
			0,
			sasTokenResponse.url.indexOf("?")
		)}`;
		if (!basePath.endsWith("/")) {
			basePath = `${basePath}/`;
		}
		const url = `${basePath}${sasTokenResponse.path}`;

		return {
			url: url,
			fileAndKey: fileAndKey,
			path: sasTokenResponse.path,
		};
	}

	private onProgress(
		file: File,
		progress: TransferProgressEvent,
		onProgress?: (file: File, progress: number) => void
	) {
		if (onProgress) {
			onProgress(file, progress.loadedBytes);
		}
	}
}
