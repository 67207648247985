import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export interface IAjaxifyFlags {
	requestInProgress: boolean;
	requestSuccess: boolean;
	requestError: boolean;
	errorMessage: string;
}

export const initialAjaxifyFlags: IAjaxifyFlags = {
	errorMessage: "",
	requestError: false,
	requestSuccess: false,
	requestInProgress: false,
};

export interface IAjaxifyResponse<TPayload, TData = any> {
	isSuccess: boolean;
	message: string;
	errorCode: number | null;
	payload: TPayload | null;
	postedData: TData;
}

const configPost: AxiosRequestConfig = {
	headers: {
		"Content-Type": "application/json",
	},
};

function JSON_to_URLEncoded(element: any, key?: any, list?: any) {
	list = list || [];
	if (typeof element == "object") {
		for (var idx in element)
			JSON_to_URLEncoded(
				element[idx],
				key ? key + "[" + idx + "]" : idx,
				list
			);
	} else {
		list.push(key + "=" + encodeURIComponent(element.trim()));
	}
	return list.join("&");
}

export function GetAsync<TPayload, TData = any>(
	url: string,
	postData: TData,
	errorMsgResolver?: (errorType: number) => string
): Promise<IAjaxifyResponse<TPayload>> {
	return new Promise((resolve, reject) => {
		let endPoint = url;
		var query = JSON_to_URLEncoded(postData);
		if (query !== "") endPoint = endPoint + "?" + query;

		axios
			.get<any, AxiosResponse<IAjaxifyResponse<TPayload>>>(endPoint)
			.then((resposne) => {
				const { data } = resposne;
				const errorMessage =
					errorMsgResolver &&
					data.errorCode &&
					errorMsgResolver(data.errorCode);

				resolve({
					errorCode: data.errorCode,
					message: errorMessage || data.message,
					isSuccess: data.isSuccess,
					payload: data.payload,
					postedData: postData,
				});
			})
			.catch((exp) => {
				resolve({
					errorCode: null,
					message: "Could not complete your request",
					isSuccess: false,
					payload: null,
					postedData: postData,
				});
			});
	});
}

export function PostAsync<TPayload, TRequest = any>(
	url: string,
	requestData: TRequest,
	errorMsgResolver?: (errorType: number) => string
): Promise<IAjaxifyResponse<TPayload>> {
	return new Promise((resolve, reject) => {
		axios
			.post<any, AxiosResponse<IAjaxifyResponse<TPayload>>>(
				url,
				requestData,
				configPost
			)
			.then((resposne) => {
				const { data } = resposne;
				const errorMessage =
					errorMsgResolver &&
					data.errorCode &&
					errorMsgResolver(data.errorCode);

				resolve({
					errorCode: data.errorCode,
					message: errorMessage || data.message,
					isSuccess: data.isSuccess,
					payload: data.payload,
					postedData: requestData,
				});
			})
			.catch((exp) => {
				resolve({
					errorCode: null,
					message: "Could not complete your request",
					isSuccess: false,
					payload: null,
					postedData: requestData,
				});
			});
	});
}

export default {
	PostAsync,
	GetAsync,
};
