import * as React from "react";
import { ColorCodes } from "../../shared/enums";
import util from "../../shared/utils";

interface IProps {
	name?: string;
	color?: ColorCodes;
	caption?: string;
	checked?: boolean;
	disabled?: boolean;
	onChanged?: (
		isChecked: boolean,
		e: React.ChangeEvent<HTMLInputElement>
	) => any;
}

interface IState {
	color: ColorCodes;
	caption: string;
	checked: boolean;
}

export class Checkbox extends React.Component<IProps, IState> {
	state: IState = {
		color: this.props.color ?? ColorCodes.Primary,
		caption: this.props.caption ?? "",
		checked: this.props.checked ?? false,
	};

	handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): any => {
		this.setState(
			(state, props) => {
				return {
					checked: !state.checked,
				};
			},
			() => {
				if (this.props.onChanged)
					this.props.onChanged(this.state.checked, e);
			}
		);
	};

	guid = util.string.createNewGuid();

	render() {
		return (
			<div className="form-check">
				<input
					className="form-check-input"
					id={this.guid}
					type="checkbox"
					disabled={this.props.disabled}
					checked={this.state.checked}
					name={this.props.name}
					onChange={(e) => this.handleOnChange(e)}
				/>
				<label className="form-check-label" htmlFor={this.guid}>
					{this.state.caption}
				</label>
			</div>
		);
	}
}
