import * as React from "react";
import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";
import { ArtistDetails } from "./artistDetails";
import { ArtistSummary } from "./artistSummary";

export enum ArtistLayouts {
	Summary,
	Details,
}

export interface IProps {
	alias: string;
	artist: ArtistDto | null;
	layout: ArtistLayouts;
}

export const Artist: React.FC<IProps> = (props) => {
	switch (props.layout) {
		case ArtistLayouts.Details:
			return <ArtistDetails alias={props.alias} artist={props.artist} />;

		case ArtistLayouts.Summary:
		default:
			return <ArtistSummary alias={props.alias} artist={props.artist} />;
	}
};
