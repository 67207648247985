import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router";
import { Container } from "reactstrap";
import { ChartTypes } from "src/api/models/v1/enums/music/charts/chartTypes";
import NavBar from "src/components/layout/navBar";
import { IChartFunctions } from "src/components/music/chart";
import { ReleaseDetail } from "src/components/music/release/releaseDetail";
import { initialChartState } from "src/store/music/chart/chart.state";
import { mapToPlayerFunctions } from "src/store/player/player.actions";
import { IReduxProps } from "src/store/storeHelper";

interface IProps extends IReduxProps {}

interface IRouteParams {
	catalogNo: string;
	releaseName: string;
	trackGuid: string;
}

function getChartFunctions(props: IProps): IChartFunctions {
	return {
		cartFunctions: props.Actions.BeatsShop.Cart,
		playerFunctions: mapToPlayerFunctions(props.Actions.Player),
		changeLayout: props.Actions.Music.LayoutChanged,
		loadChart: props.Actions.Music.ChartRequested,
		loadChartByCatalogNo: props.Actions.Music.ChartRequestedByCatalogNo,
	};
}

export const Release: React.FC<IProps> = (props) => {
	const { catalogNo } = useParams<IRouteParams>();
	const release = props.State.Music.releaseList.releases.find(
		(x) => x.catalogNo.trim().toUpperCase() === catalogNo.trim().toUpperCase()
	);

	const trackUserGuids =
		release?.trackUserGuids.map((guid, i) => guid.toUpperCase().trim()) || [];
	const artists =
		props.State.Artist.artistList?.filter((x) =>
			trackUserGuids.find((y) => x.guid.trim().toUpperCase() === y)
		) || [];

	const chart = props.State.Music.chartList.find(
		(x) =>
			x.type === ChartTypes.Release &&
			x.chartGuid.trim().toUpperCase() === catalogNo.trim().toUpperCase()
	);

	const chartFunctions = getChartFunctions(props);

	useEffect(() => {
		function fetch() {
			if (chartFunctions.loadChartByCatalogNo) {
				chartFunctions.loadChartByCatalogNo(catalogNo);
			}

			props.Actions.Release.getReleaseByCatalogNo(catalogNo);
		}

		fetch();
	}, []);

	const ownerArtist = artists.find(
		(x) => x.guid.toLowerCase() === release?.ownerGuid.toLocaleLowerCase()
	);
	if (ownerArtist?.isActive === false) {
		return <Redirect to="/" />;
	}

	return release ? (
		<>
			<NavBar showCartButton={true} />

			<Container fluid={true} className="container-p-t">
				<ReleaseDetail
					artists={artists}
					chart={chart || initialChartState}
					chartFunctions={chartFunctions}
					cartFunctions={props.Actions.BeatsShop.Cart}
					release={release}
					getArtistsByGuids={props.Actions.Artist.GetArtistsByGuids}
				/>
			</Container>
		</>
	) : (
		<></>
	);
};

export default Release;
// export default connectStoreToBeatsShop(Release);
