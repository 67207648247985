import React from "react";
import { MasonryOptions } from "react-masonry-component";
import { Col, Row } from "reactstrap";
import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";
import { ICartFunctions } from "src/store/beatsShop/cart/cart.actions";
import { ReleaseGridView } from "./releaseGridView";

export interface IProps {
	alias: string;
	artist: ArtistDto;
	releases: ReleaseDto[] | null;

	getReleasesByArtistAlias: (alias: string) => any;
	cartFunctions: ICartFunctions;
}

export const ReleaseGridViewByAlias = (props: IProps) => {
	const masonryOptions: MasonryOptions = {
		transitionDuration: 0,
		fitWidth: true,
		horizontalOrder: true,
	};

	const { releases, alias, getReleasesByArtistAlias } = props;
	React.useEffect(() => {
		function fetch() {
			getReleasesByArtistAlias(alias);
		}

		fetch();
	}, []);

	const ownReleases = releases?.filter(
		(x) =>
			// !x.isOwnerRecordLabel &&
			x.ownerGuid.toLowerCase() === props.artist.guid.toLowerCase()
	);
	const otherReleases = props.releases?.filter(
		(x) => x.ownerGuid.toLowerCase() !== props.artist.guid.toLowerCase()
	);

	return (
		<>
			{ownReleases && !!ownReleases.length && (
				<>
					<Row>
						<Col>
							<ReleaseGridView
								releases={ownReleases}
								cartFunctions={props.cartFunctions}
								itemWidth="300px"
							/>
						</Col>
					</Row>
				</>
			)}
			{otherReleases && !!otherReleases.length && (
				<>
					<div className="h1 my-1">Other Releases</div>
					<Row>
						<Col>
							<ReleaseGridView
								releases={otherReleases}
								cartFunctions={props.cartFunctions}
								itemWidth="300px"
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};
