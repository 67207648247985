import { Action, Reducer } from "redux";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { playlistStorageKey } from "src/store/player/player.actions";
import utils from "../../../shared/utils";
import { ICartItem } from "../../beatsShop/cart/cart.state";
import { ApplicationState } from "../../index";
import { ActionTypes, KnownActions } from "./track.actions";

export const trackListReducer: Reducer<TrackDto[]> = (
	currentState: TrackDto[] = [],
	incomingAction: Action
): TrackDto[] => {
	let action = incomingAction as KnownActions;
	let currentStateClone = utils.array.deepClone<TrackDto>(currentState);

	switch (action.type) {
		case ActionTypes.TrackListUpdated:
			return addOrUpdateTracks(
				action.payload.trackList,
				action.payload.cartItemList,
				action.applicationState
			);

		case ActionTypes.TrackMarkedInCart:
			return markTrackInCart(currentStateClone, action.payload);

		case ActionTypes.TrackRemovedFromCart:
			return removeTrackFromCart(currentStateClone, action.payload);

		case ActionTypes.IsPlayingToggled:
			return toggleIsPlaying(currentStateClone, action.payload);

		case ActionTypes.PlayingStopped:
			return stopPlaying(currentStateClone, action.payload);

		case ActionTypes.IsInPlaylistChanged:
			return isInPlaylistChanged(
				currentStateClone,
				action.payload.trackGuid,
				action.payload.isInPlayList
			);
	}

	return currentState || [];
};

function findTrackByGuid(
	trackList: TrackDto[],
	trackGuid: string
): TrackDto | null {
	if (!trackList || !trackList.length) return null;

	return (
		trackList.find(
			(x) => x.guid.toLowerCase().trim() === trackGuid.toLowerCase().trim()
		) ?? null
	);
}

function addOrUpdateTracks(
	trackList: TrackDto[],
	cartItems: ICartItem[],
	applicationState: ApplicationState
): TrackDto[] {
	let currentStateClone = utils.array.deepClone(
		applicationState.music.trackList
	);

	for (let i = 0; i < currentStateClone.length; i++) {
		const currentTrack = currentStateClone[i];
		const track = findTrackByGuid(trackList, currentTrack.guid);
		if (track) {
			track.isInCart = track.isInCart || currentStateClone[i].isInCart;
			currentStateClone[i] = track;
		}
	}

	for (let i = 0; i < trackList.length; i++) {
		const track = trackList[i];
		const currentTrack = findTrackByGuid(currentStateClone, track.guid);
		if (!currentTrack) currentStateClone.push(track);
	}

	for (let i = 0; i < cartItems.length; i++)
		markTrackInCart(currentStateClone, cartItems[i].guid);

	utils.storage
		.get(playlistStorageKey)
		.then((tracksInPlaylist: Array<string>) => {
			tracksInPlaylist &&
				tracksInPlaylist.forEach((trackGuidInPlaylist) => {
					let track = findTrackByGuid(
						currentStateClone,
						trackGuidInPlaylist
					);
					if (track) {
						track.isInPlaylist = true;
					}
				});
		});

	return currentStateClone;
}

function markTrackInCart(currentStateClone: TrackDto[], trackGuid: string) {
	// let track = findTrackByGuid(currentStateClone, trackGuid);
	// if (track)
	// 	track = {
	// 		...track,
	// 		isInCart: true
	// 	};

	for (let i = 0; i < currentStateClone.length; i++) {
		const track = currentStateClone[i];
		if (track.guid.toLowerCase() == trackGuid.toLowerCase()) {
			currentStateClone[i] = {
				...track,
				isInCart: true,
			};
		}
	}

	return currentStateClone;
}

function removeTrackFromCart(currentStateClone: TrackDto[], trackGuid: string) {
	let track = findTrackByGuid(currentStateClone, trackGuid);
	if (track) track.isInCart = false;

	return currentStateClone;
}

function toggleIsPlaying(currentStateClone: TrackDto[], trackGuid: string) {
	for (let i = 0; i < currentStateClone.length; i++)
		if (
			currentStateClone[i].guid.toLowerCase().trim() !==
			trackGuid.toLowerCase().trim()
		)
			currentStateClone[i].isPlaying = false;

	let track = findTrackByGuid(currentStateClone, trackGuid);
	if (track) track.isPlaying = !track.isPlaying;

	return currentStateClone;
}

function stopPlaying(currentStateClone: TrackDto[], trackGuid: string) {
	for (let i = 0; i < currentStateClone.length; i++)
		currentStateClone[i].isPlaying = false;

	return currentStateClone;
}

function isInPlaylistChanged(
	currentStateClone: TrackDto[],
	trackGuid: string,
	isInPlaylist: boolean
) {
	let track = findTrackByGuid(currentStateClone, trackGuid);
	if (track) track.isInPlaylist = isInPlaylist;

	return currentStateClone;
}
