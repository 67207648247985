import { User } from "oidc-client";
import { UserDto } from "src/api/models/dto/users/userDto";
import { AuthService } from "src/api/services/auth/authService";
import { UserService } from "src/api/services/user/userService";
import { AppThunkAction } from "src/store";

export interface IAuthFunctions {
	Initialize: () => void;
	Signin: () => void;
	Signout: () => void;
	SigninCallback: () => void;
	SilentSigninCallback: () => void;
	SignoutCallback: () => void;
}

export enum ActionTypes {
	Initialized = "[Auth] Initialized",

	SigninRequested = "[Auth] Signin requested",
	SigninSucceess = "[Auth] Signin succeeded",
	SigninError = "[Auth] Signin failed",

	SignoutRequested = "[Auth] Signout requested",
	SignoutSucceess = "[Auth] Signout succeeded",
	SignoutError = "[Auth] Signout failed",
}

interface IAuthInitialized {
	type: ActionTypes.Initialized;
	payload: {
		authUser: User | null;
		currentUser: UserDto | null;
	};
}

interface IAuthSigninRequested {
	type: ActionTypes.SigninRequested;
}

interface IAuthSigninSuccess {
	type: ActionTypes.SigninSucceess;
	payload: {
		authUser: User;
		currentUser: UserDto;
	};
}

interface IAuthSigninError {
	type: ActionTypes.SigninError;
}

interface IAuthSignoutRequested {
	type: ActionTypes.SignoutRequested;
}

interface IAuthSignoutSuccess {
	type: ActionTypes.SignoutSucceess;
}

interface IAuthSignoutError {
	type: ActionTypes.SignoutError;
}

export type AuthKnownActions =
	| IAuthInitialized
	| IAuthSigninRequested
	| IAuthSigninSuccess
	| IAuthSigninError
	| IAuthSignoutRequested
	| IAuthSignoutSuccess
	| IAuthSignoutError;

export const AuthActionsCreator = {
	Initialize:
		(): AppThunkAction<AuthKnownActions> => async (dispatch, getState) => {
			const authUser = await AuthService.loadUserFromStorage();
			if (authUser) {
				const currentUserResponse =
					await new UserService().getCurrentUserAsync(
						authUser.access_token
					);
				if (currentUserResponse?.result) {
					dispatch({
						type: ActionTypes.Initialized,
						payload: {
							authUser: authUser,
							currentUser: currentUserResponse.result,
						},
					});
					return;
				}
			}

			AuthService.removeUserFromStorage();

			dispatch({
				type: ActionTypes.Initialized,
				payload: {
					authUser: null,
					currentUser: null,
				},
			});
		},

	Signin:
		(): AppThunkAction<AuthKnownActions> => async (dispatch, getState) => {
			const accesssToken = await AuthService.getAccessTokenAsync();
			if (!accesssToken || accesssToken === "") {
				AuthService.signinRedirect();

				dispatch({
					type: ActionTypes.SigninRequested,
				});
			}
		},

	Signout:
		(): AppThunkAction<AuthKnownActions> => async (dispatch, getState) => {
			const user = await AuthService.loadUserFromStorage();
			if (user) {
				AuthService.signoutRedirect();
				dispatch({
					type: ActionTypes.SignoutRequested,
				});
			}
		},

	SigninCallback:
		(): AppThunkAction<AuthKnownActions> => async (dispatch, getState) => {
			const authUser = await AuthService.signinRedirectCallback();
			if (!authUser) {
				return;
			}
			const currentUserResponse =
				await new UserService().getCurrentUserAsync(authUser.access_token);
			if (currentUserResponse?.result) {
				dispatch({
					type: ActionTypes.SigninSucceess,
					payload: {
						authUser: authUser,
						currentUser: currentUserResponse.result,
					},
				});
			}
		},

	SilentSigninCallback:
		(): AppThunkAction<AuthKnownActions> => async (dispatch, getState) => {
			const authUser = await AuthService.silentSigninRedirectCallback();
			if (!authUser) {
				return;
			}
			const currentUserResponse =
				await new UserService().getCurrentUserAsync(authUser.access_token);
			if (currentUserResponse?.result) {
				dispatch({
					type: ActionTypes.SigninSucceess,
					payload: {
						authUser: authUser,
						currentUser: currentUserResponse.result,
					},
				});
			}
		},

	SignoutCallback:
		(): AppThunkAction<AuthKnownActions> => async (dispatch, getState) => {
			AuthService.signoutRedirectCallback();
			dispatch({
				type: ActionTypes.SignoutSucceess,
			});
		},
};
