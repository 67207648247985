import { IAppConfig } from "../../app.config/_app.config";

import development from "src/app.config/app.config.development";
import production from "src/app.config/app.config.production";
import sandbox from "src/app.config/app.config.sandbox";
import staging from "src/app.config/app.config.staging";

import aks_development from "src/app.config/app.config.aks.development";
import aks_production from "src/app.config/app.config.aks.production";
import aks_sandbox from "src/app.config/app.config.aks.sandbox";
import development_k8s from "src/app.config/app.config.development.k8s";

//@ts-ignore
import { env } from "../../env";

class AppConfig {
	public get value(): IAppConfig {
		const allConfigs = {
			development,
			production,
			sandbox,
			staging,
			development_k8s,
			aks_development,
			aks_production,
			aks_sandbox,
		};

		let envName = env.REACT_APP_ENV_NAME ?? "production";
		const key = envName.trim().replace(/\./gi, "_").trim();

		let result = ((allConfigs as any)[key] || production) as IAppConfig;
		result.environments.namespace = env.REACT_APP_NAMESPACE;
		return result;
	}
}

const config = new AppConfig();
export default config.value;
