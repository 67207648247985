import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { ApplicationState, AppThunkAction } from "../..";
import { ICartItem } from "../../beatsShop/cart/cart.state";
import { storeDispatch } from "../../storeRegistry";
import { ChartActionsCreator } from "../chart/chart.actions";

export enum ActionTypes {
	TrackListUpdated = "[Tracks] Track List Updated",
	TrackMarkedInCart = "[Tracks] Track was added to the cart",
	TrackRemovedFromCart = "[Tracks] Track was removed from the cart",
	IsPlayingToggled = "[Tracks] Play/Pause state was toggled",
	PlayingStopped = "[Tracks] Playing the track stopped",
	IsInPlaylistChanged = "[Tracks] Added/Removed from Player's playlist",
}

interface IUpdateTrackList {
	type: ActionTypes.TrackListUpdated;
	payload: {
		trackList: TrackDto[];
		cartItemList: ICartItem[];
	};
	applicationState: ApplicationState;
}

interface ITrackMarkedInCart {
	type: ActionTypes.TrackMarkedInCart;
	payload: string;
}

interface ITrackRemovedFromCart {
	type: ActionTypes.TrackRemovedFromCart;
	payload: string;
}

interface IIsPlayingToggled {
	type: ActionTypes.IsPlayingToggled;
	payload: string;
}

interface IPlayingStopped {
	type: ActionTypes.PlayingStopped;
	payload: string;
}

interface IIsInPlaylistChanged {
	type: ActionTypes.IsInPlaylistChanged;
	payload: {
		trackGuid: string;
		isInPlayList: boolean;
	};
}

export type KnownActions =
	| IUpdateTrackList
	| ITrackMarkedInCart
	| IIsPlayingToggled
	| IPlayingStopped
	| IIsInPlaylistChanged
	| ITrackRemovedFromCart;

export const Actions = {
	TrackListUpdated: (
		trackList: TrackDto[],
		cartItemList: ICartItem[],
		getState: () => ApplicationState
	): IUpdateTrackList => {
		return {
			type: ActionTypes.TrackListUpdated,
			payload: {
				trackList,
				cartItemList,
			},
			applicationState: getState(),
		};
	},

	MarkedTrackInCart: (trackGuid: string): ITrackMarkedInCart => {
		return {
			type: ActionTypes.TrackMarkedInCart,
			payload: trackGuid,
		};
	},

	TrackRemovedFromCart: (trackGuid: string): ITrackRemovedFromCart => {
		return {
			type: ActionTypes.TrackRemovedFromCart,
			payload: trackGuid,
		};
	},

	IsPlayingToggled: (trackGuid: string): IIsPlayingToggled => {
		return {
			type: ActionTypes.IsPlayingToggled,
			payload: trackGuid,
		};
	},

	PlayingStopped: (trackGuid: string): IPlayingStopped => {
		return {
			type: ActionTypes.PlayingStopped,
			payload: trackGuid,
		};
	},

	IsInPlaylistChanged: (
		trackGuid: string,
		isInPlayList: boolean
	): IIsInPlaylistChanged => {
		return {
			type: ActionTypes.IsInPlaylistChanged,
			payload: {
				trackGuid,
				isInPlayList,
			},
		};
	},
};

export const TrackActionsCreator = {
	TrackListUpdated:
		(
			trackList: TrackDto[],
			cartItemList: ICartItem[]
		): AppThunkAction<KnownActions> =>
		(dispatch, getState) => {
			if (trackList && trackList.length)
				dispatch(
					Actions.TrackListUpdated(trackList, cartItemList, getState)
				);
		},

	MarkedTrackInCart:
		(trackGuid: string): AppThunkAction<KnownActions> =>
		(dispatch, getState) => {
			dispatch(Actions.MarkedTrackInCart(trackGuid));
			storeDispatch(ChartActionsCreator.TrackUpdated(trackGuid));
		},

	TrackRemovedFromCart:
		(trackGuid: string): AppThunkAction<KnownActions> =>
		(dispatch, getState) => {
			dispatch(Actions.TrackRemovedFromCart(trackGuid));
			storeDispatch(ChartActionsCreator.TrackUpdated(trackGuid));
		},

	IsPlayingToggled:
		(trackGuid: string): AppThunkAction<KnownActions> =>
		(dispatch, getState) => {
			dispatch(Actions.IsPlayingToggled(trackGuid));
			storeDispatch(ChartActionsCreator.TrackUpdated(trackGuid));
		},

	PlayingStopped:
		(trackGuid: string): AppThunkAction<KnownActions> =>
		(dispatch, getState) => {
			dispatch(Actions.PlayingStopped(trackGuid));
			storeDispatch(ChartActionsCreator.TrackUpdated(trackGuid));
		},

	IsInPlaylistChanged:
		(
			trackGuid: string,
			isInPlayList: boolean
		): AppThunkAction<KnownActions> =>
		(dispatch, getState) => {
			dispatch(Actions.IsInPlaylistChanged(trackGuid, isInPlayList));
			storeDispatch(ChartActionsCreator.TrackUpdated(trackGuid));
		},
};
