import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { chartService } from "src/api/services/music/chartService";
import { releaseService } from "src/api/services/music/releaseService";
import { trackService } from "src/api/services/music/trackService";
import utils from "src/shared/utils";
import { AppThunkAction, AppThunkActionAsync } from "src/store";
import { ReleaseActionCreator } from "src/store/music/release/release.actions";
import { TrackActionsCreator } from "src/store/music/track/track.actions";
import { storeDispatch } from "src/store/storeRegistry";
import { ReleaseDto } from "./../../../api/models/v2/dto/music/releaseDto";
import { CartItemTypes, ICartItem } from "./cart.state";

export interface ICartFunctions {
	InitializeAsync(trackGuid?: string): void;
	AddToCart(guid: string, itemType: CartItemTypes, date?: Date): void;
	RemoveFromCart(guid: string, itemType: CartItemTypes): void;
}

export enum ActionTypes {
	Initialized = "[BeatsShop.Cart] Initialized",
	ItemAddedToCart = "[BeatsShop.Cart] Item Added to Cart",
	ItemRemovedFromCart = "[BeatsShop.Cart] Item Removed from Cart",
	PaymentCompleted = "[BeatsShop.Cart] Payment Succeeded for CartItems",
}

interface IInitialized {
	type: ActionTypes.Initialized;
	payload: ICartItem[];
}

interface IItemAddedToCart {
	type: ActionTypes.ItemAddedToCart;
	payload: ICartItem;
}

interface IItemRemovedFromCart {
	type: ActionTypes.ItemRemovedFromCart;
	payload: ICartItem;
}

interface IPaymentCompleted {
	type: ActionTypes.PaymentCompleted;
}

export type KnownActions =
	| IInitialized
	| IItemAddedToCart
	| IItemRemovedFromCart
	| IPaymentCompleted;
const storageKey = "BeatsShop.cart";

export const CartActions = {
	InitializedAsync:
		(trackGuid: string = ""): AppThunkActionAsync<KnownActions, any> =>
		async (dispatch, getState): Promise<any> => {
			let cartItemListFromStorage =
				(await utils.storage.get<ICartItem[]>(storageKey)) || [];

			//--- Adding Tracks to cart ---
			const trackGuidList = cartItemListFromStorage
				.filter((x) => x.type === CartItemTypes.Track)
				.map((x) => x.guid);

			if (
				!trackGuidList.find(
					(x) => x.toLowerCase().trim() === trackGuid?.toLowerCase().trim()
				)
			) {
				trackGuidList.push(trackGuid);
			}

			let tracks: TrackDto[] = [];
			if (trackGuidList?.length) {
				const res = await new trackService().getTracksByGuidsAsync(
					trackGuidList
				);
				if (res && res.statusCode === 200 && res.result) {
					tracks = res.result;
					for (const track of tracks) {
						track.isInCart = true;
						storeDispatch(
							CartActions.ItemAddedToCart(
								track.guid,
								CartItemTypes.Track
							)
						);
					}
					storeDispatch(TrackActionsCreator.TrackListUpdated(tracks, []));
				}
			}

			// tracks.forEach((track) => {
			// 	if (track.isInCart) {
			// 		storeDispatch(
			// 			CartActions.ItemAddedToCart(track.guid, CartItemTypes.Track)
			// 		);
			// 	}
			// });

			//--- Adding Releases to cart ---
			const releaseGuids = cartItemListFromStorage
				.filter((x) => x.type === CartItemTypes.Release)
				.map((x) => x.guid);

			let releases: ReleaseDto[] = [];
			if (releaseGuids?.length) {
				const releasesInStore = getState()?.music?.releaseList?.releases;
				const releaseGuidsInStore =
					releasesInStore?.map((x) => x.guid) ?? [];
				const loadReleaseGuids = releaseGuids.filter(
					(x) =>
						!releaseGuidsInStore.find(
							(y) => x.toLowerCase() === y.toLowerCase()
						)
				);

				const res = await new releaseService().getListByGuidsAsync(
					loadReleaseGuids
				);
				if (res && res.statusCode === 200 && res.result) {
					storeDispatch(
						ReleaseActionCreator.updateReleaseList(res.result)
					);
				}

				releases = getState()?.music?.releaseList?.releases.filter((x) =>
					releaseGuids.find(
						(y) => x.guid.toLowerCase() === y.toLowerCase()
					)
				);
			}

			releases.forEach((release) => {
				release.isInCart = true;
				storeDispatch(
					CartActions.ItemAddedToCart(release.guid, CartItemTypes.Release)
				);
			});

			setTimeout(async () => {
				cartItemListFromStorage =
					(await utils.storage.get<ICartItem[]>(storageKey)) || [];

				dispatch({
					type: ActionTypes.Initialized,
					payload: cartItemListFromStorage,
				});
			}, 0);
		},

	ItemAddedToCart:
		(
			guid: string,
			itemType: CartItemTypes,
			isAddedByRelease: boolean = false,
			date?: Date
		): AppThunkAction<KnownActions> =>
		async (dispatch, getState) => {
			dispatch({
				type: ActionTypes.ItemAddedToCart,
				payload: {
					guid: guid,
					type: itemType,
					addDate: date ?? new Date(),
					isAddedByRelease: isAddedByRelease,
				},
			});

			if (itemType === CartItemTypes.Track) {
				storeDispatch(TrackActionsCreator.MarkedTrackInCart(guid));
			} else if (itemType === CartItemTypes.Release) {
				storeDispatch(ReleaseActionCreator.MarkReleaseInCart(guid));
			}

			var dataFromStorage = await utils.storage.get<ICartItem[]>(storageKey);
			if (!dataFromStorage || !dataFromStorage.length) {
				dataFromStorage = [];
			}

			if (
				!dataFromStorage.find(
					(x) =>
						x.guid.toLowerCase().trim() === guid.toLowerCase().trim() &&
						x.type === itemType
				)
			) {
				dataFromStorage.push({
					guid: guid,
					type: itemType,
					isAddedByRelease: isAddedByRelease,
					addDate: date ?? new Date(),
				});
				await utils.storage.set<ICartItem[]>(
					storageKey,
					dataFromStorage,
					60 * 24 * 30
				); //1 month
			}

			if (itemType === CartItemTypes.Release) {
				const release = await (
					await new releaseService().getListByGuidsAsync([guid])
				)?.result?.at(0);
				if (release) {
					const response = await (
						await new chartService().getReleaseByCatalogNo(
							release.catalogNo
						)
					).result;
					response?.trackList.forEach((track) => {
						storeDispatch(
							CartActions.ItemRemovedFromCart(
								track.guid,
								CartItemTypes.Track
							)
						);

						storeDispatch(
							CartActions.ItemAddedToCart(
								track.guid,
								CartItemTypes.Track,
								true
							)
						);
					});
				}
			}
		},

	ItemRemovedFromCart:
		(guid: string, itemType: CartItemTypes): AppThunkAction<KnownActions> =>
		async (dispatch, getState) => {
			dispatch({
				type: ActionTypes.ItemRemovedFromCart,
				payload: {
					guid: guid,
					type: itemType,
					addDate: new Date(),
				},
			});

			if (itemType === CartItemTypes.Track) {
				storeDispatch(TrackActionsCreator.TrackRemovedFromCart(guid));
			} else if (itemType === CartItemTypes.Release) {
				storeDispatch(ReleaseActionCreator.RemovedReleaseFromCart(guid));
			}

			var dataFromStorage =
				(await utils.storage.get<ICartItem[]>(storageKey)) ?? [];
			// if (!dataFromStorage || !dataFromStorage.length) {
			// 	return;
			// }

			const item = dataFromStorage.find(
				(x) =>
					x.guid.toLowerCase().trim() === guid.toLowerCase().trim() &&
					x.type == itemType
			);
			// if (!item) {
			// 	return;
			// }

			if (item) {
				dataFromStorage = utils.array.cloneRemove<ICartItem>(
					dataFromStorage,
					item,
					(x) => x.guid
				);
			}

			await utils.storage.set<ICartItem[]>(
				storageKey,
				dataFromStorage,
				60 * 24 * 30
			); //1 month

			if (itemType === CartItemTypes.Release) {
				const release = await (
					await new releaseService().getListByGuidsAsync([guid])
				)?.result?.at(0);
				if (release) {
					const response = await (
						await new chartService().getReleaseByCatalogNo(
							release.catalogNo
						)
					).result;
					response?.trackList.forEach((track) => {
						storeDispatch(
							CartActions.ItemRemovedFromCart(
								track.guid,
								CartItemTypes.Track
							)
						);
					});
				}
			}
		},

	PaymentSucceededForItems:
		(
			trackGuidList: Array<string>,
			releaseGuidList: Array<string>
		): AppThunkAction<KnownActions> =>
		async (dispatch, getState) => {
			trackGuidList.forEach((trackGuid) => {
				setTimeout(() => {
					storeDispatch(
						CartActions.ItemRemovedFromCart(
							trackGuid,
							CartItemTypes.Track
						)
					);
				}, 0);
			});

			releaseGuidList.forEach((releaseGuid) => {
				setTimeout(() => {
					storeDispatch(
						CartActions.ItemRemovedFromCart(
							releaseGuid,
							CartItemTypes.Release
						)
					);
				}, 0);
			});

			// const releases = await new releaseService().getListByGuidsAsync(releaseGuidList);
			// const tracksInRelease

			await utils.storage.set<ICartItem[]>(storageKey, [], 60 * 24 * 30); //1 month

			dispatch({
				type: ActionTypes.PaymentCompleted,
			});
		},
};
