import axios from "axios";
import { CheckoutRequest } from "src/api/models/v1/requests/beatsShop/checkoutRequest";
import { CheckoutResponse } from "src/api/models/v1/responses/beatsShop/checkoutResponse";
import appConfig from "src/shared/utils/appConfig";
import { ICheckoutForm } from "src/store/beatsShop/orders/checkout.state";
import { BaseService } from "../../baseService";

export class checkoutService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async processAsync(
		requestGuid: string,
		checkoutForm: ICheckoutForm,
		trackGuidList: Array<string>,
		releaseGuidList: Array<string>
	): Promise<CheckoutResponse> {
		const request: CheckoutRequest = {
			guid: requestGuid,
			userGuid: checkoutForm.userGuid,
			cardHolderName: checkoutForm.cardHolderName,
			stripeInfo: {
				amount: checkoutForm.amount,
				currency: "CAD",
				stripeToken: {
					id: checkoutForm.stripeToken.token?.id || "",
				},
			},
			trackGuidList: trackGuidList,
			releaseGuidList: releaseGuidList,
			firstName: checkoutForm.firstName,
			lastName: checkoutForm.lastName,
			email: checkoutForm.email,
			isGuestCheckout: checkoutForm.isGuestCheckout,
		};

		const url = appConfig.domains.api + "/api/v2/beatsshop/order/checkout";
		try {
			const response = await axios.post<CheckoutResponse>(url, request);
			return response.data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	}
}
