import * as FileType from "file-type";
import { createNewGuid } from "./string";

export enum FileTypes {
	wav = "wav",
	mp3 = "mp3",
	jpg = "jpg",
	png = "png",
}

export interface IFileType {
	ext?: string;
	mime?: string;
}

const getFileTypeAsync = (file: File): Promise<IFileType> => {
	return new Promise((resolve, reject) => {
		const filereader = new FileReader();
		filereader.onloadend = function (evt: ProgressEvent<FileReader> | any) {
			if (evt.target.readyState === FileReader.DONE) {
				if (this.result) {
					let array = new Uint8Array(this.result as any);
					FileType.fromBuffer(array).then((x) =>
						resolve({
							ext: x?.ext,
							mime: x?.mime,
						})
					);
				}
			}
		};

		filereader.onerror = function (evt: ProgressEvent<FileReader> | any) {
			reject();
		};

		filereader.readAsArrayBuffer(file);
	});
};

export const downloadFile = (url: string, downloadName?: string) => {
	const a = document.createElement("a") as HTMLAnchorElement;
	a.href = url;
	a.id = createNewGuid();
	a.target = "_blank";
	a.download = downloadName || url.substring(url.lastIndexOf("/") + 1);
	a.setAttribute("download", a.download);

	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

export default {
	downloadFile,
	getFileTypeAsync,
};
