import factory, { IAppConfig } from "./_app.config";
const appConfig = factory();

export default {
	...appConfig,

	environments: {
		...appConfig.environments,
		isProduction: false,
		isStaging: true,
		name: "Staging",
	},

	stripe: {
		publishableKey: "pk_live_ESUQBOUVoqTRQCODJFi3R60Q00LbfFgFKN",
	},

	domains: {
		api: "https://api-staging.beatsunion.com",
		legacyWeb: "https://manager-staging.beatsunion.com",
		wordpress: "https://beatsunion.com",
		identity: "https://beatsunion-identity-staging.azurewebsites.net",
		react: "https://staging.beatsunion.com",
		storage: "https://beatsunion-storage-staging.azurewebsites.net",
	},

	auth: {
		authority: "https://beatsunion-identity-staging.azurewebsites.net",
		client_id: "BeatsUnion.Web.React",
		response_type: "id_token token",
		scope: "openid profile offline_access api1.read",
		automaticSilentRenew: true,
		redirect_uri: "https://staging.beatsunion.com/signin-oidc",
		silent_redirect_uri: "https://staging.beatsunion.com/silent-signin-oidc",
		extraQueryParams: {
			signoutUrl: "https://staging.beatsunion.com/signout-oidc",
		},
	},
} as IAppConfig;
