import array from "./array";

export const createNewGuid = (): string => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
		var r = (Math.random() * 16) | 0,
			v = c === "x" ? r : r & (0x3 | 0x8);
		return v.toString(16);
	});
};

export const getUrlEncodedCsv = (
	data: string[],
	delimiter: string = ","
): string => {
	const encodedData = array.deepClone(data);
	encodedData.forEach((v, i) => encodeURI(v));
	return encodedData.join(delimiter);
};

export const getHashCode = (value: string): number => {
	var hash = 0;
	for (var i = 0; i < value.length; i++) {
		var character = value.charCodeAt(i);
		hash = (hash << 5) - hash + character;
		hash = hash & hash; // Convert to 32bit integer
	}
	return hash;
};

export const formatBytes = (bytes: number) => {
	var marker = 1024; // Change to 1000 if required
	var decimal = 2; // Change as required
	var kiloBytes = marker; // One Kilobyte is 1024 bytes
	var megaBytes = marker * marker; // One MB is 1024 KB
	var gigaBytes = marker * marker * marker; // One GB is 1024 MB
	var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB

	// return bytes if less than a KB
	if (bytes < kiloBytes) return bytes + " Bytes";
	// return KB if less than a MB
	else if (bytes < megaBytes)
		return (bytes / kiloBytes).toFixed(decimal) + " KB";
	// return MB if less than a GB
	else if (bytes < gigaBytes)
		return (bytes / megaBytes).toFixed(decimal) + " MB";
	// return GB if less than a TB
	else return (bytes / gigaBytes).toFixed(decimal) + " GB";
};

export const zeroPad = (value: number, places: number) => {
	var zero = places - value.toString().length + 1;
	return Array(+(zero > 0 && zero)).join("0") + value;
};

export default {
	createNewGuid,
	getUrlEncodedCsv,
	getHashCode,
	formatBytes,
	zeroPad,
};
