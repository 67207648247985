import { artistReducer } from "./artist/artist.reducer";
import { IArtistState, InitialArtistState } from "./artist/artist.state";
import { authReducer } from "./auth/auth.reducer";
import { IAuthState, InitialAuthState } from "./auth/auth.state";
import { beatsShopReducer } from "./beatsShop/beatsShop.reducer";
import {
	IBeatsShopState,
	initialBeatsShopState,
} from "./beatsShop/beatsShop.state";
import { OrderReducer } from "./beatsShop/orders/checkout.reducer";
import {
	initialOrderState,
	IOrderState,
} from "./beatsShop/orders/checkout.state";
import { musicReducer } from "./music/music.reducer";
import { IMusicState, initialMusicState } from "./music/music.state";
import { searchCriteriaReducer } from "./music/searchCriteria/searchCriteria.reducer";
import { playerReducer } from "./player/player.reducer";
import { initialPlayerState, IPlayerState } from "./player/player.state";
import { searchReducer } from "./search/search.reducer";
import { InitialSearchState, ISearchState } from "./search/search.state";

// The top-level state object
export interface ApplicationState {
	beatsShop: IBeatsShopState;
	player: IPlayerState;
	music: IMusicState;
	checkout: IOrderState;
	artist: IArtistState;
	auth: IAuthState;
	search: ISearchState;
}

export const InitialApplicationState: ApplicationState = {
	beatsShop: initialBeatsShopState,
	player: initialPlayerState,
	music: initialMusicState,
	checkout: initialOrderState,
	artist: InitialArtistState,
	auth: InitialAuthState,
	search: InitialSearchState,
};

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
	beatsShop: beatsShopReducer,
	player: playerReducer,
	music: musicReducer,
	checkout: OrderReducer,
	searchCriteria: searchCriteriaReducer,
	artist: artistReducer,
	auth: authReducer,
	search: searchReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
	(
		dispatch: (action: TAction) => void,
		getState: () => ApplicationState
	): void;
}

export interface AppThunkActionAsync<TAction, TResult> {
	(
		dispatch: (action: TAction) => void,
		getState: () => ApplicationState
	): Promise<TResult>;
}
