import { Form, Formik } from "formik";
import React, { Component } from "react";
import { injectStripe, ReactStripeElements } from "react-stripe-elements";
import { Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { LoggedInCheckout } from "src/components/beatsShop/checkout/loggedInCheckout";
import { LoggedOutCheckout } from "src/components/beatsShop/checkout/loggedOutCheckout";
import { AjaxButton } from "src/components/common";
import { IAjaxifyFlags } from "src/shared/ajaxify";
import utils from "src/shared/utils";
import { createNewGuid } from "src/shared/utils/string";
import {
	ICheckoutForm,
	initialOrderState,
	OrderSchema,
} from "src/store/beatsShop/orders/checkout.state";
import { AuthProvider, IAuthStore } from "../auth/authProvider";

export interface ICheckoutFormProps {
	totalAmount: number;
	ajaxifyFlags: IAjaxifyFlags | null;

	authStore: IAuthStore;
	processPaymentAsync: (values: ICheckoutForm) => any;
}

interface IState {
	stripeHasError: boolean;
	stripeIsComplete: boolean;
	requestGuid: string;
}

export type ICheckoutProps = ICheckoutFormProps &
	ReactStripeElements.InjectedStripeProps;

export class CheckoutForm extends Component<ICheckoutProps, IState> {
	constructor(props: ICheckoutProps) {
		super(props);
		this.state = {
			stripeHasError: false,
			stripeIsComplete: props.totalAmount === 0,
			requestGuid: utils.string.createNewGuid(),
		};
	}

	async handleSubmit(values: ICheckoutForm) {
		const { stripe } = this.props;
		if (!stripe) {
			return;
		}
		values.userGuid = this.props.authStore.authState.user?.guid || "";
		values.isGuestCheckout = values.userGuid === "";

		if (this.props.totalAmount > 0) {
			const response = await stripe?.createToken({
				name: values.cardHolderName,
				currency: "CAD",
				type: "card",
			});

			if (response.error) {
				this.setState((state, props) => {
					return { ...this.state, stripeHasError: true };
				});
				return;
			}

			values.stripeToken = response;
		}
		values.requestGuid = this.state.requestGuid;

		if (!this.props.processPaymentAsync) {
			return;
		}

		await this.props.processPaymentAsync(values);
	}

	onStripeChanged(e: stripe.elements.ElementChangeResponse) {
		if (e?.complete) {
			this.setState((state, props) => {
				return { ...this.state, stripeIsComplete: true };
			});
		} else {
			this.setState((state, props) => {
				return { ...this.state, stripeIsComplete: false };
			});
		}
	}

	render() {
		const showError =
			this.props.ajaxifyFlags?.requestError &&
			this.props?.ajaxifyFlags?.requestInProgress === false;

		var initialValues = initialOrderState.form;
		initialValues.userGuid =
			this.props.authStore?.authState?.user?.guid ?? "";
		if (this.props.totalAmount === 0) {
			initialValues.cardHolderName = createNewGuid();
			initialValues.stripeToken = {};
		} else {
			initialValues.cardHolderName = "";
		}

		return (
			<>
				<Formik<ICheckoutForm>
					initialValues={initialOrderState.form}
					validateOnMount={true}
					validationSchema={OrderSchema}
					onSubmit={(values) => this.handleSubmit(values)}
				>
					{(formikProps) => {
						return (
							<Form>
								<div className="mt-1">
									<AuthProvider
										authStore={this.props.authStore}
										loggedInTemplate={(auth: IAuthStore) => (
											<LoggedInCheckout
												{...this.props}
												onStripeChanged={(e) =>
													this.onStripeChanged(e)
												}
												showPayment={this.props.totalAmount > 0}
												auth={auth}
											/>
										)}
										loggedOutTemplate={(auth: IAuthStore) => (
											<LoggedOutCheckout
												{...this.props}
												showPayment={this.props.totalAmount > 0}
												onStripeChanged={(e) =>
													this.onStripeChanged(e)
												}
											/>
										)}
									/>
								</div>

								<div className="mt-2">
									<hr />

									<Row className="mt-2">
										<Col xs="12" lg="4">
											<AuthProvider
												authStore={this.props.authStore}
												loggedInTemplate={(auth: IAuthStore) => (
													<AjaxButton
														type="submit"
														className="btn btn-lg btn-primary w-100"
														loading={
															this.props?.ajaxifyFlags
																?.requestInProgress || false
														}
														loadingText="Processing your Payment"
													>
														Confirm Purchase
													</AjaxButton>
												)}
												loggedOutTemplate={(auth: IAuthStore) =>
													formikProps.isValid &&
													this.state.stripeIsComplete &&
													Object.keys(formikProps.touched).length >
														0 ? (
														<AjaxButton
															type="submit"
															className="btn btn-lg btn-primary w-100"
															loading={
																this.props?.ajaxifyFlags
																	?.requestInProgress || false
															}
															loadingText="Processing your Payment"
														>
															Confirm Purchase
														</AjaxButton>
													) : (
														<Button
															className="w-100"
															type="button"
															color="default"
															size="lg"
															disabled={true}
														>
															Confirm Purchase
														</Button>
													)
												}
											/>
										</Col>

										<Col
											xs="12"
											lg="8"
											className={`animated ${
												showError ? "fadeIn" : "hidden"
											}`}
										>
											<ListGroup>
												<ListGroupItem color="danger">
													<i className="fa fa-exclamation-circle" />
													&nbsp;
													<strong>Payment failed.</strong>&nbsp;
													There was an error while processing your
													payment. Please try again later.
												</ListGroupItem>
											</ListGroup>
										</Col>
									</Row>
								</div>
							</Form>
						);
					}}
				</Formik>
			</>
		);
	}
}

export default injectStripe<ICheckoutFormProps>(CheckoutForm);
//export CheckoutFormInjected;
