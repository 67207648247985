import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { CenterScreen } from "src/components/layout/centerScreen";

interface IProps {}

export const GuestOrderError = (props: IProps) => {
	return (
		<Container>
			<Row className="mt-2 mt-md-4">
				<Col>
					<CenterScreen title="">
						<ListGroup>
							<ListGroupItem color="danger">
								<i className="fa fa-exclamation-circle" />
								&nbsp;
								<strong>Invalid Request.</strong>&nbsp; This download
								link is not valid.
							</ListGroupItem>
						</ListGroup>
						<Row>
							<Col className="mt-2">
								<Link to={"/"} className="btn btn-lg btn-primary">
									Home
								</Link>
							</Col>
						</Row>
					</CenterScreen>
				</Col>
			</Row>
		</Container>
	);
};
