import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";
import { AppThunkAction } from "src/store";
import { ArtistService } from "../../api/services/artists/artistService";

export enum ArtistActionTypes {
	ArtistInitialized = "[Artist] Artist initialized",
	ArtistRequestedAliases = "[Artist] Artist requested by alias(es)",
	ArtistRequestedGuids = "[Artist] Artist requested by Guid(s)",
	ArtistSuccess = "[Artist] Artist success",
	ArtistError = "[Artist] Artist error",
}

interface IArtistInitialized {
	type: ArtistActionTypes.ArtistInitialized;
}

interface IArtistRequestedAliases {
	type: ArtistActionTypes.ArtistRequestedAliases;
	payload: string;
}

interface IArtistRequestedGuids {
	type: ArtistActionTypes.ArtistRequestedGuids;
	payload: string[];
}

interface IArtistSuccess {
	type: ArtistActionTypes.ArtistSuccess;
	payload: ArtistDto[];
}

interface IArtistError {
	type: ArtistActionTypes.ArtistError;
}

export type ArtistKnownActions =
	| IArtistInitialized
	| IArtistRequestedAliases
	| IArtistRequestedGuids
	| IArtistSuccess
	| IArtistError;

export const ArtistActionsCreator = {
	GetArtistsByAlias:
		(alias: string): AppThunkAction<ArtistKnownActions> =>
		async (dispatch, getState) => {
			dispatch({
				type: ArtistActionTypes.ArtistRequestedAliases,
				payload: alias,
			});

			const response = await new ArtistService().getByAliasAsync(alias);

			if (response && response.statusCode === 200 && response.result) {
				dispatch({
					type: ArtistActionTypes.ArtistSuccess,
					payload: [response.result],
				});
			} else {
				dispatch({
					type: ArtistActionTypes.ArtistError,
				});
			}
		},

	GetArtistsByGuids:
		(guids: string[]): AppThunkAction<ArtistKnownActions> =>
		async (dispatch, getState) => {
			dispatch({
				type: ArtistActionTypes.ArtistRequestedGuids,
				payload: guids,
			});

			const response = await new ArtistService().getByGuidsAsync(guids);

			if (response && response.statusCode === 200 && response.result) {
				dispatch({
					type: ArtistActionTypes.ArtistSuccess,
					payload: response.result,
				});
			} else {
				dispatch({
					type: ArtistActionTypes.ArtistError,
				});
			}
		},

	UpdateArtists:
		(artists: ArtistDto[]): AppThunkAction<ArtistKnownActions> =>
		async (dispatch, getState) => {
			dispatch({
				type: ArtistActionTypes.ArtistSuccess,
				payload: artists,
			});
		},
};
