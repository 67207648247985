import { Component } from "react";
import { Table } from "reactstrap";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { Img } from "src/components/common/img";
import utils from "src/shared/utils";
import { CartItemTypes } from "src/store/beatsShop/cart/cart.state";
import { IChartProps } from ".";
import { ArtistList } from "../common/artistList";
import { ReleaseName } from "../common/releaseName";
import { ButtonTypes, TrackButton } from "../common/trackButton";
import { ChartListViewCompact_Store } from "./chartListViewCompact";

export class ChartListView extends Component<IChartProps> {
	render() {
		const props = {
			tracks: this.props.chart.trackList,
			actionMode: this.props.actionMode,
			play: (track: TrackDto) => {
				this.props.chartFunctions.playerFunctions?.play(track.guid);
			},
			pause: (track: TrackDto) => {
				this.props.chartFunctions.playerFunctions?.pause(track.guid);
			},
			addToCart: (track: TrackDto) => {
				this.props.cartFunctions?.AddToCart(
					track.guid,
					CartItemTypes.Track
				);
			},
		};

		return (
			<>
				<div className="d-none d-md-block">
					<ChartListViewAllColumns {...this.props} />
				</div>
				<div className="d-md-none">
					<ChartListViewCompact_Store {...props} />
				</div>
			</>
		);
	}
}

class ChartListViewAllColumns extends Component<IChartProps> {
	handleAddToCartClick(trackGuid: string) {
		this.props.cartFunctions?.AddToCart(trackGuid, CartItemTypes.Track);
	}

	handlePlayPauseClick(track: TrackDto) {
		if (track.isPlaying)
			return this.props.chartFunctions.playerFunctions?.pause(track.guid);

		return this.props.chartFunctions.playerFunctions?.play(track.guid);
	}

	handleAddToPlaylist(track: TrackDto) {
		return this.props.chartFunctions.playerFunctions?.playlistFunctions.add(
			track.guid
		);
	}

	render() {
		return (
			<>
				<div className="chart-list-view d-flex flex-wrap text-black  ">
					<table className="table table-borderless table-sm ">
						{/* <thead>
							<tr>
								<th style={{ maxWidth: "300px" }}>Title</th>
								<th style={{ maxWidth: "200px" }}>Artist</th>
								<th style={{ maxWidth: "200px" }}>Release</th>
								<th style={{ maxWidth: "200px" }}>Genre</th>
								<th>BPM</th>
								<th>Length</th>
								<th className="ps-h">&nbsp;</th>
							</tr>
						</thead> */}

						<tbody>
							{this.props.chart.trackList?.map((track, index) => {
								return (
									<tr
										key={index}
										className={`shadow-hover border-separator ${
											track.isPlaying ? "shadow-normal" : ""
										}`}
									>
										<td
											style={{
												maxWidth: "300px",
												minWidth: "125px",
											}}
										>
											<div>
												<Img
													className="rounded me-h shadow-normal clickable"
													src={track.release.imageUrl}
													altSrc={track.release.altImageUrl}
													square={50}
													alt={track.title}
													onClick={this.handlePlayPauseClick.bind(
														this,
														track
													)}
												/>
												<span
													className="clickable"
													onClick={this.handlePlayPauseClick.bind(
														this,
														track
													)}
												>
													<strong>{track.title}</strong>
												</span>
											</div>
										</td>
										<td style={{ maxWidth: "200px" }}>
											<div>
												<ArtistList track={track} />
											</div>
										</td>
										<td style={{ maxWidth: "200px" }}>
											<ReleaseName track={track} />
										</td>
										<td style={{ maxWidth: "200px" }}>
											{track.genre}
										</td>
										{/* <td>{track.key}</td> */}
										<td>{track.bpm}</td>
										<td>
											{utils.dateTime.formatPlayerTime(
												track.duration
											)}
										</td>
										<td
											className="text-end text-no-wrap ps-h"
											style={{ maxWidth: "80px" }}
										>
											<Table className="table-compact table-borderless">
												<tbody>
													<tr>
														<td>
															<TrackButton
																size="sm"
																track={track}
																type={ButtonTypes.PlayPause}
																onClick={this.handlePlayPauseClick.bind(
																	this,
																	track
																)}
															/>
														</td>
														{/* <td>
															<TrackButton
																size="sm"
																track={track}
																type={ButtonTypes.AddToPlaylist}
																onClick={this.handleAddToPlaylist.bind(
																	this,
																	track
																)}
															/>
														</td> */}
														<td>
															<TrackButton
																size="sm"
																track={track}
																type={ButtonTypes.AddToCart}
																onClick={this.handleAddToCartClick.bind(
																	this,
																	track.guid
																)}
															/>
														</td>
													</tr>
												</tbody>
											</Table>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</>
		);
	}
}
