import * as React from "react";
import { useHistory } from "react-router";
import { Route, Switch, withRouter } from "react-router-dom";
import { NotFound } from "src/components/layout/notFound";
import { connectStore, IReduxProps } from "src/store/storeHelper";
import {
	SigninCallback,
	SignoutCallback,
	SilentSigninCallback,
} from "./components/auth/authCallback";
import ScrollToTop from "./components/common/scrollTop";
import Player from "./components/music/player";
import ArtistAlias from "./containers/Artist/alias";
import BeatsShop from "./containers/BeatsShop";
import Checkout from "./containers/BeatsShop/checkout";
import Confirm from "./containers/BeatsShop/confirm";
import { GuestOrderView } from "./containers/BeatsShop/guestOrderView";
import { OrderHistoryView } from "./containers/BeatsShop/orderHistoryView";
import { UploadView } from "./containers/IFrame/UploadView";
import Release from "./containers/Music/release";
import { InvoicesView } from "./containers/Payment/InvoicesView";
import { SubscriptionView } from "./containers/Payment/SubscriptionView";
import { Search } from "./containers/Search/search";
import utils from "./shared/utils";

interface IProps extends IReduxProps {}

const App = (props: IProps) => {
	React.useEffect(() => {
		async function workerAsync() {
			utils.dom.appendScriptTag(utils.appConfig.mdBootstrap.jsSrc);
		}

		workerAsync();
	});

	const withPlayer = (Component: React.ComponentType<IProps>) => {
		return (
			<>
				<Component {...props} />
				<Player {...props} />
			</>
		);
	};

	const history = useHistory();

	return (
		<React.Suspense>
			<Switch>
				<Route
					exact
					path="/User/Subscription/Invoices"
					children={<InvoicesView {...props} />}
				/>
				<Route
					exact
					path="/User/Subscription"
					children={<SubscriptionView {...props} />}
				/>
				<Route
					exact
					path="/signin-oidc"
					children={<SigninCallback authFunctions={props.Actions.Auth} />}
				/>
				<Route
					exact
					path="/silent-signin-oidc"
					children={
						<SilentSigninCallback authFunctions={props.Actions.Auth} />
					}
				/>
				<Route
					exact
					path="/signout-oidc"
					children={<SignoutCallback authFunctions={props.Actions.Auth} />}
				/>
				<Route
					exact
					path="/download/:guestOrderGuid"
					children={<GuestOrderView />}
				/>

				<Route exact path="/external/upload" children={<UploadView />} />

				{/* ------------------------ with player ------------------------- */}

				{/* <div> */}
				<Route
					exact
					path="/"
					children={() => {
						return withPlayer(BeatsShop);
					}}
				/>
				<Route
					exact
					path="/BeatShop"
					children={() => {
						return withPlayer(BeatsShop);
					}}
				/>
				<Route
					exact
					path="/MyOrders"
					children={withPlayer(OrderHistoryView)}
				/>
				<Route exact path="/Checkout" children={withPlayer(Checkout)} />
				<Route exact path="/Confirm" children={withPlayer(Confirm)} />
				<Route
					exact
					path="/Artist/:alias"
					children={() => withPlayer(ArtistAlias)}
				/>
				<Route
					exact
					path="/RecordLabel/:alias"
					children={() => withPlayer(ArtistAlias)}
				/>
				<Route
					exact
					path="/Release/:releaseName/:catalogNo"
					children={withPlayer(Release)}
				/>
				<Route
					exact
					path="/Track/:releaseName/:catalogNo/:trackGuid"
					children={withPlayer(Release)}
				/>
				<Route
					exact
					path="/search/:searchTerm"
					children={withPlayer(Search)}
				/>

				<Route
					component={() => {
						// if (!appConfig.environments.isDevelopment) {
						// 	window.location.href = `${appConfig.domains.wordpress}/NotFound`;
						// }
						return <NotFound></NotFound>;
					}}
				/>

				{/* <Player {...props} />
				</div> */}
				{/* <Route component={() => <RoutesWithPlayer {...props} />} /> */}
			</Switch>
			<ScrollToTop />
		</React.Suspense>
	);
};

const connectedApp = connectStore(App);
export default withRouter(connectedApp);
