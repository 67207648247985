import classnames from "classnames";
import { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router";
import {
	Col,
	Container,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from "reactstrap";
import { ChartTypes } from "src/api/models/v1/enums/music/charts/chartTypes";
import { Artist, ArtistLayouts } from "src/components/artist";
import { Footer } from "src/components/layout/footer";
import NavBar from "src/components/layout/navBar";
import Chart, {
	ActionModes,
	IChartFunctions,
} from "src/components/music/chart";
import { createNewGuid } from "src/shared/utils/string";
import { mapToPlayerFunctions } from "src/store/player/player.actions";
import { IReduxProps } from "src/store/storeHelper";
import { ReleaseGridViewByAlias } from "../../components/music/release/releaseGridViewByAlias";

interface IProps extends IReduxProps {}

interface IRouteParams {
	alias: string;
}

export const ArtistAlias = (props: IProps) => {
	const { alias } = useParams<IRouteParams>();
	const [activeTab, setActiveTab] = useState<string>("1");

	const artist = props.State.Artist.artistList?.find(
		(x) => x.alias.trim().toUpperCase() === alias.trim().toUpperCase()
	);

	const releaseList =
		props.State.Music.releaseList.releases.filter((x) =>
			x.trackUserGuids.find(
				(y) => y.toLowerCase().trim() === artist?.guid.toLowerCase().trim()
			)
		) || [];

	const loadChart = (alias: string, pageNumber: number) => {
		return props.Actions.Music.ChartRequestedByAlias(alias, pageNumber);
	};

	const chartFunctions: IChartFunctions = {
		playerFunctions: mapToPlayerFunctions(props.Actions.Player),
		changeLayout: props.Actions.Music.LayoutChanged,
		loadChart: (chartType, filter, guid, pageNo, alias) =>
			loadChart(alias ?? createNewGuid(), pageNo ?? 1),
		loadChartByAlias: loadChart,
	};

	useEffect(() => {
		function fetch() {
			props.Actions.Artist.GetArtistsByAlias(alias);
			props.Actions.Music.ChartRequestedByAlias(alias, 1);
		}

		fetch();
	}, []);

	const toggle = (tab: string) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	if (props.State.Artist.ajaxifyFlags.requestError) {
		const history = useHistory();
		history.push("/");

		return <></>;
	}

	if (artist?.isActive === false) {
		return <Redirect to="/" />;
	}

	const chart = props.State.Music.chartList.find(
		(x) => x.type === ChartTypes.Artist
	);
	if (!chart) {
		return null;
	}

	return (
		<>
			<NavBar showCartButton={true} />

			<Container fluid={true} className="container-p-t">
				<div>
					{artist && (
						<>
							<Artist
								alias={alias}
								layout={ArtistLayouts.Details}
								artist={artist}
							/>
						</>
					)}
				</div>
				<Row className="mt-2">
					<Col>
						<Nav tabs className="border-bottom">
							<NavItem className="flex-grow-1 text-center">
								<NavLink
									className={classnames({
										active: activeTab === "1",
										clickable: activeTab !== "1",
										"text-capitalize": true,
									})}
									onClick={() => {
										toggle("1");
									}}
								>
									<h3>Releases</h3>
								</NavLink>
							</NavItem>
							<NavItem className="flex-grow-1 text-center">
								<NavLink
									className={classnames({
										active: activeTab === "2",
										clickable: activeTab !== "2",
										"text-capitalize": true,
									})}
									onClick={() => {
										toggle("2");
									}}
								>
									<h3>Tracks</h3>
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<Row className="mt-1">
									<Col>
										{artist ? (
											<ReleaseGridViewByAlias
												alias={alias}
												artist={artist}
												releases={releaseList}
												cartFunctions={props.Actions.BeatsShop.Cart}
												getReleasesByArtistAlias={
													props.Actions.Release
														.getReleasesByArtistAlias
												}
											/>
										) : (
											<></>
										)}
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId="2">
								<Row className="mt-1">
									<Col>
										{chart?.trackList?.length ? (
											<Chart
												title=""
												// hideTitle={true}
												chart={chart}
												alias={alias}
												showLayoutToggle={true}
												showLayoutToggleOnRight={true}
												actionMode={ActionModes.Store}
												chartFunctions={chartFunctions}
												cartFunctions={props.Actions.BeatsShop.Cart}
											/>
										) : null}
									</Col>
								</Row>
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</Container>

			<Footer />
		</>
	);
};

export default ArtistAlias;
