import { Component } from "react";
import { Container } from "reactstrap";
import { ChartTypes } from "src/api/models/v1/enums/music/charts/chartTypes";
import { Body } from "src/components/beatsShop/body";
import { Footer } from "src/components/layout/footer";
import NavBar from "src/components/layout/navBar";
import { IChartFunctions } from "src/components/music/chart";
import { ChartLayouts } from "src/shared/enums";
import { initialChartState } from "src/store/music/chart/chart.state";
import { mapToPlayerFunctions } from "src/store/player/player.actions";
import { IReduxProps } from "src/store/storeHelper";

interface IProps extends IReduxProps {}

class BeatsShop extends Component<IProps> {
	async componentDidMount() {
		// await this.props.Actions.BeatsShop.cart.InitializeAsync();
		this.props.Actions.BeatsShop.Initialized();
		this.props.Actions.SearchCriteria.Load();
		this.props.Actions.Music.ChartRequested(ChartTypes.Hot);
		this.props.Actions.Music.ChartRequested(ChartTypes.DefaultUI, null, "");
	}

	chartFunctions(): IChartFunctions {
		return {
			playerFunctions: mapToPlayerFunctions(this.props.Actions.Player),
			changeLayout: this.props.Actions.Music.LayoutChanged,
			loadChart: this.props.Actions.Music.ChartRequested,
			loadChartByCatalogNo:
				this.props.Actions.Music.ChartRequestedByCatalogNo,
		};
	}

	render() {
		const defaultUI =
			this.props.State.Music.chartList.find(
				(x) => x.type === ChartTypes.DefaultUI
			) || initialChartState;

		const hotChart =
			this.props.State.Music.chartList.find(
				(x) => x.type === ChartTypes.Hot
			) || initialChartState;
		hotChart.layout = ChartLayouts.ListCompact;

		return (
			<>
				<NavBar showCartButton={true} />

				<Container fluid={true} className="container-p-t">
					<Body
						searchCriteria={
							this.props.State.Music.searchCriteria.searchCriteria
						}
						filterAjaxifyFlags={
							this.props.State.Music.searchCriteria.ajaxifyFlags
						}
						hotChart={hotChart}
						defaultUI={defaultUI}
						chartFunctions={this.chartFunctions()}
						cartFunctions={this.props.Actions.BeatsShop.Cart}
					/>
				</Container>

				<Footer />
			</>
		);
	}
}

export default BeatsShop;
