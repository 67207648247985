import { useState } from "react";
import {
	Card,
	CardBody,
	Container,
	Modal,
	ModalBody,
	ModalHeader,
} from "reactstrap";
import { OrderDto } from "src/api/models/v1/dto/beatsShop/orderDto";
import { AuthProvider, IAuthStore } from "src/components/auth/authProvider";
import { OrderDownload } from "src/components/beatsShop/orders/orderDownload";
import { OrderHistory } from "src/components/beatsShop/orders/orderHistory";
import NavBar from "src/components/layout/navBar";
import { IReduxProps } from "src/store/storeHelper";

interface IProps extends IReduxProps {}

export const OrderHistoryView = (props: IProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [order, setOrder] = useState<OrderDto | null>(null);

	const showDownloadModal = (order: OrderDto): void => {
		setIsModalOpen(true);
		setOrder(order);
		props.Actions.OrderHistory.getOrderTracks(order);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setOrder(null);
	};

	const tracks =
		props.State.BeatsShop.OrderHisotry.orderDetails[order?.guid || ""] || [];

	const authStore: IAuthStore = {
		authState: props.State.Auth,
		authFunctions: props.Actions.Auth,
	};

	return (
		<>
			<NavBar showCartButton={false} />
			<Container fluid>
				<AuthProvider
					authStore={authStore}
					loggedOutTitle="My Orders"
					loggedInTemplate={(auth) => {
						return (
							<>
								<h1 className="mt-2">My Orders</h1>
								<Card className="mt-2">
									<CardBody>
										<OrderHistory
											authStore={authStore}
											onDownloadClicked={showDownloadModal}
											orderHistory={
												props.State.BeatsShop.OrderHisotry
											}
											orderHistoryFuncions={
												props.Actions.OrderHistory
											}
										/>
									</CardBody>
								</Card>
								<Modal
									className="right"
									isOpen={isModalOpen}
									scrollable={true}
								>
									<ModalHeader toggle={closeModal}>
										Purchased Tracks
									</ModalHeader>
									<ModalBody>
										<OrderDownload
											tracks={tracks}
											orderGuid={order?.guid || ""}
										/>
									</ModalBody>
								</Modal>
							</>
						);
					}}
				/>
			</Container>
		</>
	);
};
