import { ICartState, initialCartState } from "./cart/cart.state";
import {
	IntialOrderHisotryState,
	IOrderHistoryState,
} from "./orders/history.state";

export interface IBeatsShopState {
	Cart: ICartState;
	OrderHisotry: IOrderHistoryState;
}

export const initialBeatsShopState: IBeatsShopState = {
	Cart: initialCartState,
	OrderHisotry: IntialOrderHisotryState,
};
