import { Field, FieldProps } from "formik";
import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import {
	FormikTextbox,
	InputTypes,
} from "src/components/common/formik/formikTextbox";
import { StripeSimple } from "src/components/payment/stripeSimple";
import { ICheckoutForm } from "src/store/beatsShop/orders/checkout.state";
import { nameof } from "ts-simple-nameof";

interface IProps {
	onChanged?: (e: stripe.elements.ElementChangeResponse) => void;
}

export const Payment = (props: IProps) => {
	const [hasError, setHasError] = useState(false);

	return (
		<>
			<Row>
				<Col>
					<FormikTextbox
						label="Cardholder Name"
						name={nameof<ICheckoutForm>((f) => f.cardHolderName)}
						type={InputTypes.text}
					/>
				</Col>
			</Row>
			<Row className="mt-1">
				<Col>
					<Field name="stripeToken">
						{(renderArgs: FieldProps<any>) => {
							const { meta } = renderArgs;
							return (
								<div className="form-outline">
									<StripeSimple
										id="stripe"
										formikMeta={meta}
										hasError={hasError}
										onChange={props.onChanged}
									/>
								</div>
							);
						}}
					</Field>
				</Col>
			</Row>
		</>
	);
};
