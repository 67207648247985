import { Col, Container, Row } from "reactstrap";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { GuestOrderResponse } from "src/api/models/v2/responses/beatsShop/guestOrderResponse";
import { DownloadService } from "src/api/services/music/downloadService";
import { CenterScreen } from "src/components/layout/centerScreen";
import { ActionModes } from "src/components/music/chart";
import { ChartListViewCompact_Download } from "src/components/music/chart/chartListViewCompact";
import { downloadFile } from "src/shared/utils/files";

interface IProps {
	guestOrder: GuestOrderResponse;
}

export const GuestOrderValid = (props: IProps) => {
	const downloadTrack = async (
		track: TrackDto,
		fileType: string,
		orderGuid: string
	) => {
		const response = await new DownloadService().downloadTrackAsync(
			fileType,
			track.guid,
			orderGuid,
			true
		);

		if (!response) {
			return;
		}

		const artistNames = track.collaborators
			.map((artist) => artist.alias)
			.join(", ");
		const downloadName = `${artistNames} - ${track.title}.${fileType}`;
		downloadFile(response.downloadUrl, downloadName);
	};

	return (
		<Container>
			<Row className="mt-2 mt-md-4">
				<Col>
					<CenterScreen title="">
						<Row>
							<Col>
								<h4>Your Downloads</h4>
								<div className="text-muted">
									To download your track, click on the MP3 or WAV
									button.
								</div>
								<div className="mt-2">
									<ChartListViewCompact_Download
										tracks={props.guestOrder.trackList}
										actionMode={ActionModes.Download}
										downloadAsync={(track, fileType) =>
											downloadTrack(
												track,
												fileType,
												props.guestOrder.orderGuid
											)
										}
									/>
								</div>
							</Col>
						</Row>
					</CenterScreen>
				</Col>
			</Row>
		</Container>
	);
};
