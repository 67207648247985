import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import appConfig from "src/shared/utils/appConfig";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey:
			appConfig.azure.applicationInsights.instrumentationKey,
		disableFetchTracking: false,
		extensions: [reactPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory },
		},
	},
});

appInsights.loadAppInsights();
export { reactPlugin, appInsights };
