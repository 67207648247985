import React from "react";
import { Container } from "reactstrap";
import { GuestOrder } from "src/components/beatsShop/orders/guestOrders";
import NavBar from "src/components/layout/navBar";
import { useQueryString } from "src/shared/utils/hooks";

interface IRouteParams {
	guestOrderGuid: string;
}

export const GuestOrderView = () => {
	const params = useQueryString<IRouteParams>();

	return (
		<>
			<NavBar showCartButton={false} />
			<Container fluid>
				<GuestOrder guestOrderGuid={params.guestOrderGuid} />
			</Container>
		</>
	);
};
