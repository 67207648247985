import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Action, bindActionCreators, Dispatch } from "redux";
import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { ApplicationState } from "../../../store";
import {
	mapToPlayerFunctions,
	PlayerActionCreators,
} from "../../../store/player/player.actions";
import { FullPlayer } from "./fullPlayer";
import { PeaksJS } from "./peaksJS";

export type IPlayerState = ReturnType<typeof mapStateToProps>;
export type IPlayerProps = IOwnProps &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapActionsToProps>;

interface IOwnProps {}

interface IOwnState {
	size: "full" | "minimized";
	progress: number;
	isVisible: boolean;
}

class Player extends Component<IPlayerProps, IOwnState> {
	peaksJsInstance: PeaksJS | null = null;
	divWaveContainer: React.RefObject<HTMLDivElement>;

	state: IOwnState = {
		size: "full",
		progress: 0,
		isVisible: false,
	};

	constructor(props: IPlayerProps) {
		super(props);
		this.divWaveContainer = React.createRef();

		this.handleOnDismiss = this.handleOnDismiss.bind(this);

		this.handlePlayNext = this.handlePlayNext.bind(this);
		this.handlePlayPrevious = this.handlePlayPrevious.bind(this);
	}

	componentDidMount() {
		this.props.functions.initialize();
	}

	async componentDidUpdate() {
		if (!this.state.isVisible) {
			this.setState((state, props) => {
				return {
					...state,
					isVisible: true,
				};
			});
		}

		const { currentTrack } = this.props.state.player;
		//const newTrack = nextProps.state.player.currentTrack;

		if (!this.peaksJsInstance && this.divWaveContainer.current) {
			this.peaksJsInstance = PeaksJS.factory(this.divWaveContainer.current);
			this.peaksJsInstance.setOnProgress((time) =>
				this.handleOnProgress(time)
			);
			this.peaksJsInstance.setOnFinishedPlaying((track) =>
				this.handleOnFinishedPlaying(track)
			);
		}

		if (!currentTrack && this.peaksJsInstance) this.peaksJsInstance.stop();

		if (currentTrack && this.peaksJsInstance) {
			if (
				this.peaksJsInstance.currentTrack?.guid
					.toLocaleLowerCase()
					.trim() !== currentTrack.guid.toLocaleLowerCase().trim()
			) {
				//if (currentTrack?.guid.toLocaleLowerCase().trim() !== newTrack.guid.toLocaleLowerCase().trim())
				this.peaksJsInstance.loadAsync(
					currentTrack,
					(newTrack) => newTrack.previewUrl
				);
			}

			if (currentTrack.isPlaying) this.peaksJsInstance.play();
			else this.peaksJsInstance.pause();
		}
	}

	handlePlayPauseClick(track: TrackDto | null) {
		if (!track) return;

		if (track.isPlaying) return this.props.functions.pause(track.guid);

		return this.props.functions.play(track.guid);
	}

	handleOnProgress(time: number) {
		this.setState((state, props) => {
			return {
				...state,
				progress: time,
			};
		});
	}

	handleOnFinishedPlaying(track: TrackDto | null) {
		if (!track) return;

		const index = this.props.state.player.playlist.indexOf(track.guid);
		if (index < 0 || index === this.props.state.player.playlist.length - 1)
			return;

		this.props.functions.play(this.props.state.player.playlist[index + 1]);
	}

	handleOnVolumeChange(volume: number) {
		if (this.peaksJsInstance) this.peaksJsInstance.setVolume(volume);
	}

	handleOnDismiss() {
		this.props.functions.dismiss();
	}

	handlePlayNext() {
		let next = this.findInPlaylist(
			this.props.state.player.currentTrack?.guid || "",
			"next"
		);
		if (next === "") return;

		this.props.functions.stop();
		this.props.functions.play(next);
	}

	handlePlayPrevious() {
		let previous = this.findInPlaylist(
			this.props.state.player.currentTrack?.guid || "",
			"previous"
		);
		if (previous === "") return;

		this.props.functions.stop();
		this.props.functions.play(previous);
	}

	handlePlaylistRemove(trackGuid: string) {
		this.props.functions.playlistFunctions.remove(trackGuid);
	}

	handlePlaylistReorder(oldIndex: number, newIndex: number) {
		this.props.functions.playlistFunctions.reorder(oldIndex, newIndex);
	}

	findInPlaylist(trackGuid: string, direction: "next" | "previous"): string {
		if (trackGuid === "") return "";

		const playlist = this.props.state?.player?.playlist || [];
		if (!playlist?.length) return "";

		let index = playlist.indexOf(trackGuid);

		if (direction === "next") {
			if (index === playlist.length - 1) return playlist[0];

			return playlist[index + 1];
		}

		if (direction === "previous") {
			if (index === 0) return playlist[playlist.length - 1];

			return playlist[index - 1];
		}

		return "";
	}

	render() {
		const { isOpen } = this.props.state.player;
		const track = this.props.state.player.currentTrack;
		const playerClassName =
			"player animated w-100 " +
			(isOpen ? "slideInUp" : "slideOutDown") +
			(this.state.isVisible ? "" : " invisible");

		return (
			<div className={playerClassName}>
				<audio className="hidden"></audio>

				<Row>
					<Col xs="12">
						<Row
							className={`animated ${
								this.state.size === "full" ? "hidden" : ""
							}`}
						>
							<Col
								sm="12"
								lg={{ size: 8, offset: 2 }}
								xl={{ size: 6, offset: 6 }}
							>
								<div className="card m-0 shadow-normal">
									<div className="card-body p-h">
										<div className="row flex-row">
											<div className="col-12 col-sm-6">
												<div className="row">
													<div className="col d-flex align-items-center">
														<div className="float-left pl-1">
															<div className="d-flex align-items-center">
																<div
																	className="font-weight-bold flex-grow-1 ps-h"
																	data-id="divTrackName"
																></div>
																(
																<div
																	className="text-muted flex-grow-0"
																	data-id="divReleaseName"
																></div>
																)
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-6 d-flex align-items-center">
												<div className="flex-grow-1 ps-1">
													<div className="row ">
														<div className="col">
															<span
																className="clickable action-btn"
																data-id="buttonPrevious"
															>
																<i className="fa fa-step-backward"></i>
															</span>
														</div>
														<div className="col text-center">
															<span
																className="clickable action-btn"
																data-id="buttonPlay"
															>
																<i className="fa fa-play"></i>
															</span>
															<span
																className="clickable action-btn hidden"
																data-id="buttonPause"
															>
																<i className="fa fa-pause"></i>
															</span>
														</div>
														<div className="col text-end">
															<span
																className="clickable action-btn"
																data-id="buttonNext"
															>
																<i className="fa fa-step-forward"></i>
															</span>
														</div>
													</div>
												</div>
												<div className="text-center flex-grow-1">
													<span data-id="spanTime">00:00</span>
													<span data-id="spanDuration">
														{" "}
														- 00:00
													</span>
												</div>
												<div className="flex-grow-0 pl-1">
													<button
														type="button"
														className="btn btn-sm btn-outline-secondary btn-sm"
													>
														<i className="fa fa-expand-arrows-alt"></i>
													</button>
													<button
														type="button"
														className="btn btn-sm btn-outline-secondary btn-sm"
														data-id="buttonDismiss"
													>
														<i className="fa fa-times"></i>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>

						<Row>
							<Col xs="12">
								<FullPlayer
									{...this.props}
									track={track}
									duration={
										(this.peaksJsInstance as any)?.audioElement
											?.duration || 0
									}
									fullDuration={track?.duration}
									progress={this.state.progress}
									playerState={this.props}
									handlePlayNext={this.handlePlayNext}
									handlePlayPrevious={this.handlePlayPrevious}
									handleOnDismiss={this.handleOnDismiss}
									handleVolumeChange={(volume: number) =>
										this.handleOnVolumeChange(volume)
									}
									handlePlayPauseClick={this.handlePlayPauseClick.bind(
										this,
										track
									)}
									handlePlaylistRemove={(trackGuid: string) =>
										this.handlePlaylistRemove(trackGuid)
									}
									handlePlaylistReorder={(
										oldIndex: number,
										newIndex: number
									) => this.handlePlaylistReorder(oldIndex, newIndex)}
								>
									<div
										ref={this.divWaveContainer}
										className="position-relative"
									></div>
								</FullPlayer>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		state: {
			player: state.player,
			trackList: state.music.trackList,
		},
	};
}

function mapActionsToProps(dispatch: Dispatch<Action<any>>) {
	const playerActions = bindActionCreators(PlayerActionCreators, dispatch);

	return {
		functions: mapToPlayerFunctions(playerActions),
	};
}

export default connect(mapStateToProps, mapActionsToProps)(Player);
