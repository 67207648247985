
import { AnyAction, Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState, AppThunkAction } from './';


let store: Store<ApplicationState>;

export const registerStore = (newStore: Store<ApplicationState>) => {
    store = newStore;
};

export function storeDispatch<TAction>(action: AppThunkAction<TAction>) {
	let dispatch = store.dispatch as ThunkDispatch<ApplicationState, void, AnyAction>;
	return dispatch(action);
};
