export function cloneMap<K, V>(map: Map<K, V>) {
	const result = new Map<K, V>();
	for (const [key, value] of map.entries()) {
		result.set(key, value);
	}
	return result;
}

export default {
	cloneMap,
};
