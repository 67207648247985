import { Action, Reducer } from "redux";
import { initialAjaxifyFlags } from "src/shared/ajaxify";
import { SearchActionTypes, SearchKnownActions } from "./search.actions";
import { InitialSearchState, ISearchState } from "./search.state";

export const searchReducer: Reducer<ISearchState> = (
	currentState: ISearchState = InitialSearchState,
	incomingAction: Action
): ISearchState => {
	const action = incomingAction as SearchKnownActions;

	switch (action.type) {
		case SearchActionTypes.SearchInitialized: {
			return {
				...currentState,
				ajaxifyFlags: {
					...initialAjaxifyFlags,
				},
			};
		}
		case SearchActionTypes.SearchError: {
			return {
				...currentState,
				ajaxifyFlags: {
					...initialAjaxifyFlags,
					requestError: true,
				},
			};
		}
		case SearchActionTypes.SearchRequested: {
			return {
				...currentState,
				ajaxifyFlags: {
					...initialAjaxifyFlags,
					requestInProgress: true,
				},
			};
		}
		case SearchActionTypes.SearchSuccess: {
			return {
				...currentState,
				response: action.payload,
				ajaxifyFlags: {
					...initialAjaxifyFlags,
					requestSuccess: true,
				},
			};
		}
	}

	return currentState;
};
