import { isEqual, isFunction } from 'lodash';
import noUiSlider, { Options } from 'nouislider';
import React, { Component } from 'react';
import { isArray } from 'util';


interface IProps extends Options {

	formatValue?: (value: number) => string;
	onChange?: (values: number[], rawValues: number[]) => void;
	onSlide?: (values: number[], rawValues: number[]) => void;
}


export class Slider extends Component<IProps> {

	container: React.RefObject<HTMLDivElement>;
	sliderInstance: noUiSlider.noUiSlider | null;

	constructor(props: IProps) {
		super(props);

		this.container = React.createRef();
		this.sliderInstance = null;
	}

	setupSlider() {

		if (!this.container.current)
			return;

		var startArray = [];
		if (!isArray(this.props.start))
			startArray = [this.props.start];
		else
			startArray = this.props.start;

		const options: Options = {
			...this.props,
			animate: false,
			tooltips: false,
			//animationDuration: 1000,
			//connect: [false, true, false],
			format: {
				to: function (value: any) {
					return value.toFixed(0);
				},
				from: function (value: any) {
					return Number(value.replace(',-', ''));
				}
			}
		}

		this.sliderInstance = noUiSlider.create(this.container.current, options);

		this.sliderInstance.on("slide", this.handleOnSlide.bind(this));
		this.sliderInstance.on("change", this.handleOnChange.bind(this));

		this.setDataValueAttr(startArray);
	}


	setDataValueAttr(values: any[]) {

		if (!this.container.current)
			return;

		let children = this.container.current.getElementsByClassName('noUi-handle');
		for (var i = 0; i < values.length; i++){

			var formattedValue = values[i].toString();
			if (this.props.formatValue && isFunction(this.props.formatValue))
				formattedValue = this.props.formatValue(values[i]);

			children.item(i)?.setAttribute("data-value", formattedValue);
		}
	}

	handleOnSlide(values: any[], handle: number, unencodedValues: number[]): void {

		this.setDataValueAttr(values);
		if (this.props.onSlide)
			this.props.onSlide(values, unencodedValues);
	}

	handleOnChange(values: any[], handle: number, unencodedValues: number[]): void {

		if (this.props.onChange)
			this.props.onChange(values, unencodedValues);
	}

	componentDidMount() {

		this.setupSlider();
	}

	componentDidUpdate(previousProps: IProps) {

		if (this.sliderInstance && (!isEqual(previousProps.start, this.props.start))) {
			this.sliderInstance.destroy();
			this.setupSlider();
		}
	}


	render() {
		return (
			<div className="w-100" ref={this.container} />
		);
	}
}

export default Slider;
