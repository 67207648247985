import { ArtistDto } from "src/api/models/v2/dto/users/artistDto";
import { IAjaxifyFlags, initialAjaxifyFlags } from "src/shared/ajaxify";

export interface IArtistState {
	artistList: Array<ArtistDto>;
	ajaxifyFlags: IAjaxifyFlags;
}

export const InitialArtistState: IArtistState = {
	artistList: [],
	ajaxifyFlags: initialAjaxifyFlags,
};
