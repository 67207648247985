import { TrackDto } from "src/api/models/v2/dto/music/trackDto";
import { IAjaxifyFlags, initialAjaxifyFlags } from "../../shared/ajaxify";

export interface IPlayerState {
	isOpen: boolean;

	playlist: string[];
	currentTrack: TrackDto | null;
	ajaxifyFlags: IAjaxifyFlags;
}

export const initialPlayerState: IPlayerState = {
	isOpen: false,

	playlist: [],
	currentTrack: null,
	ajaxifyFlags: initialAjaxifyFlags,
};
