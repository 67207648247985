import React from "react";
import { Card, CardBody, Container } from "reactstrap";
import NavBar from "src/components/layout/navBar";
import { Invoices } from "src/components/payment/subscription/invoices";
import { IReduxProps } from "src/store/storeHelper";

interface IProps extends IReduxProps {}

export const InvoicesView = (props: IProps) => {
	return (
		<>
			<NavBar showCartButton={false} />

			<Container>
				<Card className="mt-2">
					<CardBody>
						<Invoices {...props} reload={false} />
					</CardBody>
				</Card>
			</Container>
		</>
	);
};
