import { Action, Reducer } from "redux";
import { Actions, KnownActions } from "./checkout.actions";
import { initialOrderState, IOrderState } from "./checkout.state";

export const OrderReducer: Reducer<IOrderState> = (
	currentState: IOrderState = initialOrderState,
	incomingAction: Action
): IOrderState => {
	const action = incomingAction as KnownActions;

	switch (action.type) {
		case Actions.Initialized:
			return {
				...currentState,
				ajaxifyFlags: {
					...currentState.ajaxifyFlags,
					errorMessage: "",
					requestError: false,
					requestSuccess: false,
					requestInProgress: false,
				},
			};

		case Actions.CheckoutRequested:
			return {
				...currentState,
				ajaxifyFlags: {
					...currentState.ajaxifyFlags,
					requestInProgress: true,
				},
			};

		case Actions.CheckoutSuccess:
			return {
				...currentState,
				response: action.payload,
				ajaxifyFlags: {
					...currentState.ajaxifyFlags,
					requestInProgress: false,
					requestSuccess: true,
				},
			};

		case Actions.CheckoutError:
			return {
				...currentState,
				response: action.payload || { ...currentState.response },
				ajaxifyFlags: {
					...currentState.ajaxifyFlags,
					requestInProgress: false,
					requestError: true,
					errorMessage: "",
				},
			};

		case Actions.NavigatedFromConfirm:
			return {
				...currentState,
				// response: {

				// },
			};

		default:
			return { ...currentState };
	}
};
