import Axios from "axios";
import { DownloadRequest } from "src/api/models/v1/requests/beatsShop/downloadRequest";
import { DownloadResponse } from "src/api/models/v1/responses/beatsShop/downloadResponse";
import { BaseService } from "src/api/services/baseService";
import appConfig from "src/shared/utils/appConfig";

export class DownloadService extends BaseService {
	constructor() {
		super(appConfig.domains.api);
	}

	public async downloadTrackAsync(
		fileType: string,
		trackGuid: string,
		orderGuid: string,
		isGuestOrder: boolean
	): Promise<DownloadResponse | null> {
		const request: DownloadRequest = {
			downloadAll: false,
			fileType: fileType,
			orderGuid: orderGuid,
			trackGuid: trackGuid,
		};

		const config = await super.getAxiosConfigAsync(!isGuestOrder);
		let url = super.appendQueryString(
			"/api/v1/beatsshop/order/download",
			request
		);
		if (isGuestOrder) {
			url = super.appendQueryString(
				"/api/v1/beatsshop/order/guestOrder/download",
				request
			);
		}

		try {
			const response = await Axios.get<DownloadResponse>(url, config);
			if (response.status == 200) {
				return response.data;
			}
		} catch (error) {
			console.log(error);
		}

		return null;
	}

	public async downloadAllAsync(
		fileType: string,
		orderGuid: string
	): Promise<DownloadResponse | null> {
		const request: DownloadRequest = {
			downloadAll: true,
			fileType: fileType,
			orderGuid: orderGuid,
			trackGuid: "",
		};

		const config = await super.getAxiosConfigAsync(true);
		const url = super.appendQueryString(
			"/api/v1/beatsshop/order/download",
			request
		);

		try {
			const response = await Axios.get<DownloadResponse>(url, config);
			if (response.status == 200) {
				return response.data;
			}
		} catch (error) {
			console.log(error);
		}

		return null;
	}
}
