export const getQuery = (url: string) => {
	let query = "";
	if (url.indexOf("?") > 0) {
		query = url.substring(url.indexOf("?") + 1);
	}

	return query;
};

export const getUrlWithoutQuery = (url: string) => {
	if (url.indexOf("?") > 0) {
		return url.substring(0, url.indexOf("?"));
	}

	return url;
};

export const parse = (query: string) => {
	let keyValues: { [key: string]: string } = {};

	if (query.trim() === "") {
		return keyValues;
	}

	query.split("&").forEach((keyValue) => {
		var [key, value] = keyValue.split("=");
		if (key.trim() !== "") {
			keyValues[key] = decodeURIComponent(value);
		}
	});

	return keyValues;
};

export const merge = (query: string, secondQuery: string) => {
	const keyValues = parse(secondQuery);
	let result = query;
	Object.keys(keyValues).forEach((currentKey) => {
		if (currentKey !== "") {
			result = addOrUpdate(result, currentKey, keyValues[currentKey]);
		}
	});

	return result;
};

export const addOrUpdate = (
	query: string,
	key: string,
	value: string | number
) => {
	const keyValues = parse(query);
	const queryArray: string[] = [];

	[...Object.keys(keyValues), key].forEach((currentKey) => {
		if (currentKey !== "") {
			if (currentKey.toLowerCase() === key.toLowerCase()) {
				queryArray.push(`${key}=${value}`);
			} else {
				queryArray.push(`${currentKey}=${keyValues[currentKey]}`);
			}
		}
	});

	return queryArray.join("&");
};

export default {
	getQuery,
	parse,
	addOrAppend: addOrUpdate,
};
