import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import NavBar from "src/components/layout/navBar";
import { SearchResults } from "src/components/search/searchResults";
import { IReduxProps } from "src/store/storeHelper";

interface IProps extends IReduxProps {}
interface IRouteParam {
	searchTerm: string;
}

export const Search = (props: IProps) => {
	const { searchTerm } = useParams<IRouteParam>();

	return (
		<>
			<NavBar showCartButton={true} searchTerm={searchTerm} />

			<Container fluid={true} className="container-p-t">
				<SearchResults searchTerm={searchTerm} {...props}></SearchResults>
			</Container>
		</>
	);
};
