import { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import { Container } from "reactstrap";
import { IAuthStore } from "src/components/auth/authProvider";
import { calculateValues } from "src/components/beatsShop";
import { CartSummaryBody } from "src/components/beatsShop/cartSummary";
import CheckoutForm from "src/components/beatsShop/checkoutForm";
import NavBar from "src/components/layout/navBar";
import { IChartFunctions } from "src/components/music/chart";
import utils from "src/shared/utils";
import { CartItemTypes } from "src/store/beatsShop/cart/cart.state";
import { ICheckoutForm } from "src/store/beatsShop/orders/checkout.state";
import { mapToPlayerFunctions } from "src/store/player/player.actions";
import { IReduxProps } from "src/store/storeHelper";

interface IProps extends IReduxProps {}

interface IState {
	requestGuid: string;
}

class Checkout extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			requestGuid: utils.string.createNewGuid(),
		};
	}

	private orderAmounts = () => {
		return calculateValues({
			trackList: this.props.State.Music.trackList,
			releaseList: this.props.State.Music.releaseList.releases,
			cartItemList: this.props.State.BeatsShop.Cart.itemList,
		});
	};

	async componentDidMount() {
		(!this.props.State.BeatsShop.Cart.itemList ||
			!this.props.State.BeatsShop.Cart.itemList.length) &&
			(await this.props.Actions.BeatsShop.Cart.InitializeAsync());
	}

	chartFunctions(): IChartFunctions {
		return {
			cartFunctions: this.props.Actions.BeatsShop.Cart,
			changeLayout: this.props.Actions.Music.LayoutChanged,
			playerFunctions: mapToPlayerFunctions(this.props.Actions.Player),
		};
	}

	getRequestGuid(): string {
		if ((this.state.requestGuid || "") !== "") return this.state.requestGuid;

		const guid = utils.string.createNewGuid();
		this.setState({ requestGuid: guid });
		return guid;
	}

	async handleSubmitAsync(values: ICheckoutForm) {
		const trackGuidList = this.props.State.BeatsShop.Cart.itemList
			.filter((x) => x.type === CartItemTypes.Track && !x.isAddedByRelease)
			.map((x) => x.guid);

		const releaseGuidList = this.props.State.BeatsShop.Cart.itemList
			.filter((x) => x.type === CartItemTypes.Release)
			.map((x) => x.guid);

		values.amount = this.orderAmounts().total;

		const requestGuid = this.getRequestGuid();
		return await this.props.Actions.Checkout.ProcessCheckoutAsync(
			requestGuid,
			values,
			trackGuidList,
			releaseGuidList
		);
	}

	private emptyCart = () => {
		const childrenProps = {
			trackList: this.props.State.Music.trackList,
			releaseList: this.props.State.Music.releaseList.releases,
			cartItemList: this.props.State.BeatsShop.Cart.itemList,
			cartFunctions: this.props.Actions.BeatsShop.Cart,
		};

		return (
			<>
				<div className="mt-2">
					<CartSummaryBody {...childrenProps} />
				</div>
			</>
		);
	};

	private nonEmptyCart = () => {
		const childrenProps = {
			trackList: this.props.State.Music.trackList,
			releaseList: this.props.State.Music.releaseList.releases,
			cartItemList: this.props.State.BeatsShop.Cart.itemList,
			cartFunctions: this.props.Actions.BeatsShop.Cart,
		};

		const auth: IAuthStore = {
			authState: this.props.State.Auth,
			authFunctions: this.props.Actions.Auth,
		};

		return (
			<div className="pb-5">
				{/* <div className="mt-2">
					<h2>My Beats Cart</h2>
				</div> */}

				<div className="mt-2">
					<CartSummaryBody {...childrenProps} />
				</div>

				<hr className="mb-2" />

				<StripeProvider apiKey={utils.appConfig.stripe.publishableKey}>
					<Elements
						fonts={[
							{
								cssSrc: "https://fonts.googleapis.com/css?family=Lato",
							},
						]}
					>
						<CheckoutForm
							totalAmount={this.orderAmounts().total}
							authStore={auth}
							ajaxifyFlags={this.props.State.Checkout.ajaxifyFlags}
							processPaymentAsync={this.handleSubmitAsync.bind(this)}
						/>
					</Elements>
				</StripeProvider>
			</div>
		);
	};

	render() {
		const childrenProps = {
			trackList: this.props.State.Music.trackList,
			cartItemList: this.props.State.BeatsShop.Cart.itemList,
			cartFunctions: this.props.Actions.BeatsShop.Cart,
		};

		return (
			<>
				<NavBar showCartButton={false} />

				<Container className="container-p-t">
					{childrenProps.cartItemList &&
					childrenProps.cartItemList.length ? (
						<this.nonEmptyCart />
					) : (
						<this.emptyCart />
					)}
				</Container>
			</>
		);
	}
}

export default Checkout;
