import React, { Component, createRef } from "react";

interface IProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	loading: boolean;
	loadingText?: string;
	wrapperClass?: string;
}

export class AjaxButton extends Component<IProps> {
	button: React.RefObject<HTMLButtonElement>;

	constructor(props: IProps) {
		super(props);
		this.button = createRef();
	}

	render() {
		const { className } = this.props;
		const { loading, loadingText, wrapperClass, ...propsNoLoading } =
			this.props;

		return (
			<div
				className={`ajax-button ${this.props.className} ${
					this.props.wrapperClass || ""
				} ${this.props.loading ? " loading" : ""}`}
			>
				<button
					ref={this.button}
					disabled={this.props.loading}
					{...propsNoLoading}
				>
					{this.props.children}
				</button>
				<div
					className={`loading-overlay align-items-center justify-content-center animated faster ${className} ${
						this.props.loading ? "d-flex fadeIn" : "hidden fadeOut"
					}`}
				>
					<i className="fa fa-spin fa-sync-alt" />
					&nbsp;{this.props.loadingText || ""}
				</div>
			</div>
		);
	}
}

export default AjaxButton;
