import { Action, Reducer } from "redux";
import { ReleaseDto } from "src/api/models/v2/dto/music/releaseDto";
import { initialAjaxifyFlags } from "src/shared/ajaxify";
import utils from "src/shared/utils";
import { ReleaseActionTypes, ReleaseKnownActions } from "./release.actions";
import { IReleaseState, InitialReleaseState } from "./release.state";

export const releaseReducer: Reducer<IReleaseState> = (
	currentState: IReleaseState = InitialReleaseState,
	incomingAction: Action
): IReleaseState => {
	const action = incomingAction as ReleaseKnownActions;

	switch (action.type) {
		case ReleaseActionTypes.ReleaseInitialized:
			return { ...(currentState || InitialReleaseState) };

		case ReleaseActionTypes.ReleasesByAliasRequestedCatalogNo:
		case ReleaseActionTypes.ReleasesByAliasRequestedArtistAlias:
			return {
				...currentState,
				ajaxifyFlags: {
					...initialAjaxifyFlags,
					requestInProgress: true,
				},
			};

		case ReleaseActionTypes.ReleasesByAliasError:
			return {
				...currentState,
				ajaxifyFlags: {
					...initialAjaxifyFlags,
					requestError: true,
				},
			};

		case ReleaseActionTypes.ReleasesByAliasSuccess:
			const releases = utils.array.merge(
				currentState.releases,
				action.payload,
				(x) => x.guid.toLocaleLowerCase().trim(),
				true
			);

			return {
				...currentState,
				releases: [...releases],
				ajaxifyFlags: {
					...initialAjaxifyFlags,
					requestSuccess: true,
				},
			};

		case ReleaseActionTypes.ReleasesByGuidsSuccess:
			const newReleases = utils.array.merge(
				currentState.releases,
				action.payload,
				(x) => x.guid.toLowerCase().trim()
			);

			return {
				...currentState,
				releases: newReleases,
				ajaxifyFlags: {
					...initialAjaxifyFlags,
					requestSuccess: true,
				},
			};

		case ReleaseActionTypes.ReleaseMarkedInCart:
			const target = currentState.releases.find(
				(x) => x.guid.toLowerCase() === action.payload.toLowerCase()
			);
			if (!target) {
				return currentState;
			}

			target.isInCart = true;
			const releasesCloned = utils.array.cloneReplace<ReleaseDto>(
				currentState.releases,
				target,
				(x) => x.guid
			);
			return {
				...currentState,
				releases: releasesCloned,
			};

		case ReleaseActionTypes.ReleaseRemovedFromCart:
			const targetToBeRemoved = currentState.releases.find(
				(x) => x.guid.toLowerCase() === action.payload.toLowerCase()
			);
			if (!targetToBeRemoved) {
				return currentState;
			}

			targetToBeRemoved.isInCart = false;
			const releasesClonedRemoved = utils.array.cloneReplace<ReleaseDto>(
				currentState.releases,
				targetToBeRemoved,
				(x) => x.guid
			);
			return {
				...currentState,
				releases: releasesClonedRemoved,
			};
	}

	return currentState || InitialReleaseState;
};
